import { ReactNode } from 'react';
import { prepareStyle, useStyles } from '@creditinfo-ui/styles';

const filterFormWrapperStyle = prepareStyle(utils => ({
	marginBottom: utils.spacings.lg,
}));

export interface FilterFormDataGridProps {
	dataGrid: ReactNode;
	filterForm: ReactNode;
}

export const FilterFormDataGrid = ({ dataGrid, filterForm }: FilterFormDataGridProps) => {
	const { applyStyle } = useStyles();

	return (
		<>
			{filterForm && <div className={applyStyle(filterFormWrapperStyle)}>{filterForm}</div>}
			{dataGrid}
		</>
	);
};
