import { AnyAction, Middleware, createAction, createReducer } from '@reduxjs/toolkit';
import { any, dissoc, find, findIndex, isEmpty as getIsEmpty, last, propEq, reject } from 'ramda';
import { MessageDescriptor } from 'react-intl';
import { Thunk } from 'redux-syringe';

import { generateId, getCurrentQueryParams, serializeQueryParams } from '@creditinfo-ui/utils';

import { ModalId, ModalInstance, ModalInstanceOptions } from './types';
import { createModalInstance } from './utils';

export interface ModalStackItem<TPayload extends object> {
	id: ModalId;
	options?: ModalInstanceOptions;
	payload?: TPayload;
}

type AnyModalStackItem = ModalStackItem<Record<string, any>>;

export interface RootState {
	modalStack: AnyModalStackItem[];
}

const SHOW_MODAL_ACTION_TYPE = '@creditinfo-ui/modals/showModal';

export const showModal = <TPayload extends object>(
	...params: {} extends TPayload
		? [instance: ModalInstance<TPayload>, payload?: TPayload]
		: [instance: ModalInstance<TPayload>, payload: TPayload]
) => {
	const [instance, payload] = params;

	return createAction<any>(SHOW_MODAL_ACTION_TYPE)({
		id: instance.id,
		payload,
		options: instance.options,
	});
};

export const hideModal = createAction<ModalInstance<any>>('@creditinfo-ui/modals/hideModal');
export const hideAllModals = createAction('@creditinfo-ui/modals/hideAllModals');

export const selectModalStack = (state: RootState) => state?.modalStack ?? [];

const selectModalOnTop = (state: RootState) => last(selectModalStack(state));

export const selectIsModalOnTop = (modalInstance: ModalInstance<any>) => (state: RootState) =>
	selectModalOnTop(state)?.id === modalInstance.id;

export const selectModalStackIndex = (modalInstance: ModalInstance<any>) => (state: RootState) => {
	const modalStack = selectModalStack(state);
	const index = findIndex(propEq('id', modalInstance.id), modalStack);

	return index;
};

export const selectIsAnyModalInStack = (state: RootState) => Boolean(selectModalOnTop(state));

export const selectModalPayload =
	<TPayload extends object>(modalInstance: ModalInstance<TPayload>) =>
	(state: RootState) => {
		const modalStack = selectModalStack(state);
		const modal = find(propEq('id', modalInstance.id), modalStack) as
			| ModalStackItem<TPayload>
			| undefined;

		return modal?.payload;
	};

const getIsModalIdInStack = (id: ModalId, stack: AnyModalStackItem[]): boolean =>
	any(propEq('id', id), stack);

export const selectIsModalInStack = (modalInstance: ModalInstance<any>) => (state: RootState) => {
	const modalStack = selectModalStack(state);
	const isInStack = getIsModalIdInStack(modalInstance.id, modalStack);

	return isInStack;
};

const getInitialState = (): AnyModalStackItem[] => {
	const queryParams = getCurrentQueryParams();

	return queryParams.modals
		? JSON.parse(queryParams.modals).map((modalStackItem: AnyModalStackItem) => ({
				...modalStackItem,
				options: {
					...modalStackItem.options,
					hasUrlPersistence: true,
				},
			}))
		: [];
};

export const reducer = createReducer(getInitialState(), builder => {
	builder
		.addCase(hideModal, (state, { payload }) => reject(propEq('id', payload.id), state))
		.addCase(hideAllModals, () => [])
		.addMatcher(
			({ type }: AnyAction) => type === SHOW_MODAL_ACTION_TYPE,
			(state, action) => {
				const modalStackItem = action.payload as AnyModalStackItem;
				const stackIndex = findIndex(propEq('id', modalStackItem.id), state);
				const isInStack = stackIndex >= 0;

				if (isInStack) {
					state[stackIndex] = modalStackItem;
				} else {
					state.push(modalStackItem);
				}
			}
		);
});

const modalActionTypes = [SHOW_MODAL_ACTION_TYPE, hideModal.type, hideAllModals.type];

export const middleware: Middleware =
	({ getState }) =>
	next =>
	action => {
		const returnValue = next(action);

		if (modalActionTypes.includes(action.type)) {
			const state = getState();
			const modalStack = selectModalStack(state);

			const nextQueryParamModalStack = modalStack.filter(
				modalStackItem => modalStackItem.options?.hasUrlPersistence
			);

			const currentQueryParams = getCurrentQueryParams();
			const nextModalsQueryParam = JSON.stringify(
				nextQueryParamModalStack.map(modalStackItem => {
					const otherOptions = dissoc('hasUrlPersistence', modalStackItem.options ?? {});

					return {
						...modalStackItem,
						options: getIsEmpty(otherOptions) ? undefined : otherOptions,
					};
				})
			);

			if (currentQueryParams.modals !== nextModalsQueryParam) {
				const nextSerializedQueryParams = serializeQueryParams(
					getIsEmpty(nextQueryParamModalStack)
						? dissoc('modals', currentQueryParams)
						: { ...currentQueryParams, modals: nextModalsQueryParam }
				);

				window.history.replaceState(
					window.history.state,
					'',
					nextSerializedQueryParams
						? `${window.location.pathname}?${nextSerializedQueryParams}`
						: window.location.pathname
				);
			}
		}

		return returnValue;
	};

export interface ConfirmMessages {
	cancel?: string | MessageDescriptor;
	confirm?: string | MessageDescriptor;
	description: string | MessageDescriptor;
	title: string | MessageDescriptor;
}

export interface ConfirmPayload {
	hasCancelButton?: boolean;
	messages: ConfirmMessages;
	shouldHideModalWhenConfirmed?: boolean;
}

export interface ConfirmResult {
	hasCanceled: boolean;
	hasConfirmed: boolean;
	modalInstance: ModalInstance<ConfirmPayload>;
}

export const CONFIRM_MODAL_ID_PREFIX = '@creditinfo-ui/modals/confirm';

const confirmResolveRecord: Partial<Record<ModalId, (result: ConfirmResult) => void>> = {};

export const resolveConfirm = (id: ModalId, result: ConfirmResult) => {
	confirmResolveRecord[id]?.(result);
	delete confirmResolveRecord[id];
};

export const confirm =
	(payload: ConfirmPayload): Thunk<Promise<ConfirmResult>> =>
	({ dispatch }) => {
		const modalInstance = createModalInstance(`${CONFIRM_MODAL_ID_PREFIX}/${generateId()}`);

		dispatch(showModal(modalInstance, payload));

		return new Promise(resolve => {
			confirmResolveRecord[modalInstance.id] = resolve;
		});
	};
