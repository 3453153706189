import { createSelector } from 'reselect';
import { isRtlLang } from 'rtl-detect';
import { defaultTo, o, path } from 'ramda';

interface IntlOptions {
	amountString: string;
	decimalPlaces: number;
	decimalSeparator: string;
	localCurrencyCode: string;
	localCurrencySymbol: string;
	thousandSeparator: string;
}
interface GlobalState {
	intl: {
		intlOptions: IntlOptions;
		isLoadingLocale: boolean;
		locale: string;
		preferedLocale: string;
		supportedLocales: string[];
		translations: Record<string, string>;
	};
}

const getIntlSlice = (state: GlobalState) => state.intl;
// TODO: reselect do not work - fix or remove
const getTranslationsByLocale = locale =>
	createSelector([getIntlSlice], path(['translations', locale]));

export const getLoadingNamespacesByLocale_ = locale =>
	o(defaultTo([]), o(path(['loadingNamespaces']), getTranslationsByLocale(locale)));

export const getLoadedNamespacesByLocale = locale =>
	createSelector([getTranslationsByLocale(locale)], o(defaultTo([]), path(['loadedNamespaces'])));

export const getMessagesByLocale = locale =>
	createSelector([getTranslationsByLocale(locale)], o(defaultTo({}), path(['messages'])));
export const getIntlOptions = createSelector([getIntlSlice], slice => slice?.intlOptions);
export const getSupportedLocales = createSelector([getIntlSlice], slice => slice?.supportedLocales);
export const getLocale = createSelector([getIntlSlice], slice => slice.locale);

export const getPreferedLocale = o(path(['preferedLocale']), getIntlSlice);
export const isRtl = o(isRtlLang, getLocale);
// NOTE: This function shouldn't be used in the web application,
// it's here purely to suppress TS errors in native components.
export const getIsDeviceRtl = (): boolean => false;

export const isLoadingLocale = o(path(['isLoadingLocale']), getIntlSlice);
