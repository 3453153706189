"use strict";

exports.__esModule = true;
exports.toUrlLocale = exports.removePrefix = exports.isAddressProbablyInvalid = void 0;
var _ramda = require("ramda");
var _ramdaExtension = require("ramda-extension");
var toUrlLocale = exports.toUrlLocale = (0, _ramda.compose)((0, _ramda.join)('-'), (0, _ramda.slice)(0, 2), (0, _ramda.split)('-'), (0, _ramda.replace)('_', '-'), _ramda.toLower);
var isAddressProbablyInvalid = exports.isAddressProbablyInvalid = (0, _ramda.complement)((0, _ramdaExtension.startsWithPrefix)('/'));
var removePrefix = exports.removePrefix = function removePrefix(pathname) {
  var base = typeof __BASE_PATH__ !== 'undefined' ? __BASE_PATH__ : __PATH_PREFIX__;
  return base && pathname.indexOf(base) === 0 ? pathname.slice(base.length) : pathname;
};