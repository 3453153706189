import React, { createElement } from 'react';
import { T, chain, compose, cond, init, omit, prop } from 'ramda';
import { isArray, isObject } from 'ramda-extension';
import { addPropsLeft, addPropsRight } from '@myci/utils';
import { Message } from '@myci/intl';
import { getFirstValidationError } from '@ci/validations';

const createErrorMessageElement = (rawErrorMeta, label) => {
	const errorMeta =
		typeof rawErrorMeta.type === 'string'
			? getFirstValidationError(rawErrorMeta) ?? rawErrorMeta
			: rawErrorMeta;

	return (
		<Message
			{...errorMeta.message}
			values={{
				...errorMeta.options,
				...errorMeta.messageValues,
				value: errorMeta.value,
				label,
			}}
			key={errorMeta.message.id}
		/>
	);
};

const createErrorNode = (errorMeta, label) => {
	const errors = errorMeta?.errors ?? errorMeta?.validationErrors ?? errorMeta;

	if (isArray(errors)) {
		const processErrorMetaArray = compose(
			init,
			chain(
				cond([
					[
						isObject,
						singleErrorMeta => [
							createErrorMessageElement(singleErrorMeta, label),
							<br key={`${singleErrorMeta.message.id}br`} />,
						],
					],
					[T, singleErrorMeta => [singleErrorMeta, <br key={`${singleErrorMeta}br`} />]],
				])
			)
		);

		return processErrorMetaArray(errors);
	}
	if (isObject(errorMeta)) {
		return createErrorMessageElement(errorMeta, label);
	}

	return errorMeta;
};

const addErrorProp = addPropsRight(({ meta, label }) => ({
	error: (meta.touched || meta.dirty) && meta.error ? createErrorNode(meta.error, label) : null,
}));

const withFieldProps = NextComponent => {
	const getNextProps = compose(omit(['input', 'meta']), addPropsLeft(prop('input')), addErrorProp);

	const WithFieldProps = props => createElement(NextComponent, getNextProps(props));

	return WithFieldProps;
};

export default withFieldProps;
