import { ReactNode } from 'react';
import { FontSize, prepareStyle, useStyles } from '@creditinfo-ui/styles';

interface TabButtonLabelStyleProps {
	size: FontSize;
}

const tabButtonLabelStyle = prepareStyle((utils, { size }: TabButtonLabelStyleProps) => ({
	fontSize: utils.fontSizes[size],
	lineHeight: utils.lineHeights.base,
	whiteSpace: 'nowrap',
}));

export interface TabButtonLabelProps {
	children: ReactNode;
	size?: FontSize;
}

export const TabButtonLabel = ({ children, size = 'tabLabel' }: TabButtonLabelProps) => {
	const { applyStyle } = useStyles();

	return <span className={applyStyle(tabButtonLabelStyle, { size })}>{children}</span>;
};
