import { addToast } from '@ci/toasts';
import { navigate } from '@myci/navigation';
import {
	RequestAction,
	ResponseEventAction,
	createThunk,
	request,
	selectIsThunkPending,
} from '@ci/api';
import { createAction, createReducer } from '@reduxjs/toolkit';
import m from '../messages';
import { fetchUserAccount } from './account';

export const setIsPhoneCodeSent = createAction<boolean>('@changePhone/setIsPhoneCodeSent');
export const resetPhone = createAction('@changePhone/resetPhone');

export interface RequestPhoneVerificationCodePayload {
	form?: string;
	phone: string;
}

interface PhoneChangeState {
	isPhoneCodeSent: boolean;
}

const initialState: PhoneChangeState = {
	isPhoneCodeSent: false,
};

export const requestPhoneVerificationCode = createThunk(
	{
		originType: '@changePhone/requestPhoneVerificationCode',
		errorMessage: m.verifyPhoneFail,
	},
	async ({ dispatch }, payload: RequestPhoneVerificationCodePayload) => {
		const { form, phone } = payload;

		dispatch(setIsPhoneCodeSent(false));
		await dispatch(
			request({ url: '/account/phone/request', method: 'POST', body: { phone } }, { form })
		);

		dispatch(setIsPhoneCodeSent(true));

		dispatch(
			addToast({
				type: 'success',
				content: m.sendPhoneCodeSuccess,
				values: { phone: payload.phone },
			})
		);
	}
);
export const selectIsPhoneCodeSent = state => state.changePhone?.isPhoneCodeSent;

export interface VerifyPhoneCodePayload {
	code: string;
	form?: string;
	onSuccess?: () => void;
	phone: string;
}

export const verifyPhoneCode = createThunk(
	{
		originType: '@changePhone/verifyPhoneCode',
		errorMessage: m.verifyPhoneFail,
	},
	async ({ dispatch }, payload: VerifyPhoneCodePayload) => {
		const { form, code, onSuccess, phone } = payload;
		await dispatch(
			request(
				{ url: '/account/phone/verify', method: 'POST', body: { code, phone } },
				form ? { form } : undefined
			)
		);

		dispatch(addToast({ type: 'success', content: m.changePhoneSuccess }));
		dispatch(setIsPhoneCodeSent(false));
		dispatch(fetchUserAccount());

		if (onSuccess) {
			onSuccess();
		}

		navigate('../settings');
	}
);
export const selectIsVerifyingPhoneCode = selectIsThunkPending(verifyPhoneCode);

export const getPhoneCheckUniquenessErrors = (response: ResponseEventAction) => {
	const errors = response.meta.errors;

	return {
		errors: errors.map(error => ({
			message: error.code
				? {
						id: `errorMessage.${error.code}`,
						defaultMessage: error.messageLocalized,
					}
				: m.changePhoneFail,
		})),
	};
};

interface CreateCheckPhoneFieldUniquenessRequestValues {
	phone: string;
	userType: string;
}

// TODO - used only in mobile form now - remove after refactoring
export const createCheckPhoneFieldUniquenessRequest = (
	values: CreateCheckPhoneFieldUniquenessRequestValues,
	props: any,
	currentFieldName: string
): RequestAction => {
	const { userType } = values;

	const userTypePath = userType ?? 'Individual';

	return request(
		{
			url: `/register/check/${userTypePath}/phoneNumber`,
			method: 'POST',
			body: { value: values[currentFieldName] },
		},
		{
			// NOTE: API returns ApplicationError severity here, instead of ValidationError
			shouldSuppressErrorToast: true,
			origin: {
				type: '@changePhone/checkPhoneFieldUniquenessRequest',
			},
		}
	);
};

export const changePhoneReducer = createReducer(initialState, builder => {
	builder.addCase(setIsPhoneCodeSent, (state, action) => ({
		...state,
		isPhoneCodeSent: action.payload,
	}));
});

interface VerifyPhoneUniquenessPayload {
	phoneNumber: string;
	userType: 'Individual' | 'Company';
}

export const verifyPhoneUniqueness = createThunk(
	'@password/verifyPhoneUniqueness',
	async ({ dispatch }, payload: VerifyPhoneUniquenessPayload) => {
		const { phoneNumber, userType } = payload;
		try {
			await dispatch(
				request(
					{
						url: `/register/check/${userType}/phoneNumber`,
						method: 'POST',
						body: { value: phoneNumber },
					},
					{ shouldSuppressErrorToast: true }
				)
			);
		} catch (errorAction: any) {
			return errorAction.meta?.errors;
		}
	}
);
