import React, { ReactNode } from 'react';
import { includes } from 'ramda';
import { useSelector } from 'react-redux';
import { Match, Redirect } from '@gatsbyjs/reach-router';
import { useInstanceConfig } from '@myci/instances';
import { InstanceTypes, currentInstanceType, getEnvironmentVariable } from '@myci/utils';
import { backOfficeUserManager } from '../features/backOfficeAccount';
import {
	getAccountStatus,
	getUserType,
	selectIsPasswordChangeNecessary,
} from '../features/account';

const unauthorizedUrl = '/app/my-profile/settings/unauthorized';
// TODO: verify if custom changePassword URL is used in any instance or TeamCity
const configuredChangePasswordUrl = getEnvironmentVariable('GATSBY_MYCI_CHANGE_PASSWORD_URL');
const restrictedUrl = '/app/my-profile/settings/restricted';

const changePasswordPath =
	currentInstanceType === InstanceTypes.INSTANCE_FO
		? 'my-profile/settings/password'
		: 'dashboard/change-password';

const changePasswordUrl =
	currentInstanceType === InstanceTypes.INSTANCE_FO && configuredChangePasswordUrl
		? `/${configuredChangePasswordUrl}`
		: `/app/${changePasswordPath}`;

interface CheckAccountConstraintsProps {
	children: ReactNode;
}

export const CheckAccountConstraints = ({ children }: CheckAccountConstraintsProps) => {
	const { restrictedUserTypes } = useInstanceConfig();
	const userType = useSelector(getUserType);
	const isUserTypeRestricted =
		userType && restrictedUserTypes && includes(userType, restrictedUserTypes);

	const accountStatus = useSelector(getAccountStatus);
	const isAccountUnauthorized = accountStatus === 'Unauthorized';

	const boUserData = useSelector(backOfficeUserManager.selectFirst);
	const isFoInstance = currentInstanceType === InstanceTypes.INSTANCE_FO;
	const isBoPasswordChangeNecessary = boUserData?.passwordChangeRequired;
	const isFoPasswordChangeNecessary = useSelector(selectIsPasswordChangeNecessary);
	const isAnyPasswordChangeNecessary = isFoInstance
		? isFoPasswordChangeNecessary
		: isBoPasswordChangeNecessary;

	if (!isFoInstance && !boUserData) {
		return null;
	}

	if (isUserTypeRestricted) {
		return (
			<Match path={restrictedUrl}>
				{({ match }) => (!match ? <Redirect noThrow to={restrictedUrl} /> : children)}
			</Match>
		);
	}

	if (isAccountUnauthorized) {
		return (
			<Match path={unauthorizedUrl}>
				{({ match }) => (!match ? <Redirect noThrow to={unauthorizedUrl} /> : children)}
			</Match>
		);
	}

	if (isAnyPasswordChangeNecessary) {
		return (
			<Match path={changePasswordUrl}>
				{({ match }) => (!match ? <Redirect noThrow to={changePasswordUrl} /> : children)}
			</Match>
		);
	}

	return children;
};
