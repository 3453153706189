import { defineMessages } from 'react-intl';

export default defineMessages({
	apiVersionError: {
		id: 'dataApi.apiVersionError',
		defaultMessage: 'Loading API version failed',
		description: 'Error handling string for api version failure',
	},
	instanceConfigurationError: {
		id: 'dataApi.instanceConfigurationError',
		defaultMessage: 'Loading Instance Configuration failed',
		description: 'Error handling string for Instance Configuration failure',
	},
	privacyPolicyError: {
		id: 'dataApi.privacyPolicyError',
		defaultMessage: 'Loading Privacy Policy failed',
		description: 'Privacy policy loading error',
	},
	termsAndConditionsError: {
		id: 'dataApi.termsAndConditionsError',
		defaultMessage: 'Loading Terms and Conditions failed',
		description: 'Terms and Conditions loading error',
	},
	disputeReasonError: {
		id: 'dataApi.disputeReasonError',
		defaultMessage: 'Loading Dispute Reason failed',
		description: 'Loading Dispute Reason error',
	},
	citiesError: {
		id: 'dataApi.citiesError',
		defaultMessage: 'Loading Cities failed',
		description: 'Loading Cities error',
	},
	idNumberConfigError: {
		id: 'dataApi.idNumberConfigError',
		defaultMessage: 'Loading identifier details configuration for given user type failed',
		description: 'Identifier details config loading error',
	},
	countrySubdivisionsError: {
		id: 'dataApi.countrySubdivisionsError',
		defaultMessage: 'Loading administrative units for given user type failed',
		description: 'Country subdivisions loading error',
	},
	mobileConfigError: {
		id: 'dataApi.mobileConfigError',
		defaultMessage: 'Loading mobile configuration failed',
	},
	idNumberName_NationalId: {
		id: 'myciUser.idNumberName_NationalId',
		defaultMessage: 'Civil ID',
	},
	idNumberName_IdCardNumber: {
		id: 'myciUser.idNumberName_IdCardNumber',
		defaultMessage: 'Resident ID',
	},
	idNumberName_CustomIdNumber1: {
		id: 'myciUser.idNumberName_CustomIdNumber1',
		defaultMessage: 'Non-ROP ID',
	},
	idNumberName_CustomIdNumber3: {
		id: 'myciUser.idNumberName_CustomIdNumber3',
		defaultMessage: 'Non-MOCI ID',
	},
	idNumberName_RegistrationNumber: {
		id: 'myciUser.idNumberName_RegistrationNumber',
		defaultMessage: 'Commercial Registration Number',
	},
});
