import { simplifyResolverHook } from '@ci/react-utils';
import { makeFormatter } from 'afformative';
import { compose, filter, gte, identity, last, map, mergeDeepRight, when } from 'ramda';
import { isFunction, isNotNil, isNumeric, isObject } from 'ramda-extension';
import { useCallback } from 'react';
import { FormattedNumberParts, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { m } from '../messages';
import { useMessageFormatter } from './utility';

const AbbreviationTable = {
	T: 1,
	M: 2,
	B: 3,
};

const FormattedNumberTypes = {
	INTEGER: 'integer',
	GROUP: 'group',
	DECIMAL: 'decimal',
	FRACTION: 'fraction',
	LITERAL: 'literal',
	CURRENCY: 'currency',
	MINUS_SIGN: 'minusSign',
};

const valueTypes = [
	FormattedNumberTypes.DECIMAL,
	FormattedNumberTypes.GROUP,
	FormattedNumberTypes.INTEGER,
	FormattedNumberTypes.FRACTION,
	FormattedNumberTypes.LITERAL,
];

// NOTE: `\u200f` is a RTL marker that should probably be ignored. Who knows if it's actually necessary.
const onlyWhitespaceRegExp = /^[\s\u200f]*$/;

const ltrMarkerPart = {
	type: FormattedNumberTypes.LITERAL,
	value: '\u200e',
};

const replaceCurrencySymbol = (currencyPart, currencySymbol) => {
	if (currencyPart.type === FormattedNumberTypes.CURRENCY && isNotNil(currencySymbol)) {
		return {
			...currencyPart,
			value: currencySymbol,
		};
	}

	return currencyPart;
};

// NOTE: The BE switch defines the internal order of [currency, value] tuples when formatting amounts.
// Internally, we extract the currency and any relevant parts from the `Intl.NumberFormat.prototype.formatToParts`
// and reorder them based on a `{0} {1}` string (i.e. {value} {currency}). This logic is quite na¨ive, as it
// relies on the fact that any non-whitespace literals are tied to the value (and whitespace literals are ignored).
//
// Previously, this implementation just attempted to switch the value with the currency based on the amount string.
// This is not feasible due to minus signs and random literals that `window.Intl` occasionally throws around.
const formatAsAmount = (amountString, currencySymbol) => parts => {
	const currencyFirst = amountString.indexOf('{1}') < amountString.indexOf('{0}');

	const [firstPart, middlePart, lastPart] = amountString.split(/\{0}|\{1}/g).map(text => ({
		value: text,
		type: FormattedNumberTypes.LITERAL,
	}));

	const defaultCurrencyPart = parts.find(part => part.type === FormattedNumberTypes.CURRENCY) || {
		type: FormattedNumberTypes.LITERAL,
		value: '',
	};

	const currencyPart = replaceCurrencySymbol(defaultCurrencyPart, currencySymbol);

	const valueParts = parts
		.filter(part => valueTypes.includes(part.type))
		.filter(
			// NOTE: We let the `amountString` dictate the whitespace. Keep just the useful literals (e.g. abbreviations).
			part => part.type !== FormattedNumberTypes.LITERAL || !onlyWhitespaceRegExp.test(part.value)
		);

	const minusSignPart = parts.find(part => part.type === FormattedNumberTypes.MINUS_SIGN);

	// NOTE: This implementation depends on using latin numerals everywhere. Once it becomes necessary
	// to display RTL numerals (as you've probably guessed by `ltrMarkerPart`), good luck. Currently,
	// we always want to display a "-1,000.000" fragment, regardless of direction. Without the LTR marker,
	// "1,000.000-" would be displayed instead, which is probably undesirable.
	const minusSignPartsBeforeValue =
		minusSignPart && currencyPart.value.length > 1 ? [ltrMarkerPart, minusSignPart] : [];

	const minusSignPartsBeforeCurrency =
		minusSignPart && currencyPart.value.length === 1 ? [minusSignPart] : [];

	return currencyFirst
		? [
				firstPart,
				...minusSignPartsBeforeCurrency,
				currencyPart,
				middlePart,
				...minusSignPartsBeforeValue,
				...valueParts,
				lastPart,
			]
		: [
				firstPart,
				...minusSignPartsBeforeValue,
				...valueParts,
				middlePart,
				// NOTE: `minusSignPartsBeforeCurrency` makes no sense here.
				currencyPart,
				lastPart,
			];
};

const getPartsAfterFraction = parts => {
	const fractionPartIndex = parts.findIndex(part => part.type === FormattedNumberTypes.FRACTION);

	return fractionPartIndex !== -1 ? parts.slice(fractionPartIndex + 1) : [];
};

const addAbbreviationSymbol = (abbreviation, intl) => parts => [
	...parts,
	{
		type: FormattedNumberTypes.LITERAL,
		value: intl.formatMessage(m[abbreviation]),
	},
	...getPartsAfterFraction(parts),
];

const getIntegerPartsIndices = parts =>
	parts.reduce((indices, part, partIndex) => {
		if (part.type === FormattedNumberTypes.INTEGER) {
			return [...indices, partIndex];
		}

		return indices;
	}, []);

// Abbreviation is used to shorten number and replace specific character count on the end of number with string symbol.
// Function removeUndesirableParts remove characters that should be replaced.
const removeUndesirableParts = integerPartsToRemoveCount => parts => {
	const integerPartsIndices = getIntegerPartsIndices(parts);
	const integerPartsToRemoveIndices = integerPartsIndices.splice(
		integerPartsIndices.length - integerPartsToRemoveCount
	);

	return parts.reduce((prevParts, part, partIndex) => {
		if (
			integerPartsToRemoveIndices.includes(partIndex) ||
			integerPartsToRemoveIndices.includes(partIndex + 1)
		) {
			return prevParts;
		}

		return [...prevParts, part];
	}, []);
};

const getIntegerParts = parts => parts.filter(part => part.type === FormattedNumberTypes.INTEGER);

const getLastVisibleIntegerPartIndex = integerPartsToRemoveCount => parts => {
	const integerPartsIndices = getIntegerPartsIndices(parts);
	const visibleIntegerPartsIndices = integerPartsIndices.splice(
		0,
		integerPartsIndices.length - integerPartsToRemoveCount
	);

	return last(visibleIntegerPartsIndices);
};

const getFirstHiddenIntegerPart = integerPartsToRemoveCount => parts => {
	const integerParts = getIntegerParts(parts);
	const hiddenIntegerParts = integerParts.splice(
		integerParts.length - integerPartsToRemoveCount,
		integerParts.length
	);

	return hiddenIntegerParts?.[0];
};

const roundLastVisiblePart = integerPartsToRemoveCount => parts => {
	const lastVisibleIntegerPartIndex =
		getLastVisibleIntegerPartIndex(integerPartsToRemoveCount)(parts);

	const firstHiddenIntegerPart = getFirstHiddenIntegerPart(integerPartsToRemoveCount)(parts);

	return parts.map((part, partIndex) => {
		if (
			partIndex === lastVisibleIntegerPartIndex &&
			firstHiddenIntegerPart &&
			firstHiddenIntegerPart.value >= 500
		) {
			return { ...part, value: (Number(part.value) + 1).toString() };
		}

		return part;
	});
};

const removeFraction = filter(
	part => ![FormattedNumberTypes.DECIMAL, FormattedNumberTypes.FRACTION].includes(part.type)
);

const applyAbbreviation = (value, abbreviation, intl) => {
	const integerPartsToRemoveCount = AbbreviationTable[abbreviation];

	return when(
		// NOTE: `Math.abs` necessary so we can abbreviate negative numbers.
		() => gte(Math.abs(value), 1000 ** integerPartsToRemoveCount),
		compose(
			addAbbreviationSymbol(abbreviation, intl),
			removeUndesirableParts(integerPartsToRemoveCount),
			roundLastVisiblePart(integerPartsToRemoveCount),
			removeFraction
		)
	);
};

const replaceSeparator = options => part => {
	if (part.type === FormattedNumberTypes.GROUP && options.thousandSeparator) {
		return options.thousandSeparator;
	} else if (part.type === FormattedNumberTypes.DECIMAL && options.decimalSeparator) {
		return options.decimalSeparator;
	}

	return part.value;
};

const formatNumber = (value, options, intl) =>
	compose(
		// NOTE: Currencies are just a subset of numbers under our logic.
		options.amountString && (options.currency || options.style === 'currency')
			? formatAsAmount(options.amountString, options.currencySymbol)
			: identity,
		options.abbreviation ? applyAbbreviation(value, options.abbreviation, intl) : identity
	);

const replaceSeparators = options => map(replaceSeparator(options));

export const useResolveNumberFormatter = () => {
	// NOTE: Previously, this selector was imported from `@cbs/intl`, but we can't depend on it here.
	// The formatter won't break without it, so if some application wants to customize the behaviour
	// of this formatter, it needs to have `state.intl.intlOptions` defined.
	const intlOptions = useSelector(state => state?.intl?.intlOptions);
	const intl = useIntl();

	return useCallback(
		getFormatterOptions =>
			makeFormatter((value, suggestions, dataContext) => {
				const valueAsNumber = Number(value);

				if (Number.isNaN(valueAsNumber)) {
					return value;
				}

				if (suggestions.includes('comparable')) {
					return valueAsNumber;
				}

				const formatterOptions = isFunction(getFormatterOptions)
					? getFormatterOptions(value, suggestions, dataContext)
					: getFormatterOptions ?? {};

				const options = mergeDeepRight(intlOptions, formatterOptions);
				const style = options.currency ? 'currency' : options.style;

				const formatResult = compose(
					replaceSeparators(options),
					formatNumber(value, options, intl)
				);

				const numberPartsOptions = {
					maximumFractionDigits: options.decimalPlaces || options.maximumDecimalPlaces,
					minimumFractionDigits: options.decimalPlaces,
					currency: options.currency || options.localCurrencyCode,
					style,
					currencyDisplay: 'code',
				};

				if (suggestions.includes('primitive')) {
					return formatResult(intl.formatNumberToParts(value, numberPartsOptions)).join('');
				}

				return (
					<FormattedNumberParts {...numberPartsOptions} value={value}>
						{formatResult}
					</FormattedNumberParts>
				);
			}),
		[intlOptions, intl]
	);
};

export const useNumberFormatter = simplifyResolverHook(useResolveNumberFormatter);

export const SimplePercentageFormatter = makeFormatter(
	value => (isNumeric(value) ? `${value}%` : ''),
	{
		displayName: 'SimplePercentageFormatter',
	}
);

export const getAmountValue = (amount, shouldUseLocal) =>
	isObject(amount) ? (shouldUseLocal ? amount.localValue : amount.value) : amount;

const getIsMaskedAmount = amount => isObject(amount) && amount.masked;

export const useResolveAmountFormatter = () => {
	const resolveNumberFormatter = useResolveNumberFormatter();
	const messageFormatter = useMessageFormatter(identity);

	return useCallback(
		(options = {}) => {
			const { useLocalCurrency, defaultUseLocalCurrency } = options;
			const shouldUseLocal = useLocalCurrency ?? defaultUseLocalCurrency;

			const getFormatterOptions = (value, suggestions, { amount }) => {
				const formatterOptions = {
					style: 'currency',
					...options,
				};

				if (!shouldUseLocal && isObject(amount)) {
					if (amount.currency && amount.currency !== 'NotSpecified') {
						formatterOptions.currency = amount.currency;
					}

					// NOTE: `currencySymbol` can be an empty string because we might want to display
					// the currency in a standalone field.
					if (isNotNil(amount.currencySymbol) && amount.currencySymbol !== 'NotSpecified') {
						formatterOptions.currencySymbol = amount.currencySymbol;
					}
				}

				return formatterOptions;
			};

			const numberFormatter = resolveNumberFormatter(getFormatterOptions);

			return makeFormatter(
				(value, suggestions) => {
					if (getIsMaskedAmount(value)) {
						return messageFormatter.format(m.maskedAmount, suggestions);
					}

					const amountValue = getAmountValue(value, shouldUseLocal);

					return numberFormatter.format(amountValue, suggestions, {
						amount: value,
					});
				},
				{
					displayName: 'AmountFormatter',
				}
			);
		},
		[messageFormatter, resolveNumberFormatter]
	);
};

export const useAmountFormatter = simplifyResolverHook(useResolveAmountFormatter);

const integerOptions = {
	decimalPlaces: 0,
};

export const useIntegerFormatter = () => useNumberFormatter(integerOptions);

const currencyOptions = {
	style: 'currency',
};

export const useCurrencyFormatter = () => useNumberFormatter(currencyOptions);

const decimalOptions = {
	maximumDecimalPlaces: 20,
	decimalPlaces: 0,
};

export const useDecimalFormatter = () => useNumberFormatter(decimalOptions);
