import 'core-js/stable';
import 'regenerator-runtime/runtime';

Object.assign = require('object-assign');

if (typeof window !== 'undefined') {
	require('whatwg-fetch');
	require('events-polyfill/event-constructor-polyfill');

	// NOTE: `documentMode` is only defined for Internet Explorer.
	if (window.document.documentMode) {
		// HACK: `react-helmet` throws an error when developing in IE 11 with an `eval-` devtool.
		// Upgrading to a newer version of `react-helmet` should have solved the issue, but the error
		// still occurs. The underlying issue is that `requestAnimationFrame` isn't called as a method
		// under the hood, which breaks inside an `eval()`. Hence, we solve this issue ourselves.
		// See https://github.com/nfl/react-helmet/issues/336 for more information.
		window.requestAnimationFrame = window.requestAnimationFrame.bind(window);
		window.cancelAnimationFrame = window.cancelAnimationFrame.bind(window);
	}
}
