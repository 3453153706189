import { downloadFile } from './downloadFile';

declare global {
	interface Window {
		BlobBuilder: any;
		MSBlobBuilder: any;
		MozBlobBuilder: any;
		WebKitBlobBuilder: any;
	}
}

export const downloadTextFile = (content: string, filename: string, mimetype: string) => {
	let blob: Blob | null = null;

	try {
		const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
		blob = new Blob([bom, content], { type: mimetype });
	} catch (error: any) {
		if (error.name === 'InvalidStateError') {
			// HACK: Change of global variable is necessary for Internet Explorer.
			window.BlobBuilder =
				window.BlobBuilder ||
				window.WebKitBlobBuilder ||
				window.MozBlobBuilder ||
				window.MSBlobBuilder;

			if (window.BlobBuilder) {
				const blobBuilder = new window.BlobBuilder();

				blobBuilder.append(content);
				blob = blobBuilder.getBlob(mimetype);
			}
		}
	}

	if (blob) {
		downloadFile(blob, filename);
	}
};
