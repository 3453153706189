import { useMemo } from 'react';
import { makeFormatter } from 'afformative';
import { useSelector } from 'react-redux';
import { getLocale } from '@myci/intl';
import { getEnvironmentVariable } from '@myci/utils';

import { AdministrativeUnit } from './duck';
import { indexAdministrativeUnitsByCode } from './utils';

const defaultLocale = getEnvironmentVariable('GATSBY_DEFAULT_LOCALE')!;

export const useAdministrativeUnitCodeFormatter = (units: AdministrativeUnit[]) => {
	const locale = useSelector(getLocale);
	const unitsByCode = useMemo(() => indexAdministrativeUnitsByCode(units), [units]);

	return useMemo(
		() =>
			makeFormatter((unitCode: string) => {
				const unit = unitsByCode[unitCode];

				return unit?.names?.[locale] ?? unit?.names?.[defaultLocale] ?? unitCode;
			}),
		[locale, unitsByCode]
	);
};
