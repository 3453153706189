import React from 'react';
import { Message } from '@myci/intl';

import m from '../../messages';
import Button from '../Button';

const BackButton = props => (
	<Button className="align-self-start" icon="back" size="xs" {...props}>
		<Message {...m.backButton} />
	</Button>
);

BackButton.propTypes = {
	...Button.propTypes,
};

export default BackButton;
