import { prepareStyle } from '@creditinfo-ui/styles';

export const linkStyle = prepareStyle(utils => ({
	backgroundColor: 'transparent',
	border: 0,
	color: utils.colors.gray700,
	fontWeight: utils.fontWeights.normal,
	height: 'auto',
	padding: 0,
	textDecoration: 'underline',
	textTransform: 'capitalize',
}));

// NOTE: Copied over from Bootstrap.
export const visuallyHiddenStyle = prepareStyle(() => ({
	border: 0,
	clip: 'rect(0, 0, 0, 0)',
	height: '1px',
	margin: '-1px',
	overflow: 'hidden',
	padding: 0,
	position: 'absolute',
	whiteSpace: 'nowrap',
	width: '1px',
}));

export const displayNoneStyle = prepareStyle(() => ({ display: 'none' }));

export const overflowWrapAnywhereStyle = prepareStyle(() =>
	'documentMode' in window.document
		? {
				// NOTE: `wordBreak: 'break-word'` should have a similar effect as `overflowWrap: 'anywhere'`,
				// but is deprecated. However, it's supported by IE11 (unlike `overflowWrap: 'anywhere'`).
				// https://developer.mozilla.org/en-US/docs/Web/CSS/word-break#values
				// https://caniuse.com/mdn-css_properties_overflow-wrap_anywhere
				wordBreak: 'break-word',
			}
		: {
				overflowWrap: 'anywhere',
			}
);
