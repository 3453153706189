import { rtlScrollMode, rtlScrollModes } from './rtlScrollMode';

// NOTE: Resolves to comply with `rtlScrollModes.default`.
export const resolveScrollLeft = (element: Element) => {
	if (typeof window === 'undefined') {
		return NaN;
	}

	const rtl = window.getComputedStyle(element).direction === 'rtl';
	const scrollLeft = element?.scrollLeft;

	// NOTE: Chrome.
	if (!rtl || rtlScrollMode === rtlScrollModes.default) {
		return scrollLeft;
	}

	// NOTE: Firefox.
	if (rtlScrollMode === rtlScrollModes.negative) {
		return scrollLeft + element?.scrollWidth - element?.clientWidth;
	}

	// NOTE: IE.
	if (rtlScrollMode === rtlScrollModes.reverse) {
		return element?.scrollWidth - element?.clientWidth - scrollLeft;
	}

	return NaN;
};
