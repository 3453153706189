import { ReactNode } from 'react';

export interface DependencyProducerProps {
	children?: ReactNode;
}

type ResolveDependency<T> = T extends (...args: any[]) => any ? T : () => T;

export const createReactHookThunkDependency = <TDependency extends unknown>(
	useDependency: () => TDependency
) => {
	const dependencyRef: { current: TDependency | null } = { current: null };

	const DependencyProducer = ({ children }: DependencyProducerProps) => {
		const dependency = useDependency();

		dependencyRef.current = dependency;

		return <>{children}</>;
	};

	const resolveDependency: ResolveDependency<TDependency> = ((...args: any[]) =>
		typeof dependencyRef.current === 'function'
			? dependencyRef.current(...args)
			: dependencyRef.current) as any;

	return [DependencyProducer, resolveDependency] as const;
};
