import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Message } from '@myci/intl';
import { isInBrowser } from '@myci/utils';

import m from './messages';

import { getAuthorizationCode, logOut, selectIsAuthenticating, selectRefreshToken } from './duck';

const Secure = ({ children, component: Component, ...otherProps }) => {
	const refreshToken = useSelector(selectRefreshToken);
	const isAuthenticating = useSelector(selectIsAuthenticating);
	const dispatch = useDispatch();

	const shouldLogOut = !refreshToken && !isAuthenticating && !getAuthorizationCode();

	useEffect(() => {
		if (shouldLogOut) {
			dispatch(logOut());
		}
	}, [shouldLogOut]);

	if (!isInBrowser()) {
		return null;
	}

	if (shouldLogOut) {
		return <Message {...m.redirecting} />;
	}

	if (Component) {
		return <Component {...otherProps} />;
	}

	return children;
};

Secure.propTypes = {
	children: PropTypes.node,
	component: PropTypes.elementType,
};

export default Secure;
