import { Button } from '@creditinfo-ui/atoms';
import { MouseEventHandler, useContext } from 'react';

import { ModalInstanceContext } from '../../contexts';
import { useModal } from '../../hooks';
import { m } from '../../messages';

export interface SlideModalFooterCloseButtonProps {
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const SlideModalFooterCloseButton = ({ onClick }: SlideModalFooterCloseButtonProps) => {
	const modalInstance = useContext(ModalInstanceContext);
	const modal = useModal(modalInstance!);

	return (
		<Button
			icon="close"
			message={m.close}
			onClick={onClick ?? modal.hide}
			variant={['secondary', { isOutlined: true }]}
		/>
	);
};
