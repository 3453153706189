import { makeFormatter } from 'afformative';
import { TOP_OVERLAY_CLASS_NAME } from './constants';
import { HorizontalSpacing, VerticalSpacing } from './types';

export const identityFormatter = makeFormatter<any, any>(value => value, {
	displayName: '@creditinfo-ui/atoms/identityFormatter',
});

export const getElementTotalWidth = (node: HTMLElement): number => {
	const nodeStyle = window.getComputedStyle(node);

	return (
		parseInt(nodeStyle.getPropertyValue('margin-left')) +
		parseInt(nodeStyle.getPropertyValue('margin-right')) +
		node.offsetWidth
	);
};

export const resolveVerticalMargin = (verticalMargin: VerticalSpacing | undefined) => {
	const marginBottom =
		(typeof verticalMargin === 'string' ? verticalMargin : verticalMargin?.bottom) ?? 'none';

	const marginTop =
		(typeof verticalMargin === 'string' ? verticalMargin : verticalMargin?.top) ?? 'none';

	return {
		marginBottom,
		marginTop,
	};
};

export const resolveHorizontalMargin = (horizontalMargin: HorizontalSpacing | undefined) => {
	const marginInlineEnd =
		(typeof horizontalMargin === 'string' ? horizontalMargin : horizontalMargin?.end) ?? 'none';

	const marginInlineStart =
		(typeof horizontalMargin === 'string' ? horizontalMargin : horizontalMargin?.start) ?? 'none';

	return {
		marginInlineEnd,
		marginInlineStart,
	};
};

export const resolveVerticalPadding = (verticalPadding: VerticalSpacing | undefined) => {
	const paddingBottom =
		(typeof verticalPadding === 'string' ? verticalPadding : verticalPadding?.bottom) ?? 'none';

	const paddingTop =
		(typeof verticalPadding === 'string' ? verticalPadding : verticalPadding?.top) ?? 'none';

	return {
		paddingBottom,
		paddingTop,
	};
};

export const getHasElementOverflowHidden = (element: HTMLElement) =>
	window.getComputedStyle(element).overflow === 'hidden';

export const getHasElementPositionFixed = (element: HTMLElement) =>
	window.getComputedStyle(element).position === 'fixed';

export const queryBottomOverlay = () => {
	const footers = document.querySelectorAll('footer');

	for (const footer of footers) {
		const computedStyle = window.getComputedStyle(footer);

		if (computedStyle.position === 'fixed' && computedStyle.bottom.startsWith('0')) {
			return footer;
		}
	}

	return null;
};

export const queryTopOverlay = () => {
	const topOverlay = document.querySelector(`.${TOP_OVERLAY_CLASS_NAME}`);

	if (!topOverlay) {
		return null;
	}

	const computedStyle = window.getComputedStyle(topOverlay);

	if (computedStyle.position === 'fixed' && computedStyle.top.startsWith('0')) {
		return topOverlay;
	}

	return null;
};

export const queryAncestor = (
	startElement: HTMLElement | null,
	predicate: (element: HTMLElement) => boolean
) => {
	if (!startElement) {
		return null;
	}

	let element = startElement;

	while (element.parentElement) {
		element = element.parentElement;

		if (predicate(element)) {
			return element;
		}
	}

	return null;
};

export const queryFixedContainerFooter = (referenceElement: HTMLElement | null) => {
	if (!referenceElement) {
		return null;
	}

	const fixedContainer = getHasElementPositionFixed(referenceElement)
		? referenceElement
		: queryAncestor(referenceElement, getHasElementPositionFixed);

	if (!fixedContainer) {
		return null;
	}

	const footer = fixedContainer.querySelector('footer');

	if (!footer) {
		return null;
	}

	const computedStyle = window.getComputedStyle(footer);

	if (
		computedStyle.position === 'absolute' ||
		computedStyle.position === 'fixed' ||
		// NOTE: `distance >= 1` is used because `getBoundingClientRect()` can return subpixel values
		// when zoomed in/out.
		Math.abs(footer.getBoundingClientRect().bottom - window.innerHeight) >= 1
	) {
		return null;
	}

	return footer;
};
