import { assoc, dissoc } from 'ramda';
import { isNotEmpty, noop } from 'ramda-extension';
import {
	ComponentType,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useIntl } from 'react-intl';
import { m } from './messages';

type SourceId = string;

type UnsavedChangesStore = Partial<Record<SourceId, true>>;

interface RegisterUnsavedChanges {
	(sourceId: SourceId, hasUnsavedChanges: boolean): void;
}

const UnsavedChangesBoundaryContext = createContext<{
	register: RegisterUnsavedChanges;
	store: UnsavedChangesStore;
}>({
	register: noop,
	store: {},
});

export interface UnsavedChangesBoundaryProps {
	children: ReactNode;
}

/**
 * Creates a boundary for unsaved changes. The unsaved changes flag will be propagated to the
 * parent boundaries, but the nesting enables the usage of the `useHasCurrentBoundaryUnsavedChanges`
 * hook. This is useful especially with modals. If you have a form inside a modal, you don't want
 * to accidentally close the modal. If you have a modal inside a form, it's business as usual.
 */
export const UnsavedChangesBoundary = ({ children }: UnsavedChangesBoundaryProps) => {
	const { register: parentRegister } = useContext(UnsavedChangesBoundaryContext);
	const [store, setStore] = useState<UnsavedChangesStore>({});

	const register: RegisterUnsavedChanges = useCallback(
		(sourceId, hasUnsavedChanges) => {
			setStore(previousStore =>
				hasUnsavedChanges ? assoc(sourceId, true, previousStore) : dissoc(sourceId, previousStore)
			);

			// NOTE: We use this parent registering to propagate the information to the topmost boundary.
			parentRegister(sourceId, hasUnsavedChanges);
		},
		[parentRegister]
	);

	const contextValue = useMemo(() => ({ store, register }), [store, register]);

	return (
		<UnsavedChangesBoundaryContext.Provider value={contextValue}>
			{children}
		</UnsavedChangesBoundaryContext.Provider>
	);
};

export const withUnsavedChangesBoundary =
	<TProps extends object>(Component: ComponentType<TProps>) =>
	(props: TProps) => (
		<UnsavedChangesBoundary>
			<Component {...props} />
		</UnsavedChangesBoundary>
	);

/**
 * Use this hook to indicate that a form has unsaved changes. Source ID can be e.g. `forms.something`.
 */
export const useUnsavedChangesProducer = (sourceId: SourceId, hasUnsavedChanges: boolean) => {
	const { register } = useContext(UnsavedChangesBoundaryContext);

	useEffect(() => {
		register(sourceId, hasUnsavedChanges);

		return () => {
			register(sourceId, false);
		};
	}, [sourceId, hasUnsavedChanges, register]);
};

/**
 * Returns whether there are any unsaved changes in the current boundary.
 */
export const useHasCurrentBoundaryUnsavedChanges = () => {
	const { store } = useContext(UnsavedChangesBoundaryContext);

	return isNotEmpty(store);
};

export const useConfirmNavigation = () => {
	const intl = useIntl();

	// NOTE: It's not possible to use the same message that the browser uses for `beforeunload`.
	// eslint-disable-next-line no-alert
	return useCallback(() => window.confirm(intl.formatMessage(m.prompt)), [intl]);
};

export type UnsavedChangesStrategy = ComponentType<{ children?: ReactNode }>;

export interface UnsavedChangesProviderProps {
	children?: ReactNode;
	strategy: UnsavedChangesStrategy;
}

const PureUnsavedChangesProvider = ({
	children,
	strategy: Strategy,
}: UnsavedChangesProviderProps) => <Strategy>{children}</Strategy>;

/**
 * This provider must wrap the entire application tree.
 */
export const UnsavedChangesProvider = withUnsavedChangesBoundary(PureUnsavedChangesProvider);
