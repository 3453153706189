export const storageKeys = {
	REFRESH_TOKEN_KEY: '@myci/authentication/REFRESH_TOKEN',
	ACCESS_TOKEN_KEY: '@myci/authentication/ACCESS_TOKEN',
	MYCI_STORE_KEY: '@myci/MYCI_STORE_KEY',
	PUSH_NOTIFICATIONS_TOKEN: '@myci/PUSH_NOTIFICATIONS_TOKEN',
	SELECTED_LOCALE: '@myci/SELECTED_LOCALE',
	TEMP_REGISTRATION_DATA: '@myci/TEMP_REGISTRATION_DATA',
	TEMP_REQUESTED_ORDER_DATA: '@myci/TEMP_REQUESTED_ORDER_DATA',
	TEMP_REQUESTED_PRODUCT_ORDER_DATA: '@myci/TEMP_REQUESTED_PRODUCT_ORDER_DATA',
	TEMP_POST_LOGIN_ROUTE: '@myci/TEMP_POST_LOGIN_ROUTE',
	TEMP_PAYMENT_GATEWAY_TYPE: '@myci/TEMP_PAYMENT_GATEWAY_TYPE',
	TEMP_PAYMENT_SUCCESS_INDICATOR: '@myci/TEMP_PAYMENT_SUCCESS_INDICATOR',
	MYCI_LOCALE_KEY: '@myci/locale',
};
