import untypedAllTranslations from '../data/allTranslations.json';

export {
	default as reducer,
	middleware,
	getLocaleFromStorage,
	loadLocale,
	isCurrentLocaleUserRequested,
	setLocaleInStorage,
} from './duck';
export { polyfillLocale } from './polyfillLocale';
export { polyfillIntl } from './polyfillIntl';
export { loadAllTranslations } from './loadAllTranslations';
export * from './selectors';
export { useEnsureLocale } from './useEnsureLocale';

export { default as TranslationModeProvider } from './components/TranslationModeProvider';
export { default as IntlProvider } from './components/IntlProvider';
export { default as MessageList } from './components/MessageList';
export { useIntl } from 'react-intl';

export * from './utils';

export { Message } from '@ci/message';

type Translations = Record<string, Record<string, string>>;
export const allTranslations = untypedAllTranslations as Translations;
