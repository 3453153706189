/**
 * @description Same purpose as native `atob`, but unlike `atob`, handling UTF-8 characters like `Ā` or `Ņ` correctly
 * source: https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 * @param input base64 encoded string
 */
export const decodeBase64 = (input: string) => {
	const parsedInput = atob(input);
	const bytes = new Uint8Array(parsedInput.length);

	for (let i = 0; i < parsedInput.length; i++) {
		bytes[i] = parsedInput.charCodeAt(i);
	}

	const decoder = new TextDecoder();
	return decoder.decode(bytes);
};
