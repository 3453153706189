import copyToClipboardLegacy from 'copy-to-clipboard';

export const copyToClipboard = async (content: string): Promise<boolean> => {
	if (navigator.clipboard && window.isSecureContext) {
		try {
			await navigator.clipboard.writeText(content);

			return true;
		} catch {
			return copyToClipboardLegacy(content);
		}
	}

	return copyToClipboardLegacy(content);
};
