import { createThunk } from '@ci/api';
import { logOut, selectAccessToken } from '@myci/authentication';
import { isInBrowser as getIsInBrowser } from '@myci/utils';
import { addToast } from '@ci/toasts';
import { m } from './messages';

export const performSessionTimeout = createThunk(
	'@sessionTimeout/performSessionTimeout',
	async ({ dispatch, getState }) => {
		const isLoggedIn = Boolean(selectAccessToken(getState()));
		if (isLoggedIn) {
			if (getIsInBrowser()) {
				dispatch(logOut());
			} else {
				dispatch(logOut());
				dispatch(
					addToast({
						type: 'warning',
						content: m.loggedOut,
					})
				);
			}
		}
	}
);
