import { ReactNode } from 'react';
import { mergeStyles, prepareStyle, useStyles } from '@ci/styles';
import { overflowWrapAnywhereStyle } from '@ci/atoms';
import { CollapsibleTitle, CollapsibleTitleProps } from './CollapsibleTitle';
import { useCollapsibleControls } from '../hooks';

const collapsibleStatusWrapperStyle = prepareStyle<{ isExpanded: boolean }>(
	(utils, { isExpanded }) => ({
		alignItems: 'flex-start',
		display: 'flex',
		flexDirection: 'column',
		gap: utils.spacings.xs,
		opacity: 1,
		overflow: 'visible',
		transitionDuration: utils.transitions.speeds.faster,
		transitionProperty: 'opacity',
		transitionTimingFunction: utils.transitions.easing,
		width: '100%',
		selectors: {
			[utils.breakpoints.lg]: {
				alignItems: 'center',
				flexDirection: 'row',
				gap: utils.spacings.md,
			},
			[utils.breakpoints.xl]: {
				gap: utils.spacings.lg,
			},
		},
		extend: {
			condition: isExpanded,
			style: {
				height: 0,
				opacity: 0,
				overflow: 'hidden',
				selectors: {
					[utils.breakpoints.xl]: {
						height: 'auto',
					},
				},
			},
		},
	})
);

const collapsibleStatusStyle = prepareStyle(() => ({
	alignItems: 'center',
	display: 'flex',
	flexWrap: 'wrap',
}));

const collapsibleStatusItemStyle = prepareStyle(utils => ({
	color: utils.colors.gray500,
	selectors: {
		':not(:last-child)': {
			marginInlineEnd: utils.spacings.md,
		},
	},
}));

const collapsibleTitleStyle = prepareStyle(() => ({
	flexShrink: 0,
}));

const labelStyle = prepareStyle(() => ({
	whiteSpace: 'nowrap',
}));

export interface CollapsibleFormStatusProps {
	items: Array<[ReactNode, ReactNode]>;
	title?: ReactNode;
	titleProps?: Omit<CollapsibleTitleProps, 'title'>;
}

export const CollapsibleFormStatus = ({ items, title, titleProps }: CollapsibleFormStatusProps) => {
	const { applyStyle } = useStyles();
	const { isExpanded } = useCollapsibleControls();

	return (
		<div className={applyStyle(collapsibleStatusWrapperStyle, { isExpanded })}>
			{title && (
				<CollapsibleTitle
					{...titleProps}
					customStyle={mergeStyles([collapsibleTitleStyle, titleProps?.customStyle])}
					title={title}
				/>
			)}
			<div className={applyStyle(collapsibleStatusStyle)}>
				{items.map(([label, value], index) => (
					<div key={index} className={applyStyle(collapsibleStatusItemStyle)}>
						<span className={applyStyle(labelStyle)}>{label}:&nbsp;</span>
						<span className={applyStyle(overflowWrapAnywhereStyle)}>{value}</span>
					</div>
				))}
			</div>
		</div>
	);
};
