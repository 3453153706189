import React, { Children, cloneElement, useRef } from 'react';
import PropTypes from 'prop-types';
import { TabBar } from '@myci/ui-components';

import {
	useHasJourneyStepErrors,
	useIsJourneyStepDirty,
	useJourneyControls,
	useJourneyCurrentStep,
} from '../hooks';

const JourneyTabs = ({ children, wizard, namespace }) => {
	const { setNumberOfSteps, goToStep } = useJourneyControls(namespace);

	const currentStep = useJourneyCurrentStep(namespace);
	const isStepDirty = useIsJourneyStepDirty(namespace);
	const hasJourneyStepErrors = useHasJourneyStepErrors(namespace);

	const numberOfStepsRef = useRef();

	const journeyTabs = Children.map(children, (child, index) => {
		const step = index + 1;

		return child
			? cloneElement(child, {
					stepNumber: wizard && step,
					isDisabled: wizard && currentStep < step,
					isDone: wizard && currentStep > step,
					isActive: currentStep === step,
					isInvalid: isStepDirty(step) && hasJourneyStepErrors(step),
					onClick: e => {
						e.preventDefault();

						if (!wizard || currentStep > step) {
							goToStep(step);
						}
					},
				})
			: child;
	});

	const currentNumberOfSteps = Children.count(journeyTabs);

	if (numberOfStepsRef.current !== currentNumberOfSteps) {
		numberOfStepsRef.current = currentNumberOfSteps;

		setNumberOfSteps(currentNumberOfSteps);
	}

	return <TabBar>{journeyTabs}</TabBar>;
};

JourneyTabs.propTypes = {
	children: PropTypes.node,
	namespace: PropTypes.string,
	wizard: PropTypes.bool,
};
export default JourneyTabs;
