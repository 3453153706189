import { Text } from '@creditinfo-ui/atoms';
import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { cx } from 'ramda-extension';
import { ReactNode } from 'react';

import {
	SLIDE_MODAL_CONTROLS_HEIGHT,
	SLIDE_MODAL_MOBILE_CONTROLS_HEIGHT,
	SLIDE_MODAL_MOBILE_PADDING_X,
	SLIDE_MODAL_PADDING_X,
} from './SlideModal';

const slideModalHeaderStyle = prepareStyle(utils => ({
	alignItems: 'end',
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	marginBottom: utils.spacings.md,
	marginTop: utils.spacings.sm,
	padding: `${SLIDE_MODAL_MOBILE_CONTROLS_HEIGHT} ${SLIDE_MODAL_MOBILE_PADDING_X} 0`,

	selectors: {
		[utils.breakpoints.md]: {
			padding: `${SLIDE_MODAL_CONTROLS_HEIGHT} ${SLIDE_MODAL_PADDING_X} 0`,
		},
	},
}));

const slideModalTitleStyle = prepareStyle(utils => ({
	alignItems: 'center',
	display: 'flex',
	marginBottom: utils.spacings.sm,
	width: '100%',

	selectors: {
		[utils.breakpoints.sm]: {
			marginBottom: 0,
			width: 'auto',
		},
	},
}));

const slideModalTitleTextStyle = prepareStyle(utils => ({
	marginInlineEnd: utils.spacings.sm,
	overflowWrap: 'anywhere',
}));

const slideModalSubtitleStyle = prepareStyle(utils => ({
	fontSize: '1.8rem',
	marginBottom: 0,
	marginTop: utils.spacings.sm,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',

	selectors: {
		[utils.breakpoints.md]: {
			fontSize: '2rem',
		},
	},
}));

const slideModalDetailsStyle = prepareStyle(utils => ({
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'wrap',

	selectors: {
		[utils.breakpoints.sm]: {
			flexDirection: 'row',
		},
	},
}));

export interface SlideModalHeaderProps {
	className?: string;
	details?: ReactNode;
	status?: ReactNode;
	subtitle?: ReactNode;
	title: ReactNode;
}

export const SlideModalHeader = ({
	className,
	details,
	status,
	subtitle,
	title,
}: SlideModalHeaderProps) => {
	const { applyStyle } = useStyles();

	return (
		<div className={cx(applyStyle(slideModalHeaderStyle), className)}>
			<div className={applyStyle(slideModalTitleStyle)}>
				<Text customStyle={slideModalTitleTextStyle} variant="extraTitle">
					{title}
				</Text>
				{status}
			</div>
			{subtitle && <div className={applyStyle(slideModalSubtitleStyle)}>{subtitle}</div>}
			{details && <div className={applyStyle(slideModalDetailsStyle)}>{details}</div>}
		</div>
	);
};
