import React, { ReactElement } from 'react';
import { cx } from 'ramda-extension';

import Box from '../Box';

interface ContentInnerProps {
	children: ReactElement;
	className?: string;
	withoutShadow?: boolean;
}
const ContentInner = ({ withoutShadow, children, className, ...rest }: ContentInnerProps) => (
	<Box
		className={cx('content-inner', withoutShadow && 'content-inner--without-shadow', className)}
		{...rest}
	>
		{children}
	</Box>
);

export default ContentInner;
