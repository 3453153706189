import { Formatter, makeFormatter } from 'afformative';
import { ReactNode, useCallback } from 'react';
import { Icon, IconType, Text } from '@creditinfo-ui/atoms';
import { MessageDescriptor, useIntl } from 'react-intl';
import { isNil as getIsNil } from 'ramda';
import { Message } from '@creditinfo-ui/messages';
import { simplifyUseResolve } from '@creditinfo-ui/utils';
import { m } from '../messages';

export type BooleanFormat =
	| 'yesNo'
	| 'yesNo_swapIcons'
	| 'openClosed'
	| 'onOff'
	| 'enabledDisabled';

const defaultFormat = 'yesNo';

const messageGetters: Record<BooleanFormat, (value: any) => MessageDescriptor> = {
	yesNo: (value: any) => (value ? m.yes : m.no),
	yesNo_swapIcons: (value: any) => (value ? m.yes : m.no),
	openClosed: (value: any) => (value ? m.open : m.closed),
	onOff: (value: any) => (value ? m.on : m.off),
	enabledDisabled: (value: any) => (value ? m.enabled : m.disabled),
};

const iconGetters: Record<BooleanFormat, (value: any) => IconType> = {
	yesNo: (value: any) => (value ? 'statusSuccess' : 'statusFailure'),
	yesNo_swapIcons: (value: any) => (value ? 'statusFailure' : 'statusSuccess'),
	openClosed: (value: any) => (value ? 'statusSuccess' : 'statusFailure'),
	onOff: (value: any) => (value ? 'statusSuccess' : 'statusFailure'),
	enabledDisabled: (value: any) => (value ? 'statusSuccess' : 'statusFailure'),
};

export type BooleanFormatter = Formatter<boolean | null | undefined, ReactNode, string>;

export interface BooleanFormatterOptions {
	isStrict?: boolean;
	shouldNegateValue?: boolean;
}

export interface ResolveBooleanFormatter {
	(format?: BooleanFormat, shouldNegateValue?: boolean): BooleanFormatter;
	(format?: BooleanFormat, options?: BooleanFormatterOptions): BooleanFormatter;
}

export const useResolveBooleanFormatter = () => {
	const intl = useIntl();

	const resolveBooleanFormatter: ResolveBooleanFormatter = useCallback(
		(format = defaultFormat, options) => {
			const getMessage = (format ? messageGetters[format] : null) ?? messageGetters[defaultFormat];
			const getIcon = (format ? iconGetters[format] : null) ?? iconGetters[defaultFormat];

			const { isStrict = false, shouldNegateValue = false } =
				typeof options === 'boolean' ? { shouldNegateValue: options } : options ?? {};

			return makeFormatter<boolean | null | undefined, ReactNode, string>((value, suggestions) => {
				if (getIsNil(value) && isStrict) {
					return '';
				}

				const resolvedValue = shouldNegateValue ? !value : value;
				const message = getMessage(resolvedValue);

				if (suggestions.includes('primitive')) {
					return intl.formatMessage(message);
				}

				const icon = getIcon(resolvedValue);

				if (suggestions.includes('with-icon')) {
					return <Text icon={icon} isIconInline message={message} />;
				}

				if (suggestions.includes('as-icon')) {
					return <Icon type={icon} />;
				}

				return <Message {...message} />;
			});
		},
		[intl]
	);

	return resolveBooleanFormatter;
};

export const useBooleanFormatter = simplifyUseResolve(useResolveBooleanFormatter);
