import { defineMessages } from 'react-intl';

export default defineMessages({
	downloadError: {
		id: 'attachments.downloadError',
		defaultMessage: 'Downloading attachment failed',
		description: 'Error handling string for attachment download',
	},
	scanError: {
		id: 'attachments.scanError',
		defaultMessage: 'Scanning attachment failed',
		description: 'Error handling notification string for attachment antimalware scan',
	},
	scanningForMalware: {
		id: 'attachments.scanningForMalware',
		defaultMessage: 'Scanning for malware...',
		description: 'Text placeholder for file input performing malware scan',
	},
	malwarePresent: {
		id: 'attachments.malwarePresent',
		defaultMessage: 'Malware detected. File download restricted.',
		description: 'Text placeholder for attachment control disabled due to malware',
	},
	scanFailed: {
		id: 'attachments.scanFailed',
		defaultMessage: 'Failed to scan the file.',
		description: 'Text placeholder for attachment control disabled due to failure in scanning',
	},
	fileInputControlBrowse: {
		id: 'attachments.fileInputControlBrowse',
		defaultMessage: 'browse',
	},
	fileInputControlDownload: {
		id: 'attachments.fileInputControlDownload',
		defaultMessage: 'Download file',
	},
	fileInputControlReplace: {
		id: 'attachments.fileInputControlReplace',
		defaultMessage: 'Replace file',
	},
	fileInputControlView: {
		id: 'attachments.fileInputControlView',
		defaultMessage: 'View file',
	},
	fileUploadDescription: {
		id: 'attachments.fileUploadDescription',
		defaultMessage: 'Drag and drop files here or {link} your computer.',
	},
	fileUploadFileTypeAndFileSizeDescription: {
		id: 'attachments.fileUploadFileTypeAndFileSizeDescription',
		defaultMessage: 'You can upload {extensions}. Maximum file size is {size}.',
	},
	attachment_downloadText: {
		id: 'attachments.attachment_downloadText',
		defaultMessage: 'Click {link} to download form',
	},
	attachment_linkText: {
		id: 'attachments.attachment_linkText',
		defaultMessage: 'here',
	},
});
