export const getDefaultScrollingElement = () =>
	document.scrollingElement ?? document.documentElement;

export const getScrollingParent = (startElement: HTMLElement): Element => {
	let element = startElement.parentElement;

	while (element) {
		const overflow = window.getComputedStyle(element).overflow;

		if (overflow.includes('scroll') || overflow.includes('auto')) {
			return element;
		}

		element = element.parentElement;
	}

	return getDefaultScrollingElement();
};
