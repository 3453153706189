import { o, path, join, reject, isEmpty, F } from 'ramda';
import {
	isNilOrEmptyString,
	isPlainObject,
	rejectNil,
	isArray,
	alwaysEmptyObject,
} from 'ramda-extension';
import {
	createUrl,
	serializeQueryParams,
	appendQueryParams,
	makeMiddleware,
	sleep,
} from '@ci/utils';
import invariant from 'invariant';

import { getListData, getPaginationData, getPaginationQueryString } from './features/pagination';
import { getFilteringQueryString } from './features/filtering';
import { getSortingQueryString } from './features/sorting';
import { getColumnsQueryString } from './features/columns';
import {
	errorEvent,
	successEvent,
	retryingEvent,
	addPendingRequest,
	removePendingRequest,
} from './actions';
import {
	getIsAnyRequestAction,
	getOrigin,
	getIsAnySuccessResponseAction,
	getIsAnyErrorResponseAction,
} from './utils';
import { selectIsRequestOutdated } from './features/timestamps';

const joinQueryStrings = o(join('&'), reject(isNilOrEmptyString));
const rejectEmpty = reject(isEmpty);

export const makeRequestMiddleware = ({
	selectHeaders,
	credentials,
	getHasGenericErrorHandling = F,
	getParsedErrorPayload,
	getParsedResponseMeta = alwaysEmptyObject,
	baseUrl,
}) =>
	makeMiddleware(getIsAnyRequestAction, ({ dispatch, getState }) => async action => {
		const {
			// TODO: Move pagination and filtering out of this middleware (plugin mechanism).
			pagination,
			filtering,
			sorting,
			// TODO: create more general solution through enumeration of content types
			isCsv,
			queryParams = {},
			url: metaUrl,
			method = 'GET',
			headers = {},
			body,
			retryTimes = 0,
			retryInterval = 1000,
			grid,
			csvColumns,
			baseUrl: metaBaseUrl,
		} = action.payload;

		const origin = getOrigin(action);

		invariant(origin.type, 'API requests must have the origin action in `action.meta.origin`.');

		invariant(
			!getIsAnySuccessResponseAction(action) && !getIsAnyErrorResponseAction(action),
			"API request's `action.meta.origin` type must not be an API event."
		);

		const makeBeforeResponseMeta = additionalMeta =>
			rejectNil({
				// NOTE: We are spreading `origin.meta` just for convenience. If a control flow task is not
				// an API request but e.g. `fetchBatches()`, we can use `origin.meta` to retrieve the task ID
				// instead of having to spread `action.meta` in every user middleware.
				...origin.meta,
				...action.meta,
				...additionalMeta,
				request: action,
			});

		const customQueryString = serializeQueryParams(queryParams);
		const paginationQueryString = getPaginationQueryString(pagination);
		const filteringQueryString = getFilteringQueryString(filtering);
		const sortingQueryString = getSortingQueryString(sorting);

		// NOTE: We do not want the API package to depend on the data grid package. Because the API middleware does not have
		// a plugin or configuration mechanism, this is the best way to implement this functionality without cyclic dependencies.
		const dataGridState = path(['@ci/data-grid', grid], getState());

		const currentColumnKeys = dataGridState?.fieldOrder.filter(column =>
			dataGridState?.shownFields.includes(column)
		);

		const gridColumnsQueryString = grid ? getColumnsQueryString(currentColumnKeys) : null;

		const csvColumnsQueryString = csvColumns ? getColumnsQueryString(csvColumns) : null;

		const queryString = joinQueryStrings([
			csvColumnsQueryString,
			gridColumnsQueryString,
			customQueryString,
			paginationQueryString,
			filteringQueryString,
			sortingQueryString,
		]);

		const url = appendQueryParams(createUrl([metaBaseUrl ?? baseUrl, metaUrl]), queryString);

		const contentType = isPlainObject(body) || isArray(body) ? 'application/json' : null;
		const accept = isCsv ? 'text/csv' : null;

		const performFetch = async () => {
			let response;

			try {
				response = await fetch(url, {
					method,
					headers: rejectNil({
						'Content-Type': contentType,
						Accept: accept,
						...selectHeaders(getState()),
						...headers,
					}),
					body: isPlainObject(body) || isArray(body) ? JSON.stringify(body) : body,
					credentials,
				});
			} catch (error) {
				console.error(error);

				return errorEvent(error.toString(), makeBeforeResponseMeta());
			}

			const { statusText, status: statusCode } = response;
			const isSuccess = statusCode >= 200 && statusCode < 300;
			const isOutdated = selectIsRequestOutdated(action, getState());

			const makeAfterResponseMeta = additionalMeta =>
				makeBeforeResponseMeta({
					...additionalMeta,
					isOutdated,
					statusCode,
					statusText,
				});

			let parsedResponse;

			// TODO: This block should be refactored so that the CSV-specifics are not here.
			if (isCsv) {
				try {
					parsedResponse = await response.text();
				} catch (error) {
					console.error(error);

					return errorEvent(`${statusCode} ${statusText}`, makeAfterResponseMeta());
				}

				return isSuccess
					? successEvent(parsedResponse, makeAfterResponseMeta())
					: errorEvent({}, makeAfterResponseMeta());
			}

			try {
				parsedResponse = await response.json();
			} catch (error) {
				console.error(error);

				return errorEvent(`${statusCode} ${statusText}`, makeAfterResponseMeta());
			}

			const { data } = parsedResponse;

			// NOTE: Generally, we expect to receive responses from CBS APIs in a standardized wrapper
			// with a `data` property. In some rare cases, we can receive the data directly. Since JSON
			// allows `null` but not `undefined`, we can use `undefined` to guess the structure.
			const responseDataResult = data !== undefined ? data : parsedResponse;

			const makeAfterResponseParseMeta = additionalMeta =>
				makeAfterResponseMeta({
					...additionalMeta,
					...getParsedResponseMeta(parsedResponse),
					hasGenericErrorHandling: getHasGenericErrorHandling(parsedResponse),
				});

			return isSuccess
				? successEvent(
						getListData(responseDataResult) ?? responseDataResult,
						makeAfterResponseParseMeta(
							rejectEmpty({
								pagination: {
									...pagination,
									...getPaginationData(responseDataResult),
								},
							})
						)
					)
				: errorEvent(getParsedErrorPayload(parsedResponse), makeAfterResponseParseMeta());
		};

		dispatch(addPendingRequest(action));

		let fulfilledEvent;

		// NOTE: <= because we need to call `performFetch()` at least once.
		for (let retryCounter = 0; retryCounter <= retryTimes; retryCounter++) {
			if (retryCounter) {
				dispatch(retryingEvent({ attemptNumber: retryCounter }, makeBeforeResponseMeta()));

				await sleep(retryInterval);
			}

			fulfilledEvent = await performFetch();

			if (getIsAnySuccessResponseAction(fulfilledEvent)) {
				break;
			}
		}

		setTimeout(() => dispatch(removePendingRequest(fulfilledEvent)));

		dispatch(fulfilledEvent);
	});
