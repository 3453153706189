import { Buttons } from '@creditinfo-ui/layout';
import { MouseEventHandler, ReactNode } from 'react';

import { SlideModalFooter } from './SlideModalFooter';
import { SlideModalFooterCloseButton } from './SlideModalFooterCloseButton';

export interface BasicSlideModalFooterProps {
	buttons?: ReactNode[];
	onCloseButtonClick?: MouseEventHandler<HTMLButtonElement>;
}

export const BasicSlideModalFooter = ({
	buttons = [],
	onCloseButtonClick,
}: BasicSlideModalFooterProps) => (
	<SlideModalFooter>
		<Buttons
			isCentered
			buttons={[<SlideModalFooterCloseButton onClick={onCloseButtonClick} />, ...buttons]}
		/>
	</SlideModalFooter>
);

/** @deprecated Prefer `BasicSlideModalFooter` instead. */
export const DefaultSlideModalFooter = BasicSlideModalFooter;
