import { INPUT_WRAPPER_CLASS_NAME, InputWrapper } from '@creditinfo-ui/atoms';
import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { cx } from 'ramda-extension';
import { ReactNode } from 'react';

const rootStyle = prepareStyle(utils => ({
	alignItems: 'start',
	display: 'flex',
	gap: utils.spacings.sm,
}));

const fieldWrapperStyle = prepareStyle(() => ({
	flexGrow: 1,
}));

export interface FieldWithButtonsProps {
	buttons: ReactNode;
	field: ReactNode;
}

export const FieldWithButtons = ({ field, buttons }: FieldWithButtonsProps) => {
	const { applyStyle } = useStyles();

	return (
		<div className={cx(applyStyle(rootStyle), INPUT_WRAPPER_CLASS_NAME)}>
			<div className={applyStyle(fieldWrapperStyle)}>{field}</div>
			<InputWrapper label="&nbsp;">{buttons}</InputWrapper>
		</div>
	);
};
