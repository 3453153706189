import {
	getFormAsyncErrors,
	getFormSubmitErrors,
	getFormSyncErrors,
	reset,
	setSubmitFailed,
	setSubmitSucceeded,
	startSubmit,
	stopSubmit,
	submit,
} from 'redux-form';
import { getIsAnyRequestAction, getIsAnyResponseAction } from '@ci/api';
import { composeMiddleware, makeMiddleware, typeEq } from '@myci/utils';
import {
	getNamespaceByAction,
	makeActionTypes,
	makeConstantActionCreator,
	makeSimpleActionCreator,
} from 'redux-syringe';
import {
	all,
	compose,
	concat,
	endsWith,
	filter,
	find,
	fromPairs,
	identity,
	includes,
	isNil,
	keys,
	map,
	o,
	path,
	pluck,
	prop,
	propOr,
	reduce,
	reject,
	unless,
	useWith,
} from 'ramda';
import {
	alwaysEmptyArray,
	decapitalizeFirst,
	dispatch,
	ensureArray,
	isArray,
	isNotEmpty,
	joinWithDot,
	mergeDeepRightAll,
	rejectNil,
	splitByDot,
	unfoldObjectDots,
} from 'ramda-extension';
import invariant from 'invariant';
import { prepareFormErrors } from '@ci/redux-form';

import { SCOPE_PREFIX } from '../../constants';

export const SCOPE = `${SCOPE_PREFIX}-forms`;

export const ActionTypes = makeActionTypes(SCOPE, [
	'START_SUBMIT_FORM',
	'STOP_SUBMIT_FORM',
	'RESET_FORM',
	'SUBMIT_FORM',
]);

export const startSubmitForm = makeSimpleActionCreator(ActionTypes.START_SUBMIT_FORM);
export const stopSubmitForm = makeSimpleActionCreator(ActionTypes.STOP_SUBMIT_FORM);
export const resetForm = makeConstantActionCreator(ActionTypes.RESET_FORM);
export const submitForm = makeConstantActionCreator(ActionTypes.SUBMIT_FORM);

export const getAllFormErrors = namespace => state =>
	compose(
		prepareFormErrors,
		mergeDeepRightAll,
		rejectNil
	)([
		getFormSyncErrors(namespace)(state),
		getFormAsyncErrors(namespace)(state),
		getFormSubmitErrors(namespace)(state),
	]);

const apiErrorToFieldNameTransformMap = {};

// used when a different field name is returned from the API than the one in the form
export const setApiErrorToFieldNameTransform = (namespace, transform) => {
	if (!isNil(transform)) {
		apiErrorToFieldNameTransformMap[namespace] = transform;
	} else {
		delete apiErrorToFieldNameTransformMap[namespace];
	}
};

const getOriginForm = dispatch([
	path(['payload', 'meta', 'form']),
	path(['meta', 'form']),
	path(['payload', 'meta', 'namespace']),
	path(['meta', 'namespace']),
	path(['payload', 'meta', 'origin', 'payload', 'form']),
]);

export const getRegisteredFields = (form, state) =>
	o(keys, path(['form', form, 'registeredFields']))(state);

const findNestedKey = (key, registeredFields) => find(endsWith(`.${key}`), registeredFields) || key;

const mapToNestedFields = registeredFields =>
	map(([key, value]) =>
		includes(key, registeredFields) ? [key, value] : [findNestedKey(key, registeredFields), value]
	);

const decapitalizeFirstWithDot = compose(joinWithDot, map(decapitalizeFirst), splitByDot);

export const apiErrorsToFieldErrors = (form, errors, registeredFields = []) =>
	compose(
		unfoldObjectDots,
		fromPairs,
		mapToNestedFields(registeredFields),
		map(({ fieldName, message }) => [
			propOr(decapitalizeFirstWithDot, form, apiErrorToFieldNameTransformMap)(fieldName),
			message,
		]),
		filter(prop('fieldName'))
	)(errors);

const apiErrorsToGeneralErrors = o(pluck(['code']), reject(prop('fieldName')));

const prepareApiErrors = (form, errors, registeredFields) => {
	const fieldErrors = apiErrorsToFieldErrors(form, errors, registeredFields);
	const generalErrors = apiErrorsToGeneralErrors(errors);

	return {
		_error: generalErrors,
		...fieldErrors,
	};
};

const metaOrPayloadErrors = dispatch([
	path(['meta', 'errors']),
	path(['payload', 'errors']),
	alwaysEmptyArray,
]);

const getErrors = o(
	// eslint-disable-next-line react-hooks/rules-of-hooks
	reduce(useWith(concat, [identity, metaOrPayloadErrors]), []),
	unless(isArray, alwaysEmptyArray)
);

const validateResults = all(getIsAnyResponseAction);
const submitValidationMiddleware = composeMiddleware(
	makeMiddleware(typeEq(ActionTypes.START_SUBMIT_FORM), ({ dispatch }) => action => {
		dispatch(startSubmit(action.payload));
	}),
	makeMiddleware(typeEq(ActionTypes.STOP_SUBMIT_FORM), ({ dispatch, getState }) => action => {
		const results = ensureArray(action.payload);
		invariant(
			validateResults(results),
			'All payloads should be valid APISuccess or APIError action'
		);
		const form = getOriginForm(action);
		const errors = getErrors(results);
		const failed = isNotEmpty(errors);

		if (failed) {
			const registeredFields = getRegisteredFields(form, getState());
			const formErrors = prepareApiErrors(form, errors, registeredFields);

			dispatch(stopSubmit(form, formErrors));
			dispatch(setSubmitFailed(form));
		} else {
			dispatch(stopSubmit(form));
			dispatch(setSubmitSucceeded(form));
		}
	})
);

const formResetMiddleware = makeMiddleware(
	typeEq(ActionTypes.RESET_FORM),
	({ dispatch }) =>
		action => {
			const namespace = getNamespaceByAction(action);
			dispatch(reset(namespace));
		}
);

const formSubmitMiddleware = makeMiddleware(
	typeEq(ActionTypes.SUBMIT_FORM),
	({ dispatch }) =>
		action => {
			const namespace = getNamespaceByAction(action);
			dispatch(submit(namespace));
		}
);

const formMetaValidationMiddleware = composeMiddleware(
	makeMiddleware(
		getIsAnyRequestAction,
		({ dispatch }) =>
			({ meta: { form } }) =>
				form && dispatch(startSubmitForm(form))
	),
	makeMiddleware(
		getIsAnyResponseAction,
		({ dispatch }) =>
			action =>
				action.meta.form && dispatch(stopSubmitForm(action))
	)
);

export const formMiddleware = composeMiddleware(
	formResetMiddleware,
	submitValidationMiddleware,
	formSubmitMiddleware,
	formMetaValidationMiddleware
);
