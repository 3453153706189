import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { map } from 'ramda';
import { cx } from 'ramda-extension';
import { Text } from '@ci/atoms';

import TemplatePageContent from '../TemplatePageContent';
import TabList from '../TabList';
import Box from '../Box';

const TabPageContent = ({ children, hasAlternativeTheme, heading, tabs }) => {
	const tabPageContentHeading = useMemo(
		() => (
			<Text variant="viewTitle" color={hasAlternativeTheme ? 'primary' : 'unset'}>
				{heading}
			</Text>
		),
		[heading]
	);

	return (
		<TemplatePageContent heading={tabPageContentHeading}>
			<TabList
				className={cx({ 'tab-bar--with-alternative-theme': hasAlternativeTheme })}
				mt={3}
				labels={map(
					({ name, path }) => ({
						name,
						path,
					}),
					tabs
				)}
			/>
			<Box className="content-inner">{children}</Box>
		</TemplatePageContent>
	);
};

TabPageContent.propTypes = {
	children: PropTypes.node,
	hasAlternativeTheme: PropTypes.bool,
	heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
			path: PropTypes.string,
		})
	),
};

export default TabPageContent;
