import { prepareMessages } from '@creditinfo-ui/messages/node';

export const m = prepareMessages('formatters', [
	['closed', 'Closed'],
	['disabled', 'Disabled'],
	['enabled', 'Enabled'],
	['no', 'No'],
	['off', 'Off'],
	['on', 'On'],
	['open', 'Open'],
	['yes', 'Yes'],
]);
