import { T, always, cond, equals } from 'ramda';

import { InstanceTypes } from './constants';
import { getEnvironmentVariable } from './getEnvironmentVariable';

export const currentInstanceType = getEnvironmentVariable('GATSBY_MYCI_INSTANCE_TYPE');

const backOfficeVariables = {
	baseURL: getEnvironmentVariable('GATSBY_MYCI_BO_API_URL'),
	applicationKey: getEnvironmentVariable('GATSBY_MYCI_BO_API_APPLICATION_KEY'),
	appVersion: getEnvironmentVariable('GATSBY_MYCI_UI_VERSION'),
};

const frontOfficeVariables = {
	baseURL: getEnvironmentVariable('GATSBY_MYCI_FO_API_URL'),
	applicationKey: getEnvironmentVariable('GATSBY_MYCI_FO_API_APPLICATION_KEY'),
	recaptchaSiteKey: getEnvironmentVariable('GATSBY_MYCI_FO_RECAPTCHA_SITEKEY'),
	smsNotificationsTurnedOn: equals(
		getEnvironmentVariable('GATSBY_MYCI_FO_SMS_NOTIFICATIONS_TURNED_ON'),
		'1'
	),
	appVersion: getEnvironmentVariable('GATSBY_MYCI_UI_VERSION'),
	googleAnalyticsId: getEnvironmentVariable('GATSBY_MYCI_FO_GOOGLE_ANALYTICS_ID'),
	googleAnalyticsTurnedOn: equals(
		getEnvironmentVariable('GATSBY_MYCI_FO_GOOGLE_ANALYTICS_TURNED_ON'),
		'1'
	),
	instanceName: getEnvironmentVariable('GATSBY_MYCI_INSTANCE'),
};

export const getCurrentInstanceEnv = () =>
	cond([
		[equals(InstanceTypes.INSTANCE_BO), always(backOfficeVariables)],
		[T, always(frontOfficeVariables)],
	])(currentInstanceType);
