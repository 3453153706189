import React, { Fragment } from 'react';
import { compose, head } from 'ramda';
import { splitByDot } from 'ramda-extension';
import { useMessageFormatter } from '@ci/formatters';

// https://github.com/formatjs/react-intl/issues/1467
export const safeIntlComponent =
	render =>
	(...chunks) =>
		render(chunks.map((chunk, i) => <Fragment key={i}>{chunk}</Fragment>));

export const getMessageIdNamespace = compose(head, splitByDot, String);

export const getLanguageDisplayName = languageCode => {
	const strippedLanguageCode = languageCode.split('-')[0];
	const languageNames = new Intl.DisplayNames([strippedLanguageCode], {
		type: 'language',
		style: 'short',
	});
	return languageNames.of(strippedLanguageCode);
};

/* Note: showing language names should be preferably redone in favor of getLanguageDisplayName
 * but this needs to be agreed with product manager(s)
 */

export const useLocaleMessageFormatter = () =>
	useMessageFormatter(locale => ({
		id: `myci.languageName_${locale}`,
		defaultMessage: locale,
	}));
