import React from 'react';
import PropTypes from 'prop-types';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Message, useIntl } from '@myci/intl';
import { any, pathOr, prop, sort, subtract, useWith } from 'ramda';
import moment from 'moment';
import { Text } from '@ci/atoms';
import { isNotNilNorEmpty } from '@ci/utils';
import { useTranslationPrefetching } from '@ci/message';

import { useTheme } from '../ThemeProvider';
import Grid from '../Grid';
import Box from '../Box';
import DeprecatedText from '../Text';
import m from '../../messages';
import './ScoreGraph.scss';

const formatDate = date => moment(date).format("MMMM'YY");

const CustomTooltip = ({ active, payload, isRtl }) =>
	active && (
		<Grid flex className="score-graph__tooltip" dir={isRtl ? 'rtl' : 'ltr'}>
			<Box className="score-graph__tooltip-item">
				<DeprecatedText className="score-graph__tooltip-value" weight="extra-bold" mb={0}>
					{pathOr('-', ['payload', 'score'], payload[0])}
				</DeprecatedText>
				<DeprecatedText className="score-graph__tooltip-name" color="info" mb={0}>
					<Message {...m.scoreGraphCipScore} />
				</DeprecatedText>
			</Box>
			<Box className="score-graph__tooltip-item">
				<DeprecatedText className="score-graph__tooltip-value" weight="extra-bold" mb={0}>
					{pathOr('-', ['payload', 'grade'], payload[0])}
				</DeprecatedText>
				<DeprecatedText className="score-graph__tooltip-name" color="info" mb={0}>
					<Message {...m.scoreGraphCipGrade} />
				</DeprecatedText>
			</Box>
			<Box className="score-graph__tooltip-item">
				<DeprecatedText className="score-graph__tooltip-value" weight="extra-bold" mb={0}>
					{pathOr('-', ['payload', 'probabilityOfDefault'], payload[0])}%
				</DeprecatedText>
				<DeprecatedText className="score-graph__tooltip-name" color="info" mb={0}>
					<Message {...m.scoreGraphDefaultProbability} />
				</DeprecatedText>
			</Box>
			<Box as="span" className="score-graph__tooltip-arrow" />
		</Grid>
	);
CustomTooltip.propTypes = {
	active: PropTypes.bool,
	isRtl: PropTypes.bool,
	payload: PropTypes.array,
};

const getDate = prop('date');

// eslint-disable-next-line react-hooks/rules-of-hooks
const sortByDate = useWith(subtract, [getDate, getDate]);

const doesAnyHistoricalScoreExist = any(item => isNotNilNorEmpty(item.score));

const ScoreGraph = ({ data, height, showLegend }) => {
	const intl = useIntl();
	const { isRTL } = useTheme();

	const hasData = isNotNilNorEmpty(data) && doesAnyHistoricalScoreExist(data);
	const sortedData = sort(sortByDate, data ?? []);

	useTranslationPrefetching('uiComponents');

	return !hasData ? (
		<Text
			tag="div"
			icon="noData"
			iconProps={{ color: 'gray600', size: 'md' }}
			message={m.dataGridNoData}
			align="center"
		/>
	) : (
		<ResponsiveContainer className="score-graph" height={height} width="100%">
			<LineChart data={sortedData}>
				<XAxis
					dataKey="date"
					tickFormatter={formatDate}
					height={45}
					reversed={isRTL}
					padding={{ left: 40, right: 40 }}
					tickLine={false}
					tickMargin={15}
					interval={0}
					angle={-20}
				/>
				<YAxis
					dataKey="score"
					orientation={isRTL ? 'right' : 'left'}
					axisLine={false}
					padding={{ top: 10, right: 40 }}
					tickLine={false}
					tickMargin={20}
					scale="linear"
				/>
				{showLegend && (
					<Legend
						wrapperStyle={{ direction: isRTL ? 'rtl' : 'ltr' }}
						verticalAlign="top"
						height={36}
					/>
				)}
				<Tooltip
					content={<CustomTooltip isRtl={isRTL} />}
					filterNull={false}
					isAnimationActive={false}
					offset={0}
				/>
				<Line dataKey="score" type="monotone" name={intl.formatMessage(m.scoreGraphCipScore)} />
			</LineChart>
		</ResponsiveContainer>
	);
};

ScoreGraph.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			score: PropTypes.number,
			grade: PropTypes.string,
			probabilityOfDefault: PropTypes.number,
			date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		})
	),
	height: PropTypes.number,
	showLegend: PropTypes.bool,
};

export default ScoreGraph;
