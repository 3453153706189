import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { IconType } from '@creditinfo-ui/atoms';
import { TabButton } from './TabButton';
import { StepperNumber } from './StepperNumber';
import { StepperIcon } from './StepperIcon';
import { TabButtonLabel } from './TabButtonLabel';
import { TabsLayout } from '../types';

export interface StepperTabButtonProps {
	activeIndex: number;
	icon?: IconType;
	index: number;
	isActive: boolean;
	isDisabled: boolean;
	isInvalid: boolean;
	label: ReactNode;
	layout: TabsLayout;
	onClick: () => void;
}

export const StepperTabButton = forwardRef(
	(
		{
			activeIndex,
			icon,
			index,
			isActive,
			isDisabled,
			isInvalid,
			label,
			layout,
			onClick,
		}: StepperTabButtonProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => (
		<TabButton
			ref={ref}
			isActive={isActive}
			isInvalid={isInvalid}
			isDisabled={isDisabled || index > activeIndex}
			onClick={onClick}
		>
			{icon ? (
				<StepperIcon activeIndex={activeIndex} isActive={isActive} index={index} type={icon} />
			) : (
				<StepperNumber activeIndex={activeIndex} index={index} isActive={isActive} />
			)}
			<TabButtonLabel size={isActive && layout === 'vertical' ? 'activeTabLabel' : 'tabLabel'}>
				{label}
			</TabButtonLabel>
		</TabButton>
	)
);

StepperTabButton.displayName = 'StepperTabButton';
