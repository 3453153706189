import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocaleFromStorage, loadLocale } from './duck';
import { getLocale } from './selectors';

export const useEnsureLocale = () => {
	const locale = useSelector(getLocale);
	const localeFromStorage = getLocaleFromStorage();
	const dispatch = useDispatch();

	useEffect(() => {
		if (localeFromStorage && locale !== localeFromStorage) {
			dispatch(loadLocale(localeFromStorage));
		} else {
			dispatch(loadLocale(locale));
		}
	}, [dispatch, locale, localeFromStorage]);
};
