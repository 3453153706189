import debounce from 'lodash.debounce';

// source: https://github.com/lodash/lodash/issues/4815 (some code style changes applied)
export const asyncDebounce = <TFunction extends (...args: any[]) => Promise<any>>(
	func: TFunction,
	wait?: number
) => {
	const resolveSet = new Set<(p: any) => void>();
	const rejectSet = new Set<(p: any) => void>();

	const debounced = debounce((args: Parameters<TFunction>) => {
		func(...args)
			.then((...res) => {
				resolveSet.forEach(resolve => resolve(...res));
				resolveSet.clear();
			})
			.catch((...res) => {
				rejectSet.forEach(reject => reject(...res));
				rejectSet.clear();
			});
	}, wait);

	return (...args: Parameters<TFunction>): ReturnType<TFunction> =>
		new Promise((resolve, reject) => {
			resolveSet.add(resolve);
			rejectSet.add(reject);
			debounced(args);
		}) as ReturnType<TFunction>;
};
