import { head, last } from 'ramda';
import { FontWeight, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { FontFaceLoader } from '@creditinfo-ui/fonts';
import { VerticalSpacing } from '@creditinfo-ui/atoms';

import { TabDropdown } from './TabDropdown';
import { Tab } from './Tab';
import { TabContent } from './TabContent';
import { useTabDropdown } from '../hooks';
import { HorizontalInset, SetActiveTabKey, TabItem, TabKey, TabsVariant } from '../types';
import { resolveHorizontalInset } from '../utils';

export interface TabBarStyleProps {
	areWidthStatesInitialized: boolean;
	horizontalInset: HorizontalInset;
	isTabBarCentered: boolean;
}

const tabBarStyle = prepareStyle<TabBarStyleProps>(
	(utils, { areWidthStatesInitialized, horizontalInset, isTabBarCentered }) => ({
		...resolveHorizontalInset(horizontalInset, utils),
		display: 'flex',
		selectors: {
			'> button:not(:first-child)': {
				marginInlineStart: utils.spacings.lg,
			},
		},
		extend: [
			{
				condition: isTabBarCentered,
				style: {
					justifyContent: 'center',
				},
			},
			{
				condition: !areWidthStatesInitialized,
				style: {
					visibility: 'hidden',
				},
			},
		],
	})
);

export interface HorizontalTabsProps {
	activeIndex: number;
	activeKey: TabKey;
	contentVerticalPadding: VerticalSpacing;
	hasInsetShadow: boolean;
	horizontalInset: HorizontalInset;
	isTabBarCentered: boolean;
	next: () => void;
	onTabClick: (key: TabKey) => void;
	previous: () => void;
	setActiveKey: SetActiveTabKey;
	tabs: TabItem[];
	variant: TabsVariant;
}

export const PureHorizontalTabs = ({
	activeIndex,
	activeKey,
	contentVerticalPadding,
	hasInsetShadow,
	horizontalInset,
	isTabBarCentered,
	next,
	onTabClick,
	previous,
	setActiveKey,
	tabs,
	variant,
}: HorizontalTabsProps) => {
	const { applyStyle } = useStyles();
	const {
		areWidthStatesInitialized,
		dropdownItems,
		dropdownRef,
		isDropdownActive,
		makeTabRefCallback,
		shouldRenderDropdown,
		shownTabsCount,
		tabBarRef,
	} = useTabDropdown(tabs, activeKey);

	return (
		<>
			<div
				role="tablist"
				className={applyStyle(tabBarStyle, {
					areWidthStatesInitialized,
					horizontalInset,
					isTabBarCentered,
				})}
				ref={tabBarRef}
			>
				{tabs.map(
					(tab, index) =>
						index < shownTabsCount && (
							<Tab
								ref={makeTabRefCallback(tab.key)}
								tab={tab}
								key={tab.key}
								index={index}
								variant={variant}
								onTabClick={onTabClick}
								activeKey={activeKey}
								activeIndex={activeIndex}
								layout="horizontal"
							/>
						)
				)}
				{shouldRenderDropdown && (
					<TabDropdown
						ref={dropdownRef}
						activeIndex={activeIndex}
						activeKey={activeKey}
						isActive={isDropdownActive}
						items={dropdownItems}
						onTabClick={onTabClick}
						shownTabsCount={shownTabsCount}
						variant={variant}
					/>
				)}
			</div>
			<div>
				{tabs.map(tab => {
					const { content, key, isAlwaysMounted, verticalPadding } = tab;
					const isActive = activeKey === key;

					return (
						(isActive || isAlwaysMounted) && (
							<TabContent
								hasInsetShadow={hasInsetShadow}
								horizontalInset={horizontalInset}
								isActive={isActive}
								isFirst={tab === head(tabs)}
								isLast={tab === last(tabs)}
								key={key}
								next={next}
								previous={previous}
								setActiveKey={setActiveKey}
								tabKey={key}
								verticalPadding={verticalPadding ?? contentVerticalPadding}
							>
								{content}
							</TabContent>
						)
					);
				})}
			</div>
		</>
	);
};

// NOTE: Necessary for calculating the widths properly.
// `normal` is inactive tab, `extraBold` is active tab.
const tabsFontWeights: FontWeight[] = ['normal', 'extraBold'];

export const HorizontalTabs = (props: HorizontalTabsProps) => (
	<FontFaceLoader fontWeights={tabsFontWeights}>
		<PureHorizontalTabs {...props} />
	</FontFaceLoader>
);
