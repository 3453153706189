import { MaskOptions } from '@creditinfo-ui/atoms';

// TODO: This obviously shouldn't be here, but `@cbs/module-credit-applications` is currently
// not configurable (everything is hardcoded for Uganda) and `@cbs/service-report-renderer`
// doesn't support any form of external configuration.
export const ugandaPhoneMaskOptions: MaskOptions = {
	// NOTE: https://imask.js.org/guide.html#masked-pattern
	mask: '+0[00] `[0000000000]',
	overwrite: 'shift',
};
