import { ValueOf } from './types';

const featureDetectorHtml =
	'<div dir="rtl" style="width: 1px; height: 1px; position: fixed; top: 0px; left: 0px; overflow: hidden"><div style="width: 2px"><div style="display: inline-block; width: 1px"></div><div style="display: inline-block; width: 1px"></div></div></div>';

export const rtlScrollModes = {
	reverse: 'reverse',
	negative: 'negative',
	default: 'default',
} as const;

// NOTE: https://github.com/othree/jquery.rtl-scroll-type contains everything you need to know.
// If you have any questions, please don't.
const resolveRtlScrollMode = () => {
	if (typeof document === 'undefined') {
		return null;
	}

	const parent = document.createElement('div');
	parent.innerHTML = featureDetectorHtml;
	const featureDetector: any = parent.firstChild;
	document.body.appendChild(featureDetector);

	let mode: ValueOf<typeof rtlScrollModes> = rtlScrollModes.reverse;

	if (featureDetector.scrollLeft > 0) {
		mode = rtlScrollModes.default;
	} else if ('scrollIntoView' in Element.prototype) {
		featureDetector.children[0].children[1].scrollIntoView();

		if (featureDetector.scrollLeft < 0) {
			mode = rtlScrollModes.negative;
		}
	} else {
		featureDetector.scrollLeft = 1;

		if (featureDetector.scrollLeft === 0) {
			mode = rtlScrollModes.negative;
		}
	}

	document.body.removeChild(featureDetector);

	return mode;
};

export const rtlScrollMode = resolveRtlScrollMode();
