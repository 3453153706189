"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
exports.__esModule = true;
exports.wrapPageElement = exports.onClientEntry = void 0;
var _intl = require("@myci/intl");
var _formatters = require("@ci/formatters");
var _wrapWithProvider = _interopRequireDefault(require("./wrap-with-provider"));
exports.wrapPageElement = _wrapWithProvider.default;
var onClientEntry = exports.onClientEntry = function onClientEntry() {
  return (0, _intl.polyfillIntl)().then(function () {
    (0, _formatters.configureFormatterTimeZoneMode)('convertToLocalTime');
  });
};