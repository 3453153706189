import { reject, filter, o, prop, either, identity, anyPass, equals, propEq, both } from 'ramda';
import { flipIncludes, isPlainObject } from 'ramda-extension';
import { AnyLookupValue, LookupEntryKey, LookupEntryValue } from './types';

export const NOT_SPECIFIED_KEY = 0;
export const NOT_SPECIFIED_VALUE = 'NotSpecified';

// NOTE: See the tests for more details about this logic.
const getIsNotSpecifiedLookupEntry: (value: AnyLookupValue) => boolean = both<any>(
	isPlainObject,
	propEq('value', NOT_SPECIFIED_VALUE)
);

export const getIsNotSpecified = anyPass([
	getIsNotSpecifiedLookupEntry,
	equals<AnyLookupValue>(NOT_SPECIFIED_KEY),
	equals<AnyLookupValue>(NOT_SPECIFIED_VALUE),
]);

export const removeNotSpecified = reject(getIsNotSpecified);

export const filterLookupValues = (validLookupValues: LookupEntryValue[]) =>
	filter(o(flipIncludes(validLookupValues), either(prop('value'), identity)));

export const filterLookupKeys = (validLookupKeys: LookupEntryKey[]) =>
	filter(o(flipIncludes(validLookupKeys), either(prop('key'), identity)));

export const rejectLookupValues = (invalidLookupValues: LookupEntryValue[]) =>
	reject(o(flipIncludes(invalidLookupValues), either(prop('value'), identity)));

export const rejectLookupKeys = (invalidLookupKeys: LookupEntryKey[]) =>
	reject(o(flipIncludes(invalidLookupKeys), either(prop('key'), identity)));
