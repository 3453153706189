import { Message } from '@creditinfo-ui/messages';
import {
	Style,
	StyleObject,
	StyleUtils,
	mergeStyles,
	prepareStyle,
	prepareStyleFactory,
	useStyles,
} from '@creditinfo-ui/styles';
import { getIsRenderable } from '@creditinfo-ui/utils';
import { cx } from 'ramda-extension';
import { ButtonHTMLAttributes, ComponentType, ReactNode, RefAttributes, forwardRef } from 'react';
import { MessageDescriptor } from 'react-intl';

import { IconPlacement } from '../types';
import { Icon, IconProps, IconType } from './Icon';
import { Spinner } from './Spinner';

interface SubtleButtonVariantOptions {
	isInverted?: boolean;
}

interface DefaultButtonVariantOptions {
	isOutlined?: boolean;
}

type SubtleButtonVariantTuple = ['subtle', SubtleButtonVariantOptions];
type PrimaryButtonVariantTuple = ['primary', DefaultButtonVariantOptions];
type SecondaryButtonVariantTuple = ['secondary', DefaultButtonVariantOptions];
type DangerButtonVariantTuple = ['danger', DefaultButtonVariantOptions];
type InputLikeButtonVariantTuple = ['inputLike', {}];

type DefaultButtonVariantTuple =
	| PrimaryButtonVariantTuple
	| SecondaryButtonVariantTuple
	| DangerButtonVariantTuple;

export type ButtonVariantTuple =
	| SubtleButtonVariantTuple
	| DefaultButtonVariantTuple
	| InputLikeButtonVariantTuple;

export type ButtonVariant = ButtonVariantTuple[0];

export type ButtonSize = 'small' | 'medium';

const getVariantColor = (utils: StyleUtils, variant: ButtonVariant) =>
	utils.colors[variant] ?? utils.colors.primary;

const getHoverVariantColor = (utils: StyleUtils, variant: ButtonVariant) => {
	if (variant === 'secondary') {
		return utils.lighten(0.1, utils.colors.secondary);
	}

	return utils.darken(0.1, utils.colors[variant] ?? utils.colors.primary);
};

const getActiveVariantColor = (utils: StyleUtils, variant: ButtonVariant) =>
	utils.darken(0.25, utils.colors[variant] ?? utils.colors.primary);

interface BaseButtonStyleProps {
	hasRoundBorderRadius: boolean;
	iconPlacement: IconPlacement;
	isIconOnly: boolean;
	size: ButtonSize;
}

const baseButtonStyle = prepareStyle<BaseButtonStyleProps>(
	(utils, { hasRoundBorderRadius, iconPlacement, isIconOnly, size }) => {
		const styleObject: StyleObject = {
			alignItems: 'center',
			borderRadius: hasRoundBorderRadius ? utils.borders.radii.round : utils.borders.radii.basic,
			borderStyle: 'solid',
			borderWidth: utils.borders.widths.md,
			boxShadow: 'none',
			cursor: 'pointer',
			display: 'inline-flex',
			flexDirection: iconPlacement === 'start' ? 'row' : 'row-reverse',
			fontSize: utils.fontSizes.base,
			fontWeight: utils.fontWeights.semiBold,
			height: utils.sizes.control[size],
			justifyContent: 'center',
			lineHeight: 1,
			minWidth: utils.sizes.control[size],
			padding: 0,
			textDecoration: 'none',
			textTransform: 'uppercase',
			transitionDuration: utils.transitions.speeds.default,
			transitionProperty: 'color, background-color, border-color',
			transitionTimingFunction: utils.transitions.easing,
			userSelect: 'none',
			verticalAlign: 'middle',
			whiteSpace: 'nowrap',

			selectors: {
				':focus-visible': {
					outlineColor: utils.colors.focusOutline,
					outlineStyle: 'solid',
					outlineWidth: utils.borders.widths.lg,
				},
				':hover': {
					textDecoration: 'none',
				},
			},

			extend: [
				{
					condition: !isIconOnly,
					style: {
						extend: [
							{
								condition: size === 'medium',
								style: {
									minWidth: '90px',
									paddingInlineEnd: utils.spacings.md,
									paddingInlineStart: utils.spacings.md,
								},
							},
							{
								condition: size === 'small',
								style: {
									paddingInlineEnd: utils.spacings.sm,
									paddingInlineStart: utils.spacings.sm,
								},
							},
						],
					},
				},
			],
		};

		return styleObject;
	}
);

interface DefaultButtonStyleProps {
	defaultVariantTuple?: DefaultButtonVariantTuple;
	isDisabled: boolean;
}

const defaultButtonStyle = prepareStyle<DefaultButtonStyleProps>(
	(utils, { defaultVariantTuple, isDisabled }) => {
		if (!defaultVariantTuple) {
			return {};
		}

		if (isDisabled) {
			return {
				backgroundColor: utils.colors.gray400,
				borderColor: utils.colors.gray400,
				color: 'white',
				cursor: 'not-allowed',
				opacity: 0.65,
			};
		}

		const [variant, variantOptions] = defaultVariantTuple;
		const variantColor = getVariantColor(utils, variant);
		const hoverVariantColor = getHoverVariantColor(utils, variant);
		const activeVariantColor = getActiveVariantColor(utils, variant);
		const isOutlined = variantOptions.isOutlined || false;

		if (isOutlined) {
			return {
				backgroundColor: 'transparent',
				borderColor: variantColor,
				color: variantColor,
				selectors: {
					':hover:not(:active), :focus-visible:not(:active)': {
						backgroundColor: 'white',
						borderColor: hoverVariantColor,
						color: hoverVariantColor,
					},
					':active': {
						backgroundColor: utils.colors.gray100,
						borderColor: activeVariantColor,
						color: activeVariantColor,
					},
				},
			};
		}

		return {
			backgroundColor: variantColor,
			borderColor: variantColor,
			color: 'white',
			selectors: {
				':hover:not(:active), :focus-visible:not(:active)': {
					backgroundColor: hoverVariantColor,
					borderColor: hoverVariantColor,
					color: 'white',
				},
				':active': {
					backgroundColor: utils.darken(0.05, hoverVariantColor),
					borderColor: activeVariantColor,
					color: 'white',
				},
			},
		};
	}
);

interface SubtleButtonStyleProps {
	isDisabled: boolean;
	subtleVariantTuple?: SubtleButtonVariantTuple;
}

const subtleButtonStyle = prepareStyle<SubtleButtonStyleProps>(
	(utils, { isDisabled, subtleVariantTuple }) => {
		if (!subtleVariantTuple) {
			return {};
		}

		const [variant, { isInverted = false }] = subtleVariantTuple;

		if (isDisabled) {
			return {
				backgroundColor: 'transparent',
				borderColor: 'transparent',
				color: isInverted ? utils.colors.white : utils.colors.gray600,
				cursor: 'not-allowed',
				opacity: 0.35,
			};
		}

		const hoverVariantColor = getHoverVariantColor(utils, variant);
		const activeVariantColor = getActiveVariantColor(utils, variant);

		return {
			backgroundColor: 'transparent',
			borderColor: 'transparent',
			extend: [
				{
					condition: isInverted,
					style: {
						color: utils.colors.white,
						selectors: {
							':hover:not(:active), :focus-visible:not(:active)': {
								backgroundColor: utils.colors.white,
								borderColor: hoverVariantColor,
								color: hoverVariantColor,
							},
							':active': {
								backgroundColor: utils.colors.gray100,
								borderColor: activeVariantColor,
								color: activeVariantColor,
							},
						},
					},
				},
				{
					condition: !isInverted,
					style: {
						color: utils.colors.gray600,
						selectors: {
							':hover:not(:active), :focus-visible:not(:active)': {
								color: hoverVariantColor,
							},
							':active': {
								color: activeVariantColor,
							},
						},
					},
				},
			],
		};
	}
);

interface InputLikeButtonStyleProps {
	inputLikeVariantTuple?: InputLikeButtonVariantTuple;
	isDisabled: boolean;
}

const inputLikeButtonStyle = prepareStyle<InputLikeButtonStyleProps>(
	(utils, { inputLikeVariantTuple, isDisabled }) => {
		if (!inputLikeVariantTuple) {
			return {};
		}

		const secondaryOutlinedStyleObject = defaultButtonStyle(utils, {
			defaultVariantTuple: ['secondary', { isOutlined: true }],
			isDisabled,
		});

		if (isDisabled) return secondaryOutlinedStyleObject;

		return {
			...secondaryOutlinedStyleObject,
			borderColor: utils.colors.gray300,
		};
	}
);

export interface ButtonStyleProps
	extends BaseButtonStyleProps,
		DefaultButtonStyleProps,
		SubtleButtonStyleProps {
	// NOTE: `isBusy` might be useful in `customStyle`.
	isBusy: boolean;
}

const spinnerStyle = prepareStyleFactory<{ iconPlacement: IconPlacement }>(
	(utils, { iconPlacement }) =>
		iconPlacement === 'start'
			? { marginInlineStart: utils.spacings.sm }
			: { marginInlineEnd: utils.spacings.sm }
);

const iconStyle = prepareStyleFactory<{ hasChildren: boolean; iconPlacement: IconPlacement }>(
	(utils, { hasChildren, iconPlacement }) => {
		const inlineMarginValue = hasChildren ? utils.spacings.xs : 0;

		const marginStyleObject =
			iconPlacement === 'start'
				? { marginInlineEnd: inlineMarginValue }
				: { marginInlineStart: inlineMarginValue };

		return {
			color: 'inherit',
			...marginStyleObject,
		};
	}
);

// NOTE: Using an odd `px` value results in better vertical alignment in small buttons.
const BADGE_SIZE = '1.9rem';

interface BadgeStyleProps {
	iconPlacement: IconPlacement;
	variant: ButtonVariant;
}

const badgeStyle = prepareStyle<BadgeStyleProps>((utils, { iconPlacement, variant }) => {
	const marginStyleObject =
		iconPlacement === 'start'
			? { marginInlineEnd: utils.spacings.xs }
			: { marginInlineStart: utils.spacings.xs };

	return {
		alignItems: 'center',
		backgroundColor: 'white',
		borderRadius: utils.borders.radii.round,
		color: variant === 'subtle' ? utils.colors.gray600 : utils.colors.primary,
		display: 'flex',
		height: BADGE_SIZE,
		justifyContent: 'center',
		minWidth: BADGE_SIZE,
		paddingLeft: utils.spacings.xxs,
		paddingRight: utils.spacings.xxs,
		...marginStyleObject,
	};
});

interface CompatibilityButtonProps {
	/** @deprecated Use `isDisabled` instead. */
	disabled?: boolean;
}

export interface ButtonProps
	extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'>,
		CompatibilityButtonProps {
	as?:
		| ComponentType<ButtonHTMLAttributes<HTMLButtonElement> & RefAttributes<HTMLButtonElement>>
		| 'button';
	badge?: ReactNode;
	customStyle?: Style<ButtonStyleProps>;
	icon?: IconType;
	iconPlacement?: IconPlacement;
	iconProps?: Partial<IconProps>;
	isBusy?: boolean;
	isDisabled?: boolean;
	/** @deprecated Use `variant` as a tuple instead, e.g. `["primary", { isOutlined: true }]`. */
	isOutlined?: boolean;
	message?: MessageDescriptor;
	size?: ButtonSize;
	testId?: string;
	type?: 'button' | 'submit' | 'reset';
	variant?: ButtonVariant | ButtonVariantTuple;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			as: Component = 'button',
			badge,
			children,
			className,
			customStyle,
			disabled: disabledProp,
			icon,
			iconPlacement = 'start',
			iconProps,
			isBusy = false,
			isDisabled: isDisabledProp,
			isOutlined,
			message,
			onClick,
			size = 'medium',
			testId,
			type,
			variant: variantProp = 'primary',
			...otherProps
		},
		ref
	) => {
		const { applyStyle } = useStyles();

		const hasChildren = getIsRenderable(children) || Boolean(message);
		const isIconOnly = Boolean(icon) && !hasChildren;

		// NOTE: `isInactive` can be confused for the `:not(:active)` pseudo-class.
		const isDisabled = (isDisabledProp ?? disabledProp) || isBusy;

		const variantTuple =
			typeof variantProp === 'string'
				? ([variantProp, { isOutlined }] as ButtonVariantTuple)
				: variantProp;

		const [variant, variantOptions] = variantTuple;

		const mergedIconStyle = mergeStyles([
			iconStyle({ hasChildren, iconPlacement }),
			iconProps?.customStyle,
		]);

		const isOneOfDefaultVariants = variant !== 'subtle' && variant !== 'inputLike';

		return (
			<Component
				className={cx(
					applyStyle(
						[
							baseButtonStyle,
							defaultButtonStyle,
							subtleButtonStyle,
							inputLikeButtonStyle,
							customStyle,
						],
						{
							defaultVariantTuple: isOneOfDefaultVariants ? variantTuple : undefined,
							subtleVariantTuple: variant === 'subtle' ? variantTuple : undefined,
							inputLikeVariantTuple: variant === 'inputLike' ? variantTuple : undefined,
							hasRoundBorderRadius:
								isIconOnly && variant === 'subtle' && !variantOptions.isInverted,
							iconPlacement,
							isBusy,
							isDisabled,
							isIconOnly,
							size,
						}
					),
					className
				)}
				data-testid={testId}
				disabled={isDisabled}
				onClick={isDisabled ? undefined : onClick}
				ref={ref}
				type={type ?? (Component === 'button' ? 'button' : undefined)}
				{...otherProps}
			>
				{icon && (
					<Icon
						isLabeled={hasChildren}
						size="sm"
						type={icon}
						{...iconProps}
						customStyle={mergedIconStyle}
					/>
				)}
				{badge && (
					<span className={applyStyle(badgeStyle, { iconPlacement, variant })}>{badge}</span>
				)}
				{children ?? (message && <Message {...message} />)}
				{isBusy && (
					<Spinner
						variant={variant === 'subtle' && !variantOptions.isInverted ? 'dark' : 'light'}
						size="small"
						customStyle={spinnerStyle({ iconPlacement })}
					/>
				)}
			</Component>
		);
	}
);

Button.displayName = 'Button';
