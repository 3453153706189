declare global {
	interface Navigator {
		msSaveOrOpenBlob?: (blob: Blob, filename: string) => void;
	}
}

export const downloadFile = (blob: Blob, filename: string) => {
	if (window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		const data = window.URL.createObjectURL(blob);
		const downloadLink = document.createElement('a');
		downloadLink.setAttribute('href', data);
		downloadLink.setAttribute('download', filename);
		downloadLink.style.display = 'none';
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}
};
