import { useUnsavedChangesProducer } from '@ci/unsaved-changes';
import { isDirty as selectIsFormDirty } from 'redux-form';
import { useSelector } from 'react-redux';

export interface UnsavedChangesProducerProps {
	form: string;
}

export const UnsavedChangesProducer = ({ form }: UnsavedChangesProducerProps) => {
	const isFormDirty = useSelector(selectIsFormDirty(form));

	useUnsavedChangesProducer(form, isFormDirty);

	return null;
};
