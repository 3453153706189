import { F, reverse } from 'ramda';
import moment, { Moment } from 'moment';
import { isNilOrEmptyString, noop } from 'ramda-extension';
import {
	ChangeEvent,
	Component,
	ReactNode,
	forwardRef,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { flushSync } from 'react-dom';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import {
	ANCHOR_LEFT,
	ANCHOR_RIGHT,
	HORIZONTAL_ORIENTATION,
	OPEN_DOWN,
	OPEN_UP,
} from 'react-dates/constants';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { InputWrapper, useOpenDirection } from '@creditinfo-ui/atoms';
import { useGeneratedId } from '@creditinfo-ui/utils';
import { useDirection, useStyles } from '@creditinfo-ui/styles';

import { DateConstraint } from '../../types';
import { DateConfigContext } from '../../contexts';
import { CalendarHeader, YearSelectDirection } from './CalendarHeader';
import { dateInputStyle } from './styles';
import { DateModifier, formatDate } from '../../utils';

export const INVALID_SUFFIX = '-INVALID';

const getCalendarHeight = () => 310;

export const getDisplayFormat = () => moment.localeData().longDateFormat('L');

const isInCypress = typeof window !== 'undefined' && 'Cypress' in window;

// NOTE: These are here so the date picker still works with the old CBSUI form architecture.
interface CompatibilityDateInputProps {
	/** @deprecated Use `isDisabled` instead. */
	disabled?: boolean;
	/** @deprecated Use `isReadOnly` instead. */
	readOnly?: boolean;
	/** @deprecated Use `isRequired` instead. */
	required?: boolean;
	/** @deprecated Use `constraint` instead. */
	yearSelectDirection?: YearSelectDirection;
}

export interface DateInputProps extends CompatibilityDateInputProps {
	className?: string;
	constraint?: DateConstraint;
	datePickerProps?: Omit<
		SingleDatePickerShape,
		'renderMonthText' | 'id' | 'date' | 'focused' | 'onDateChange' | 'onFocusChange'
	>;
	error?: ReactNode;
	id?: string;
	isDisabled?: boolean;
	isInvalid?: boolean;
	/** Returns `null` instead of an empty string when there is no date set. */
	isNullable?: boolean;
	isReadOnly?: boolean;
	isRequired?: boolean;
	label?: ReactNode;
	modifier?: DateModifier;
	name?: string;
	onBlur?: () => void;
	onChange?: (value: string | null) => void;
	onFocus?: () => void;
	value: string | null;
}

const addInvalidSuffix = (value: string): string => `${value}${INVALID_SUFFIX}`;
const removeInvalidSuffix = (value: string): string => value.replace(INVALID_SUFFIX, '');

export const DateInput = forwardRef<Component<SingleDatePickerShape, any, any>, DateInputProps>(
	(props: DateInputProps, ref) => {
		const {
			className,
			constraint,
			datePickerProps,
			error: errorProp,
			id: idProp,
			isInvalid: isInvalidProp,
			isNullable = false,
			label,
			modifier,
			name,
			onBlur = noop,
			onChange = noop,
			onFocus = noop,
			value: valueProp,
			yearSelectDirection = 'both',
		} = props;
		const value = valueProp ? removeInvalidSuffix(valueProp) : valueProp;
		const generatedId = useGeneratedId();
		const id = idProp ?? (name ? `${name}-${generatedId}` : generatedId);

		const isRequired = props.isRequired ?? props.required ?? false;
		const isReadOnly = props.isReadOnly ?? props.readOnly ?? false;
		const isDisabled = props.isDisabled ?? props.disabled ?? false;

		const [isFocused, setIsFocused] = useState(false);
		const [valueOverride, setValueOverride] = useState<string | null>(null);
		const { isRtl } = useDirection();
		const displayFormat = getDisplayFormat();
		const dateConfig = useContext(DateConfigContext);

		// NOTE: We need to support three formats because there are three data sources for date selection:
		// clicking a date in the calendar, receiving it from BE, and typing it out manually.
		const supportedFormats = useMemo(
			() => [
				moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
				`${moment.HTML5_FMT.DATETIME_LOCAL_SECONDS}Z`,
				displayFormat,
			],
			[displayFormat]
		);

		const constraintError = useMemo(() => {
			const date = moment(value, supportedFormats, true);

			if (
				errorProp &&
				date.isValid() &&
				constraint &&
				!constraint.getIsDateValid(date, dateConfig)
			) {
				return constraint.getErrorNode(date, dateConfig);
			}

			return null;
		}, [constraint, dateConfig, errorProp, supportedFormats, value]);

		const error = constraintError ?? errorProp;
		const isInvalid = isInvalidProp ?? Boolean(error);

		const handleChange = useCallback(
			(nextValue: string, isValid: boolean) => {
				if (isNullable && isNilOrEmptyString(nextValue)) {
					return onChange(null);
				}

				if (isValid && constraint && !constraint.getIsDateValid(moment(nextValue), dateConfig)) {
					return onChange(addInvalidSuffix(nextValue));
				}

				return onChange(nextValue);
			},
			[isNullable, constraint, dateConfig, onChange]
		);

		const previousValueRef = useRef(value);
		const isResettingValueRef = useRef(false);

		// HACK: Fixes input value not being cleared when the form is reset.
		// Under the hood, we pass `null` to SingleDatePicker for both invalid and empty values.
		// As a result, SingleDatePicker doesn't know that the input value should be cleared.
		// Because we cannot clear the input value manually, we set the value to a valid moment
		// object and then back to null again, clearing the input value.
		useEffect(() => {
			const previousValue = previousValueRef.current;
			previousValueRef.current = value;

			if (!value && value !== previousValue) {
				isResettingValueRef.current = true;

				// NOTE: React is not happy with using `flushSync` directly inside a `useEffect`.
				queueMicrotask(() => {
					// HACK: We're using an old (but still valid) date to avoid visual glitches.
					// Since `flushSync` causes the DOM to update immediately, we could see `valueOverride`
					// become briefly selected in the calendar (if it's on the current page).
					flushSync(() => setValueOverride('1900-01-01T00:00:00'));
					setValueOverride(null);
					isResettingValueRef.current = false;
				});
			}
		}, [value]);

		const handleFocusChange = useCallback(
			({ focused: nextIsFocused }) => {
				setIsFocused(nextIsFocused);

				if (nextIsFocused) {
					onFocus();
				} else {
					onBlur();
				}
			},
			[onFocus, onBlur]
		);

		// NOTE: Because the BE usually expects us to send the local ISO 8601 datetime (i.e. without +offset or Z),
		// we always store the value in this format (i.e. the return value of `formatDate`).
		const handleInputChange = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				if (isResettingValueRef.current) {
					return;
				}

				const inputValue = event.target.value;
				const date = moment(inputValue, displayFormat, true);

				if (date.isValid()) {
					handleChange(formatDate(date, modifier), true);
				} else {
					handleChange(inputValue, false);
				}
			},
			[handleChange, modifier, displayFormat]
		);

		const handleDateChange = useCallback(
			(nextDate: Moment | null) => {
				if (nextDate) {
					handleChange(formatDate(nextDate.startOf('day'), modifier), true);
				}
			},
			[handleChange, modifier]
		);

		const isOutsideRange = useMemo(
			() => (constraint ? (date: Moment) => !constraint.getIsDateValid(date, dateConfig) : F),
			[constraint, dateConfig]
		);

		const initialVisibleMonth = useMemo(
			() => (constraint ? () => constraint.getInitialVisibleMonth(dateConfig) : null),
			[constraint, dateConfig]
		);

		const singleDatePickerValue = valueOverride ?? value;

		// NOTE: `moment.parseZone()` will basically ignore any timezone information we receive.
		// This is important because BE doesn't care about timezones and if the local time was west to UTC
		// (i.e. negative offset), the date displayed would be different.
		const singleDatePickerDate = moment(singleDatePickerValue, supportedFormats, true).isValid()
			? moment.parseZone(singleDatePickerValue, supportedFormats)
			: null;

		const rootRef = useRef<HTMLDivElement>(null);

		const { openDirection } = useOpenDirection({
			getRequiredHeight: getCalendarHeight,
			shouldUpdate: isFocused,
			triggerRef: rootRef,
		});

		const renderMonthElement = useCallback(
			calendarHeaderProps => (
				<CalendarHeader
					{...calendarHeaderProps}
					yearSelectDirection={yearSelectDirection}
					validYears={constraint?.getValidYears(dateConfig)}
				/>
			),
			[constraint, dateConfig, yearSelectDirection]
		);

		const { applyStyle } = useStyles();

		return (
			<InputWrapper
				className={className}
				error={error}
				icon="calendar"
				isDisabled={isDisabled}
				isRequired={isRequired}
				label={label}
				labelFor={id}
				ref={rootRef}
			>
				<div
					className={applyStyle(dateInputStyle, {
						textInputStyleProps: {
							hasFocusedStyle: true,
							isDisabled,
							isFocused,
							isInvalid,
							isReadOnly,
							variant: 'form',
						},
						openDirection,
					})}
					// HACK: SingleDatePicker doesn't have input onChange callback which would trigger with an invalid date.
					// But the event is not stopped and is propagated to the parent component. This can be removed after
					// https://github.com/airbnb/react-dates/pull/1684 is ready.
					onChange={handleInputChange}
				>
					<SingleDatePicker
						anchorDirection={isRtl ? ANCHOR_RIGHT : ANCHOR_LEFT}
						date={singleDatePickerDate}
						disabled={isDisabled}
						displayFormat={displayFormat}
						// NOTE: Because we are typing the date manually in E2E tests, we don't need to show the calendar.
						focused={isFocused && !isInCypress}
						hideKeyboardShortcutsPanel
						id={id}
						isDayHighlighted={F}
						isOutsideRange={isOutsideRange}
						initialVisibleMonth={initialVisibleMonth}
						isRTL={isRtl}
						monthFormat="MMMM YYYY"
						numberOfMonths={1}
						onDateChange={handleDateChange}
						onFocusChange={handleFocusChange}
						orientation={HORIZONTAL_ORIENTATION}
						openDirection={openDirection === 'up' ? OPEN_UP : OPEN_DOWN}
						placeholder={isRtl ? reverse(displayFormat) : displayFormat}
						readOnly={isReadOnly}
						ref={ref}
						renderMonthElement={renderMonthElement}
						transitionDuration={0}
						{...datePickerProps}
					/>
				</div>
			</InputWrapper>
		);
	}
);

DateInput.displayName = 'DateInput';
