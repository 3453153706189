import { ComponentType, createContext, useContext } from 'react';
import { MessageDescriptor } from 'react-intl';
import { ImageSourcePropType } from 'react-native';
import { CountryCode } from 'react-native-country-picker-modal';
import type { Country } from 'react-phone-number-input';
import { LocaleSpecification } from 'moment';
import { FieldErrors, FieldValues } from '@ci/forms';

import { userNameTypes } from './constants';

export interface DataListingExternalSettings {
	dataListings: {
		[namespace: string]: {
			disabledFields?: string[];
		};
	};
}

type CustomCreditReportNames = Record<'Advanced' | 'Basic', string>;

interface StepOptions {
	hasButtons?: boolean;
}

interface UserManual {
	language: string;
	link: string;
}

type UserType = 'Individual' | 'Company';

export interface Bureau {
	address: MessageDescriptor | string;
	latitude?: string;
	longitude?: string;
	title: MessageDescriptor | string;
}

export interface InstanceConfig {
	allowedCountries?: Record<number, string>;
	amountLocaleFormatOverride?: string;
	areBouncedChequesEnabled: boolean;
	areContactsHidden: boolean;
	areDisputesEnabled: boolean;
	areFreeReportsLimited: boolean;
	areMultipleDisputesEnabled: boolean;
	arePaymentIncidentsEnabled: boolean;
	areSubscriptionsEnabled: boolean;
	authentication?: {
		ExternalAuthMethods?: ComponentType<any>;
	};
	bureaus: Bureau[];
	contactInfo: {
		bureauTitle?: MessageDescriptor;
		bureauUrl?: Array<Record<string, string>>;
		contactAddress: MessageDescriptor;
		contactEmail: string;
		contactPhone: string;
	};
	customCreditReportNames?: CustomCreditReportNames;
	customHomepageNavMenuOrder?: string[];
	decimalPlaces: {
		maximumFractionDigits?: number;
		minimumFractionDigits?: number;
	};
	defaultPhoneInputCountry: Country;
	defaultUserType: UserType;
	hiddenProductFeatures?: string[];
	isBenefitsSectionEnabled: boolean;
	isBlogEnabled?: boolean;
	isBouncedDirectDebitEnabled: boolean;
	isContractCategoryEnabled: boolean;
	isExemptionInformationEnabled: boolean;
	isFactorCodeHidden?: boolean;
	isFintechEnabled: boolean;
	isGoogleAnalyticsEnabled: boolean;
	isInvoiceNumberAvailable: boolean;
	isMobileAppEnabled: boolean;
	isRegistrationDisabled?: boolean;
	isScoreDataEnabled: boolean;
	isSubscriptionUpgradeEnabled: boolean;
	isTelecomEnabled: boolean;
	isWalletEnabled: boolean;
	isWhoAreWeSectionEnabled?: boolean;
	lastInquiriesPeriod: number;
	overrideSettingsData: boolean;
	receiptPrefix?: string;
	restrictedUserTypes: UserType[];
	shouldDisplaySenderInDisputeComments?: boolean;
	shouldUseLocalizedAssets: boolean;
	showPaymentSuccessAsModal: boolean;
	storeLinks?: {
		apple: string;
		google: string;
	};
	themeOptions: {
		hasAlternativeAppImage: boolean;
		hasAlternativeDashboardTheme: boolean;
		hasAlternativeIntro: boolean;
	};
	userManuals?: UserManual[];
	userNameType: keyof typeof userNameTypes;
}

export interface NativeInstanceConfig {
	allowedCountries: Record<number, string>;
	amountLocaleFormatOverride?: string;
	areMultipleDisputesEnabled: boolean;
	arePushNotificationsEnabled: boolean;
	authentication?: {
		ExternalAuthMethods?: ComponentType<any>;
	};
	backgroundPlaceholderImage: ImageSourcePropType;
	bureaus: Bureau[];
	customCreditReportNames?: CustomCreditReportNames;
	dataListings?: DataListingExternalSettings;
	dateConfig: {
		adultAge: 18;
	};
	defaultPhoneInputCountry: CountryCode;
	hiddenProductFeatures?: string[];
	initialRoute: string;
	isContractCategoryEnabled: boolean;
	isGoogleAnalyticsEnabled: boolean;
	isSubscriptionUpgradeEnabled: boolean;
	isWalletEnabled: boolean;
	modules: any;
	momentFormatOverrides?: Record<string, LocaleSpecification>;
	phoneInputAllowedCountries?: CountryCode[];
	priorityNationalities?: number[];
	receiptPrefix?: string;
	registrationJourney: {
		asyncBlurFields?: string[];
		generalInfoStep: ComponentType<any>;
		makeValidations?: (userType: number) => (values: FieldValues) => FieldErrors;
		paymentStep: ComponentType<any>;
		personalInfoStep: ComponentType<any>;
		planSelectionStep?: ComponentType<any>;
		stepOptions?: {
			[key: string]: StepOptions;
		};
	};
	restrictedUserTypes: UserType[];
	shouldDisplaySenderInDisputeComments?: boolean;
	unauthorizedScreen?: ComponentType<any>;
	userNameType: keyof typeof userNameTypes;
}

export const InstanceConfigContext = createContext<Partial<InstanceConfig>>({});
export const NativeInstanceConfigContext = createContext<Partial<NativeInstanceConfig>>({});

export const useInstanceConfig = () => useContext(InstanceConfigContext);
export const useNativeInstanceConfig = () => useContext(NativeInstanceConfigContext);
