import React from 'react';
import { Button, ButtonProps } from '@ci/atoms';
import { Link } from '@myci/gatsby-plugin-intl';

interface ButtonLinkProps extends ButtonProps {
	to: string;
}

export const ButtonLink = ({ children, ...otherProps }: ButtonLinkProps) => (
	<Button {...otherProps} as={Link}>
		{children}
	</Button>
);
