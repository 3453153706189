import { compose, map, o, prop, trim } from 'ramda';
import { defaultToEmptyString, get } from 'ramda-extension';
import { isInBrowser, splitByCommaSafe } from '@myci/utils';

import sassVariables from '../scss/01_config/_variables.scss';

import { removePx } from './removePx';

const getVariable = o(defaultToEmptyString, get(sassVariables));

const getSanitizedSassValue = o(removePx, breakpoint => getVariable([`screen-${breakpoint}`]));

export const getOrderedBreakpoints = () =>
	isInBrowser()
		? compose(
				map(o(breakpoint => [breakpoint, getSanitizedSassValue(breakpoint)], trim)),
				splitByCommaSafe,
				prop('breakpoints-order')
			)(sassVariables)
		: [['lg', 0]];
