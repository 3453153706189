import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { cx } from 'ramda-extension';
import { isInBrowser, isNotNilNorEmpty } from '@myci/utils';
import { map } from 'ramda';
import { Link } from '@myci/gatsby-plugin-intl';
import { Message } from '@ci/message';
import { loadLocale, setLocaleInStorage, useLocaleMessageFormatter } from '@myci/intl';
import { TOP_OVERLAY_CLASS_NAME } from '@ci/atoms';

import './StickyNavigation.scss';
import AnchorLink from '../AnchorLink';
import Box from '../Box';
import Grid from '../Grid';
import Icon from '../Icon';
import useEventListener from '../../hooks/useEventListener';
import useViewport from '../../hooks/useViewport';

/**
 * Sticky navigation bar used in application layout as a main navigation part.
 * It is hardcoded that only sticks to the `window`'s viewport (room for future improvement).
 *
 * NOTE: proceed with caution - it is STICKY!
 */
const StickyNavigation = ({
	children,
	className,
	stickyNavMenuItems = [],
	logo,
	additionalMenuContent,
	transparent,
	supportedLocales,
	isLoading,
	changeLocale,
	locale,
	shouldReloadOnChange,
}) => {
	const [isSticky, setSticky] = useState(false);
	const [isHamburgerNavOpen, setHamburgerNavOpen] = useState(false);
	const [isLanguageSwitcherOpen, setIsLanguageSwitcherOpen] = useState(false);
	const nav = useRef(null);
	const { lte } = useViewport();

	// TODO: future improvement - own hook that sticks?
	const handleScroll = () => {
		if (!nav.current) {
			return;
		}
		// TODO: proper DOM utils - can be replaced by dom-utils? or @restart?
		// let topPosition = nav.current.getBoundingClientRect().top || 0;
		const height = nav.current.getBoundingClientRect().height || 0;

		if (window.pageYOffset <= height) {
			setSticky(false);
		}

		if (window.pageYOffset > 0) {
			setSticky(true);
		}
	};

	const handleToggleClick = () => setHamburgerNavOpen(!isHamburgerNavOpen);
	const handleAnchorClick = () => setHamburgerNavOpen(false);

	useEventListener(isInBrowser() ? window : null, 'scroll', isInBrowser() ? handleScroll : null);

	const localeMessageFormatter = useLocaleMessageFormatter();

	return (
		<Grid
			className={cx(
				[
					{ 'is-sticky': isSticky },
					{ 'is-open': isHamburgerNavOpen },
					{ 'is-transparent': transparent },
				],
				'sticky-navbar',
				TOP_OVERLAY_CLASS_NAME,
				className
			)}
			ref={nav}
		>
			<Grid container fluid alignItems="center">
				<Grid as="nav" alignItems="center" className="sticky-nav">
					{logo}
					{isNotNilNorEmpty(stickyNavMenuItems) && (
						<Box as="ul" className="sticky-nav__list">
							<li className="sticky-nav__link sticky-nav__additional-content">
								{additionalMenuContent}
							</li>
							{map(({ id, label, labelDescriptor, isExternalUrl, url }) => (
								<li key={url ?? id}>
									{isExternalUrl ? (
										<Link to={url} className="sticky-nav__link">
											<Message {...labelDescriptor} />
										</Link>
									) : (
										<AnchorLink id={id} onClick={handleAnchorClick} className="sticky-nav__link">
											{label}
										</AnchorLink>
									)}
								</li>
							))(stickyNavMenuItems)}
							{lte('sm') && isHamburgerNavOpen && !isLoading && locale && (
								<li>
									<a
										className={cx('sticky-nav__link', {
											open: isLanguageSwitcherOpen,
										})}
										href="javascript:;"
										onClick={() => setIsLanguageSwitcherOpen(!isLanguageSwitcherOpen)}
									>
										<Icon type="language" mr={2} />
										{localeMessageFormatter.format(locale)}
									</a>
									{isLanguageSwitcherOpen && (
										<ul className="sub-nav-mobile__list">
											<li>
												{supportedLocales &&
													map(
														languageCode => (
															<a
																key={languageCode}
																className="sub-nav__link"
																href="javascript:;"
																onClick={() => {
																	if (shouldReloadOnChange) {
																		changeLocale({ locale: languageCode });
																		setLocaleInStorage(languageCode);

																		return;
																	}
																	loadLocale(languageCode, { userRequested: true });
																}}
															>
																{localeMessageFormatter.format(languageCode)}
															</a>
														),
														supportedLocales
													)}
											</li>
										</ul>
									)}
								</li>
							)}
						</Box>
					)}
				</Grid>

				<div className="sticky-navbar__additional-content-container">
					<div className="sticky-navbar__additional-menu-item">{additionalMenuContent}</div>
					{children}
				</div>
				{isNotNilNorEmpty(stickyNavMenuItems) && (
					<Box className="sticky-nav__mobile-toggle" onClick={handleToggleClick}>
						<span />
						<span />
						<span />
					</Box>
				)}
			</Grid>
		</Grid>
	);
};

StickyNavigation.propTypes = {
	additionalMenuContent: PropTypes.node,
	/** Changes locale in url. */
	changeLocale: PropTypes.func,
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Applies to root element */
	className: PropTypes.string,
	/** Loading boolean provided by withConnectedLocale. */
	isLoading: PropTypes.bool,
	/** Current locale string provided by withConnectedLocale. */
	locale: PropTypes.string,
	/** Render logo as a node */
	logo: PropTypes.node,
	shouldReloadOnChange: PropTypes.bool,
	/** List of anchors/links to render in navigation. */
	stickyNavMenuItems: PropTypes.arrayOf(
		PropTypes.shape({
			/** ID attribute of a section that is linked with the anchor */
			id: PropTypes.string,
			/** Label of the anchor shown */
			label: PropTypes.node,
			/** Should content be rendered as Link or Anchorlink */
			isExternalUrl: PropTypes.bool,
		}).isRequired
	),
	/** Array of supported locales provided by withConnectedLocale. */
	supportedLocales: PropTypes.array,
	/** If `true` applies transparent background */
	transparent: PropTypes.bool,
};

export default StickyNavigation;
