import React, { ReactElement } from 'react';
import { Text } from '@ci/atoms';
import { prepareStyle, useStyles } from '@ci/styles';

const wrapperStyle = prepareStyle(utils => ({
	fontSize: utils.fontSizes.caption,
	paddingInlineEnd: utils.spacings.md,
	paddingInlineStart: utils.spacings.md,
	paddingTop: utils.spacings.sm,
}));

interface FileInputInfoProps {
	content: ReactElement;
}

export const FileInputInfo = ({ content }: FileInputInfoProps) => {
	const { applyStyle } = useStyles();

	return (
		<Text icon="info" className={applyStyle(wrapperStyle)}>
			{content}
		</Text>
	);
};
