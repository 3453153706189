import { defineMessages } from 'react-intl';

export default defineMessages({
	backButton: {
		id: 'uiComponents.back',
		defaultMessage: 'back',
		description: 'Back button text',
	},
	maxDecimalPlaces: {
		id: 'uiComponents.maxDecimalPlaces',
		defaultMessage: 'Max {decimalPlaces} decimal places',
		description: 'Legend for decimal input',
	},
	mandatoryFields: {
		id: 'uiComponents.mandatoryFields',
		defaultMessage: 'Mandatory Fields',
		description: 'Text informing the user that star marks the mandatory fields.',
	},
	filterShow: {
		id: 'uiComponents.filterShow',
		defaultMessage: 'Show filter',
		description: 'Show filter action within filter component.',
	},
	filterHide: {
		id: 'uiComponents.filterHide',
		defaultMessage: 'Hide filter',
		description: 'Hide filter action within filter component.',
	},
	selectStatics_any: {
		defaultMessage: 'Any',
		description: 'Generic select item, used in filters as an ignored value',
		id: 'uiComponents.selectStatics_any',
	},
	footer: {
		defaultMessage: 'User: {user} / Version: {instance} v{version} / Last update: {lastUpdate}',
		description: 'Footer message',
		id: 'uiComponents.footer',
	},
	fileUploadDescriptionLink: {
		id: 'uiComponents.fileUploadDescriptionLink',
		defaultMessage: 'browse',
	},
	finishUpload: {
		id: 'uiComponents.finishUpload',
		defaultMessage: 'Finish upload',
	},
	uploadDone: {
		id: 'uiComponents.uploadDone',
		defaultMessage: 'Done',
	},
	uploadError: {
		id: 'uiComponents.uploadError',
		defaultMessage: 'Error',
	},
	dataGridTotalItems: {
		id: 'uiComponents.dataGridTotalItems',
		defaultMessage: 'Total Items: {value}',
	},
	dataGridNoData: {
		id: 'uiComponents.dataGridNoData',
		defaultMessage: 'No data',
	},
	selectStatics_all: {
		defaultMessage: 'All',
		description: 'Generic select item, used in filters as an ignored value',
		id: 'uiComponents.selectStatics_all',
	},
	selectStatics_notSpecified: {
		defaultMessage: 'Not Specified',
		description: 'Generic select item, used in filters as an ignored value',
		id: 'uiComponents.selectStatics_notSpecified',
	},
	clear: {
		defaultMessage: 'Clear',
		id: 'uiComponents.cancel',
	},
	confirm: {
		defaultMessage: 'Confirm',
		id: 'uiComponents.confirm',
	},
	countSelected: {
		defaultMessage: '{count} selected',
		id: 'uiComponents.countSelected',
	},
	dataGridLoading: {
		id: 'uiComponents.dataGridLoading',
		defaultMessage: 'Loading data',
		description: 'Label for spinner',
	},
	dataGridSortDataAction: {
		id: 'uiComponents.sortDataAction',
		defaultMessage: 'Sort data',
		description: 'Description for sort arrows in data grid',
	},
	dataGridToggleColumns: {
		id: 'uiComponents.dataGridToggleColumns',
		defaultMessage: 'Add / Remove Columns',
		description: 'Label for menu inside data grid for toggling visibility of columns',
	},
	dataGridSearch: {
		id: 'uiComponents.dataGridSearch',
		defaultMessage: 'Search',
		description: 'Search field placeholder in menu for toggling visibility of columns in data grid',
	},
	dataGridCheckAll: {
		id: 'uiComponents.dataGridCheckAll',
		defaultMessage: 'Check all',
		description: 'Check all action - located in selection of columns menu',
	},
	dataGridToggleAll: {
		id: 'uiComponents.dataGridToggleAll',
		defaultMessage: 'All',
		description: 'Check all action - located in sorting columns menu',
	},
	close: {
		id: 'uiComponents.close',
		defaultMessage: 'Close',
		description: 'Close button label',
	},
	wroteTo: {
		id: 'uiComponents.wroteTo',
		defaultMessage: 'wrote to {recipient}',
		description: 'wrote to',
	},
	hide: {
		id: 'uiComponents.hide',
		defaultMessage: 'Hide',
		description: 'Hide',
	},
	more: {
		id: 'uiComponents.more',
		defaultMessage: 'More',
		description: 'More',
	},
	systemMessage: {
		id: 'uiComponents.systemMessage',
		defaultMessage: 'System message',
		description: 'System message',
	},
	year: {
		id: 'uiComponents.year',
		defaultMessage: 'Year',
		description: 'Year',
	},
	quartal: {
		id: 'uiComponents.quartal',
		defaultMessage: 'Quartal',
		description: 'Quartal',
	},
	month: {
		id: 'uiComponents.month',
		defaultMessage: 'Month',
		description: 'Month',
	},
	accordionPanelNoRules: {
		id: 'uiComponents.accordionPanelNoRules',
		defaultMessage: 'No rules',
		description: 'No rules label',
	},
	accordionPanelRulesApplied: {
		id: 'uiComponents.accordionPanelRulesApplied',
		defaultMessage: 'Rules applied',
		description: 'Rules applied label',
	},
	scoreGraphCipScore: {
		id: 'ui.scoreGraphCipScore',
		defaultMessage: 'CIP Score',
		description: 'Rules applied label',
	},
	scoreGraphCipGrade: {
		id: 'ui.scoreGraphCipGrade',
		defaultMessage: 'CIP Grade',
		description: 'CIP Grade',
	},
	scoreGraphDefaultProbability: {
		id: 'ui.scoreGraphDefaultProbability',
		defaultMessage: 'default %',
		description: 'default %',
	},
	attachmentUpload: {
		id: 'ui.attachmentUpload',
		defaultMessage: 'Upload Attachment',
		description: 'Description for upload attachment button',
	},
	uploadedFile: {
		id: 'ui.uploadedFile',
		defaultMessage: 'Upload File',
		description: 'Uploaded file label',
	},
	upload: {
		id: 'ui.upload',
		defaultMessage: 'Upload',
		description: 'Upload label',
	},
	appVersion: {
		id: 'ui.appVersion',
		defaultMessage: 'App Version: {appVersion} | API Version: {apiVersion}',
	},
	unread: {
		id: 'ui.unread',
		defaultMessage: 'Unread',
	},
	loadingText: {
		id: 'uiComponents.loadingText',
		defaultMessage: 'Loading...',
	},
});
