import React from 'react';
import { MaxWidths, Style, prepareStyle, useStyles } from '@ci/styles';
import { Breakpoint } from '@creditinfo-ui/fela';
import { Spacing } from '@ci/atoms';

export interface AttachmentsProps {
	attachments: any[];
	columnGap?: Spacing;
	customStyle?: Style<AttachmentsWrapperStyleProps>;
	maxWidth?: keyof MaxWidths;
	mobileLayoutBreakpoint: Breakpoint;
}

interface AttachmentsWrapperStyleProps {
	columnGap: Spacing;
	maxWidth?: keyof MaxWidths;
	mobileLayoutBreakpoint: Breakpoint;
}
const attachmentsWrapperStyle = prepareStyle<AttachmentsWrapperStyleProps>(
	(utils, { columnGap, maxWidth, mobileLayoutBreakpoint }) => ({
		alignItems: 'baseline',
		display: 'grid',
		gridAutoFlow: 'row',
		gridColumnGap: utils.spacings[columnGap],
		gridRowGap: utils.spacings.md,
		gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
		marginTop: utils.spacings.md,
		width: '100%',
		selectors: {
			[utils.breakpoints[mobileLayoutBreakpoint]]: {
				gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
				paddingInlineEnd: 0,
				paddingInlineStart: 0,
			},
		},
		extend: [
			{
				condition: Boolean(maxWidth),
				style: {
					maxWidth: utils.maxWidths[maxWidth!],
				},
			},
		],
	})
);

export const Attachments = ({
	attachments,
	columnGap = 'md',
	customStyle,
	maxWidth,
	mobileLayoutBreakpoint = 'sm',
}: AttachmentsProps) => {
	const { applyStyle } = useStyles();

	return (
		<div
			className={applyStyle([attachmentsWrapperStyle, customStyle], {
				columnGap,
				maxWidth,
				mobileLayoutBreakpoint,
			})}
		>
			{attachments}
		</div>
	);
};
