import { ReactNode } from 'react';
import { Style, prepareStyle, useStyles } from '@ci/styles';

type CollapsibleTitleSize = 'large' | 'small';

export interface CollapsibleTitleStyleProps {
	size: CollapsibleTitleSize;
}

const collapsibleTitleStyle = prepareStyle<CollapsibleTitleStyleProps>((utils, { size }) => ({
	extend: [
		{
			condition: size === 'small',
			style: {
				fontSize: utils.fontSizes.base,
				fontWeight: utils.fontWeights.semiBold,
			},
		},
		{
			condition: size === 'large',
			style: {
				fontSize: utils.fontSizes.collapsibleTitle,
				fontWeight: utils.fontWeights.bold,
			},
		},
	],
}));

export interface CollapsibleTitleProps {
	customStyle?: Style<CollapsibleTitleStyleProps>;
	size?: CollapsibleTitleSize;
	title: ReactNode;
}

export const CollapsibleTitle = ({ customStyle, size = 'large', title }: CollapsibleTitleProps) => {
	const { applyStyle } = useStyles();

	return (
		<span role="heading" className={applyStyle([collapsibleTitleStyle, customStyle], { size })}>
			{title}
		</span>
	);
};
