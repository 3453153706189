import { queryTopOverlay } from '@creditinfo-ui/atoms';
import { getDefaultScrollingElement, getScrollingParent, mergeRefs } from '@creditinfo-ui/utils';
import { ForwardedRef, forwardRef, useCallback, useLayoutEffect, useRef } from 'react';
import { TAB_BUTTON_PADDING_BOTTOM } from '../constants';

import { TabItem, TabKey, TabsLayout, TabsVariant } from '../types';
import { DefaultTabButton } from './DefaultTabButton';
import { StepperTabButton } from './StepperTabButton';

export interface TabProps {
	activeIndex: number;
	activeKey: TabKey;
	index: number;
	layout: TabsLayout;
	onTabClick: (key: TabKey) => void;
	tab: TabItem;
	variant: TabsVariant;
}

export const Tab = forwardRef(
	(
		{
			activeIndex,
			activeKey,
			index,
			layout,
			onTabClick,
			tab: { key, isDisabled = false, isInvalid = false, label, icon },
			variant,
		}: TabProps,
		forwardedRef: ForwardedRef<HTMLButtonElement>
	) => {
		const isActive = activeKey === key;
		const previousIsActiveRef = useRef(isActive);
		const ourRef = useRef<HTMLButtonElement>(null);
		const mergedRef = forwardedRef ? mergeRefs([forwardedRef, ourRef]) : ourRef;

		useLayoutEffect(() => {
			if (layout === 'vertical' && isActive && !previousIsActiveRef.current && ourRef.current) {
				const scrollingParent = getScrollingParent(ourRef.current);
				const hasCustomScrollingParent = scrollingParent !== getDefaultScrollingElement();
				const topOverlay = hasCustomScrollingParent ? null : queryTopOverlay();
				const topOverlayHeight = topOverlay?.clientHeight ?? 0;

				scrollingParent.scrollTo({
					top:
						scrollingParent.scrollTop +
						ourRef.current.getBoundingClientRect().top -
						(hasCustomScrollingParent ? scrollingParent.getBoundingClientRect().top : 0) -
						topOverlayHeight -
						TAB_BUTTON_PADDING_BOTTOM,
				});
			}

			previousIsActiveRef.current = isActive;
		}, [isActive, layout]);

		const handleClick = useCallback(() => {
			onTabClick(key);
		}, [key, onTabClick]);

		return variant === 'stepper' ? (
			<StepperTabButton
				ref={mergedRef}
				activeIndex={activeIndex}
				index={index}
				isActive={isActive}
				isDisabled={isDisabled}
				isInvalid={isInvalid}
				label={label}
				onClick={handleClick}
				icon={icon}
				layout={layout}
			/>
		) : (
			<DefaultTabButton
				ref={mergedRef}
				isActive={isActive}
				isDisabled={isDisabled}
				isInvalid={isInvalid}
				label={label}
				onClick={handleClick}
			/>
		);
	}
);

Tab.displayName = 'Tab';
