import { prepareMessages } from '@creditinfo-ui/messages/node';

export const m = prepareMessages('atoms', [
	['emptyValue', '(Empty)'],
	['toggleOn', 'On'],
	['toggleOff', 'Off'],
	['loading', 'Loading…'],
	['selectedItemNotAvailable', 'This value is no longer available'],
	// ==== START ICON TOOLTIPS ====
	['icon_add', 'Add'],
	['icon_addressPhysical', 'Physical Address'],
	['icon_addressPostal', 'Postal Address'],
	['icon_administration', 'Administration'],
	['icon_antenna', 'Antenna'],
	['icon_antennaWaves', 'Antenna Waves'],
	['icon_approve', 'Approve'],
	['icon_arrowLeft', 'Arrow Left'],
	['icon_arrowRight', 'Arrow Right'],
	['icon_attachment', 'Attachment'],
	['icon_bell', 'Bell'],
	['icon_bigData', 'Big Data'],
	['icon_billing', 'Billing'],
	['icon_bouncedCheques', 'Bounced Cheques'],
	['icon_calendar', 'Calendar'],
	['icon_cancel', 'Cancel'],
	['icon_caretDown', 'Caret Down'],
	['icon_caretRight', 'Caret Right'],
	['icon_caretUp', 'Caret Up'],
	['icon_chevronDown', 'Chevron Down'],
	['icon_chevronLeft', 'Chevron Left'],
	['icon_chevronRight', 'Chevron Right'],
	['icon_chevronUp', 'Chevron Up'],
	['icon_circle', 'Circle'],
	['icon_close', 'Close'],
	['icon_closeCircle', 'Close'],
	['icon_code', 'Code'],
	['icon_columns', 'Columns'],
	['icon_columnStatusAscending', 'Ascending'],
	['icon_columnStatusAscendingFiltered', 'Ascending Filtered'],
	['icon_columnStatusDescending', 'Descending'],
	['icon_columnStatusDescendingFiltered', 'Descending Filtered'],
	['icon_columnStatusFiltered', 'Filtered'],
	['icon_company', 'Company'],
	['icon_contact', 'Contact'],
	['icon_contactInfo', 'Contact Info'],
	['icon_contract', 'Contract'],
	['icon_contracts', 'Contracts'],
	['icon_copy', 'Copy'],
	['icon_creditApplications', 'Credit Applications'],
	['icon_dataManagement', 'Data Management'],
	['icon_delete', 'Delete'],
	['icon_details', 'Details'],
	['icon_devtools', 'DevTools'],
	['icon_disable', 'Disable'],
	['icon_disableReason_AccountStale', 'Account Stale'],
	['icon_disableReason_BranchDisabled', 'Branch Disabled'],
	['icon_disableReason_Deleted', 'Deleted'],
	['icon_disableReason_ManuallyDisabled', 'Manually Disabled'],
	['icon_disableReason_None', 'Active'],
	['icon_disableReason_NotSpecified', 'Not Specified'],
	['icon_disableReason_SourceDisabled', 'Source Disabled'],
	['icon_disableReason_SubscriberDisabled', 'Subscriber Disabled'],
	['icon_disableReason_SubscriberExpired', 'Subscriber Expired'],
	['icon_disableReason_TooManyFailed2FAAttempts', 'Too Many Failed 2FA Attempts'],
	['icon_disableReason_TooManyFailedCaptchaAttempts', 'Too Many Failed Captcha Attempts'],
	['icon_disableReason_TooManyFailedLoginAttempts', 'Too Many Failed Login Attempts'],
	['icon_disableReason_UserExpired', 'User Expired'],
	['icon_dispute', 'Dispute'],
	['icon_dot', 'Dot'],
	['icon_download', 'Download'],
	['icon_dropzone', 'Dropzone'],
	['icon_edit', 'Edit'],
	['icon_enable', 'Enable'],
	['icon_export', 'Export'],
	['icon_exportCsv', 'Export CSV'],
	['icon_external', 'External'],
	['icon_failure', 'Failure'],
	['icon_file', 'File'],
	['icon_filtering', 'Filtering'],
	['icon_group', 'Group'],
	['icon_hamburger', 'Menu'],
	['icon_help', 'Help'],
	['icon_history', 'History'],
	['icon_idGeneration', 'ID Generation'],
	['icon_idNumbers', 'ID Numbers'],
	['icon_individual', 'Individual'],
	['icon_info', 'Info'],
	['icon_inquiries', 'Inquiries'],
	['icon_instantDecision', 'Instant Decision'],
	['icon_internal', 'Internal'],
	['icon_kebab', 'Menu'],
	['icon_locale', 'Locale'],
	['icon_localeSwitcher', 'Locale Switcher'],
	['icon_locked', 'Locked'],
	['icon_loginInfo', 'Login Info'],
	['icon_matching', 'Matching'],
	['icon_messageRead', 'Message Read'],
	['icon_minus', 'Minus'],
	['icon_monitoring', 'Monitoring'],
	['icon_noData', 'No Data'],
	['icon_noDataFiltered', 'No Data Filtered'],
	['icon_notification', 'Notification'],
	['icon_pageFirst', 'First Page'],
	['icon_pageLast', 'Last Page'],
	['icon_pageNext', 'Next Page'],
	['icon_pagePrevious', 'Previous Page'],
	['icon_partialMatch', 'Partial Match'],
	['icon_passwordHide', 'Hide'],
	['icon_passwordShow', 'Show'],
	['icon_pause', 'Pause'],
	['icon_payments', 'Payments'],
	['icon_plus', 'Plus'],
	['icon_power', 'Power'],
	['icon_print', 'Print'],
	['icon_remove', 'Remove'],
	['icon_report', 'Report'],
	['icon_reportBusinessSummary', 'Business Summary Report'],
	['icon_reportConsumer', 'Consumer Report'],
	['icon_reportConsumerPlus', 'Consumer Report Plus'],
	['icon_reportCreditinfo', 'Creditinfo Report'],
	['icon_reportCreditinfoPlus', 'Creditinfo Report Plus'],
	['icon_reportInvolvements', 'Involvements Report'],
	['icon_reportNegativeInfo', 'Negative Info Report'],
	['icon_reportOcb', 'OCB Report'],
	['icon_reportQuickSummary', 'Quick Summary Report'],
	['icon_reportScoring', 'Scoring Report'],
	['icon_reportSeeId', 'See ID Report'],
	['icon_reportSkipTrace', 'Skip Trace Report'],
	['icon_reportTelecom', 'Telecom Report'],
	['icon_requiredAsterisk', 'Required'],
	['icon_reset', 'Reset'],
	['icon_response', 'Response'],
	['icon_restart', 'Restart'],
	['icon_resume', 'Resume'],
	['icon_rollback', 'Rollback'],
	['icon_run', 'Run'],
	['icon_scoring', 'Scoring'],
	['icon_search', 'Search'],
	['icon_searchNotAvailable', 'Search Not Available'],
	['icon_selection0', 'None'],
	['icon_selection100', 'All'],
	['icon_selection50', 'Some'],
	['icon_send', 'Send'],
	['icon_settings', 'Settings'],
	['icon_show', 'Show'],
	['icon_showReport', 'Show Report'],
	['icon_showReportNotRenderable', 'Show Not Renderable Report'],
	['icon_showReportSummary', 'Show Summary Report'],
	['icon_sorting', 'Sorting'],
	['icon_statistics', 'Statistics'],
	['icon_statusFailure', 'Failure'],
	['icon_statusNotApproved', 'Not Approved'],
	['icon_statusNotAvailable', 'Not Available'],
	['icon_statusNotSpecified', 'Not Specified'],
	['icon_statusProcessing', 'Processing'],
	['icon_statusSuccess', 'Success'],
	['icon_statusUploading', 'Uploading'],
	['icon_statusWaiting', 'Waiting'],
	['icon_statusWarning', 'Warning'],
	['icon_stop', 'Stop'],
	['icon_success', 'Success'],
	['icon_successCircle', 'Success'],
	['icon_supervisoryReport', 'Supervisory Report'],
	['icon_systemMaintenance', 'System Maintenance'],
	['icon_taxRates', 'Tax Rates'],
	['icon_toggleOff', 'Toggle Off'],
	['icon_toggleOn', 'Toggle On'],
	['icon_trendDown', 'Trend Down'],
	['icon_trendSideways', 'Trend Sideways'],
	['icon_trendUp', 'Trend Up'],
	['icon_unlock', 'Unlock'],
	['icon_upload', 'Upload'],
	['icon_uploadCancel', 'Upload Cancel'],
	['icon_user', 'User'],
	['icon_warning', 'Warning'],
	// ==== END ICON TOOLTIPS ====
	['showLess', 'Show Less'],
	['showMore', 'Show More'],
	['veryWeak', 'Very Weak'],
	['weak', 'Weak'],
	['wroteTo', 'wrote to {recipient}'],
	['good', 'Good'],
	['strong', 'Strong'],
	['veryStrong', 'Very Strong'],
	['passwordStrengthSufficient', 'Password is satisfying the strength requirements'],
	['passwordStrengthInsufficient', 'Password is not satisfying the strength requirements'],
	['required', 'Required'],
	['selectedItemsCount', '{count} selected'],
	['confirm', 'Confirm'],
	['clear', 'Clear'],
	['noResultsMatchFilterCriteria', 'No results match your filter criteria'],
]);
