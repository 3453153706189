import * as components from '@myci/ui-components';
import { FileInput as FileInputComponent } from '@myci/attachments';
import { withProps } from '@ci/react-utils';
import { DateInput } from '@ci/dates';
import { compose, o, omit } from 'ramda';
import { withField } from '@ci/redux-form';

import { withFieldProps } from '../decorators';
import { mapError } from '../utils';

// NOTE: Components to be used as the `component` prop of `<Field />`
export const TextInput = withFieldProps(components.TextInput);
export const DecimalInput = withFieldProps(components.DecimalInput);
export const Select = withFieldProps(components.Select);
export const Checkbox = withFieldProps(components.Checkbox);
export const InfoCheckbox = withFieldProps(components.InfoCheckbox);
export const Toggle = withFieldProps(components.Toggle);
export const Textarea = withFieldProps(components.Textarea);
export const EditableText = withFieldProps(components.EditableText);
export const YearFragmentSelect = withFieldProps(components.YearFragmentSelect);
export const RadioGroup = withFieldProps(components.RadioGroup);
export const FileInput = withFieldProps(FileInputComponent);

export const DatePicker = withFieldProps(DateInput);

export const Radio = withFieldProps(components.Radio);
export const CardRadio = withFieldProps(components.CardRadio);

// NOTE: Components to be used instead of `<Field />`
export const TextInputField = withField(TextInput);
export const DecimalInputField = withField(DecimalInput);
export const SelectField = withField(Select);
export const TextareaField = withField(Textarea);

// NOTE: This is necessary because `<Field />` changes behaviour based on passed type.
// `<Checkbox />` sets its own type, but `<Field />` has no idea about it. :(
const withRadioField = o(withProps<any>({ type: 'radio' }), withField) as typeof withField;
const withCheckboxField = o(withProps<any>({ type: 'checkbox' }), withField) as typeof withField;

export const CheckboxField = withCheckboxField(Checkbox);
export const ToggleField = withCheckboxField(Toggle);
export const RadioField = withRadioField(Radio);
export const CardRadioField = withRadioField(CardRadio);
export const DatePickerField = withField(DatePicker);
export const EditableTextField = withField(EditableText);
export const InfoCheckboxField = withCheckboxField(InfoCheckbox);
export const YearFragmentSelectField = withField(YearFragmentSelect);
export const FileInputFieldDeprecated = withField(FileInput);

// TODO: Why are we using `withFieldProps(Radio)` as the radio control?
// Shouldn't this be handled on the `RadioGroup` level?
export const RadioGroupField = (compose as any)(
	withField,
	withProps(mapError),
	withProps(({ meta, input }) => ({
		radioControl: Radio,
		selectedValue: input.value,
		meta: omit(['error'], meta),
	}))
)(components.RadioGroup);
