import { __, prop } from 'ramda';

// NOTE: All variables must be listed here so we can properly override them via `window.env`.
// WARNING: The following variables will be a part of the source code!
const baseConfig = {
	GATSBY_DEFAULT_LOCALE: process.env.GATSBY_DEFAULT_LOCALE,
	GATSBY_MYCI_FO_API_APPLICATION_KEY: process.env.GATSBY_MYCI_FO_API_APPLICATION_KEY,
	GATSBY_MYCI_FO_RECAPTCHA_SITEKEY: process.env.GATSBY_MYCI_FO_RECAPTCHA_SITEKEY,
	GATSBY_MYCI_FO_SMS_NOTIFICATIONS_TURNED_ON:
		process.env.GATSBY_MYCI_FO_SMS_NOTIFICATIONS_TURNED_ON,
	GATSBY_MYCI_FO_API_URL: process.env.GATSBY_MYCI_FO_API_URL,
	GATSBY_MYCI_INSTANCE: process.env.GATSBY_MYCI_INSTANCE,
	GATSBY_MYCI_BO_API_URL: process.env.GATSBY_MYCI_BO_API_URL,
	GATSBY_MYCI_INSTANCE_TYPE: process.env.GATSBY_MYCI_INSTANCE_TYPE,
	GATSBY_DISABLE_TRANSLATION_WARNINGS: process.env.GATSBY_DISABLE_TRANSLATION_WARNINGS,
	GATSBY_DISABLE_DISPUTES_API: process.env.GATSBY_DISABLE_DISPUTES_API,
	GATSBY_MYCI_UI_VERSION: process.env.GATSBY_MYCI_UI_VERSION,
	GATSBY_MYCI_SESSION_TIMEOUT: process.env.GATSBY_MYCI_SESSION_TIMEOUT,
	GATSBY_MYCI_FO_GOOGLE_ANALYTICS_ID: process.env.GATSBY_MYCI_FO_GOOGLE_ANALYTICS_ID,
	GATSBY_MYCI_FO_GOOGLE_ANALYTICS_TURNED_ON: process.env.GATSBY_MYCI_FO_GOOGLE_ANALYTICS_TURNED_ON,
	GATSBY_MYCI_CHANGE_PASSWORD_URL: process.env.GATSBY_MYCI_CHANGE_PASSWORD_URL,
};

// Note: not for usage on the web, they must be exposed here to prevent TS errors in mobile components
const mobileConfig = {
	MYCI_APP_DEV_CREDENTIALS_USERNAME: process.env.MYCI_APP_DEV_CREDENTIALS_USERNAME,
	MYCI_APP_DEV_CREDENTIALS_PASSWORD: process.env.MYCI_APP_DEV_CREDENTIALS_PASSWORD,
	MYCI_APP_DEBUG_MODE_ENABLED: process.env.MYCI_APP_DEBUG_MODE_ENABLED,
	MYCI_APP_EMULATOR_DETECTION_ENABLED: process.env.MYCI_APP_EMULATOR_DETECTION_ENABLED,
	MYCI_APP_NATIVE_SECURITY_ENABLED: process.env.MYCI_APP_NATIVE_SECURITY_ENABLED,
	MYCI_APP_KEY_HASHES: process.env.MYCI_APP_KEY_HASHES,
};

const runtimeConfig =
	typeof window !== 'undefined'
		? { ...baseConfig, ...mobileConfig, ...window.env }
		: { ...baseConfig, ...mobileConfig };

export const getEnvironmentVariable = prop(__, runtimeConfig);
