// eslint-disable-next-line import/no-extraneous-dependencies, no-restricted-imports
import { UserType } from '@myci/domain-subscriptions/src/types';
import { addToast } from '@ci/toasts';
import {
	InstanceTypes,
	composeMiddleware,
	currentInstanceType,
	getEnvironmentVariable,
	isInBrowser,
	makeMiddleware,
	typeEq,
} from '@myci/utils';
import { createThunk, isFetching, request, selectIsThunkPending } from '@ci/api';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { navigate } from '@myci/navigation';
import { reset } from 'redux-form';
import { run } from '@ci/control-flow';
import { startSubmitForm, stopSubmitForm } from '@myci/ui-components-redux';
import { makeActionTypes, makeConstantActionCreator, makeSimpleActionCreator } from 'redux-syringe';
import { mergeDeepLeft, o, path } from 'ramda';
import { isNotNil } from 'ramda-extension';
import { logOut, setAccessToken } from '@myci/authentication';

import { COMPANY_FREE_PLAN_KEY, USER_FREE_PLAN_KEY } from '../constants';
import m from '../messages';

export interface Account {
	accountEndingDate?: string;
	accountStatus?: string;
	accountType?: string;
	accountTypeLocalized?: string;
	areaName?: string;
	attachment1?: string;
	attachment2?: string;
	attachment3?: string;
	attachment4?: string;
	attachment5?: string;
	city?: string;
	companyType?: string;
	confirmPassword?: string;
	country?: number;
	courtCode?: string;
	culture?: string;
	dateOfBirth?: string;
	district?: string;
	email?: string;
	freeReports?: number;
	idCardScan?: string;
	idCardScan_backSide?: string;
	idCardScan_frontSide?: string;
	idCardScan_selfie?: string;
	idCardScan_sideB?: string;
	messagingChannel?: string;
	name?: string;
	nationalId?: string;
	needsToChangePassword?: boolean;
	numberOfFlat?: string;
	password?: string;
	paymentWaiting?: boolean;
	phoneNumber?: string;
	placeOfBirth?: string;
	plan?: string;
	postalCode?: string;
	province?: string;
	reasonOfRegistration?: number;
	region?: string;
	registrationNumber?: string;
	registrationNumberFirstPart?: string;
	street?: string;
	streetNumber?: string;
	termsAndConditionsChanged: boolean;
	termsAndConditionsVersion: string;
	userName?: string;
	userType?: UserType;
}

export type AccountState = Account;

interface GlobalState {
	account: {
		settings: {
			account: AccountState;
		};
	};
}

const ActionTypes = makeActionTypes('@account', [
	'CHANGE_PERSONAL_INFORMATION',
	'FETCH_USER_ACCOUNT',
	'SET_INITIALIZED',
	'SET_USER_ACCOUNT',
	'SET_ACCOUNT_STATE',
]);

export const setAccountState = createAction<{ needsToChangePassword: boolean }>(
	'@account/setAccountState'
);
export const setUserAccount = createAction<{ result: AccountState }>('@account/setUserAccount');
export const setIntialized = createAction<boolean>('@account/setIntialized');

export const setHasShownTermsAndConditionsChangedModal = createAction<boolean>(
	'@account/hasShownTermsAndConditionsChangedModal'
);
export const fetchUserAccount = makeConstantActionCreator(ActionTypes.FETCH_USER_ACCOUNT);

// TODO - remove after native form is updated
export const changePersonalInformationDeprecated = makeSimpleActionCreator(
	ActionTypes.CHANGE_PERSONAL_INFORMATION
);

const locale = getEnvironmentVariable('GATSBY_DEFAULT_LOCALE');

const initialState = {
	initialized: false,
	hasShownTermsAndConditionsChangedModal: false,
	settings: {
		account: {
			accountEndingDate: 'MISSING-FROM-API',
			postalCode: 'MISSING-FROM-API',
		},
		languageSettings: {
			culture: locale,
		},
		permissions: null,
		personalInfo: null,
		userSettings: null,
	},
};

export const acceptUpdatedTermsAndConditions = createThunk(
	{
		originType: '@account/acceptUpdatedTermsAndConditions',
		errorMessage: m.termsAndConditionsUpdateError,
	},
	async ({ dispatch }, termsAndConditionsVersion: string) => {
		await dispatch(
			request({
				url: 'account/termsAndConditions/accept',
				method: 'post',
				body: { version: termsAndConditionsVersion },
			})
		);
	}
);

export const selectHasShownTermsAndConditionsChangedModal = state =>
	state.account?.hasShownTermsAndConditionsChangedModal;
export const selectIsAcceptingTermsAndConditions = selectIsThunkPending(
	acceptUpdatedTermsAndConditions
);

const getSlice = path(['account']);

export const selectIsFetchingUserAccount = isFetching(ActionTypes.FETCH_USER_ACCOUNT);
export const selectIsUserAccountInitialized = o(path(['initialized']), getSlice);

export const selectIsChangingPersonalInfo = isFetching(ActionTypes.CHANGE_PERSONAL_INFORMATION);

export const selectIsPasswordChangeNecessary = o(
	path(['settings', 'account', 'needsToChangePassword']),
	getSlice
);

export const selectAreTermsAndConditionsChanged = (state: GlobalState) =>
	state.account?.settings?.account?.termsAndConditionsChanged;

export const getAccount = (state: GlobalState) => state?.account?.settings?.account;
export const getAccountStatus = o(path(['settings', 'account', 'accountStatus']), getSlice);
export const getUser = o(path(['settings', 'account', 'name']), getSlice);
export const getUserType = (state: GlobalState) => state.account?.settings?.account?.userType;
export const getUsername = (state: GlobalState) => state.account?.settings?.account?.userName;
export const getUserPlan = o(path<string>(['settings', 'account', 'plan']), getSlice);
export const getFreeReports = o(path(['settings', 'account', 'freeReports']), getSlice);
export const getSubscriberId = o(path(['settings', 'subscriberInfo', 'subscriberId']), getSlice);
export const getPermissions = o(path(['settings', 'permissions']), getSlice);
export const getUserCulture = o(path(['settings', 'languageSettings', 'culture']), getSlice);

export const getIsFreePlan = (planName: string) =>
	planName === COMPANY_FREE_PLAN_KEY || planName === USER_FREE_PLAN_KEY;

export const selectHasFreePlan = state => {
	const userPlan = getUserPlan(state);

	return userPlan ? getIsFreePlan(userPlan) : true;
};

const fetchMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_USER_ACCOUNT),
	({ dispatch }) =>
		action => {
			dispatch(
				run(
					() => request({ url: '/account' }, { origin: action, ...action.meta }),
					({ payload }, { isError }) => {
						if (isError) {
							return;
						}
						dispatch(setUserAccount(payload));
					}
				)
			);
		}
);

const fetchUserAccountMiddleware = makeMiddleware(
	typeEq(setAccessToken.type),
	({ dispatch }) =>
		action => {
			const inBrowser = isInBrowser();

			if (
				(currentInstanceType === InstanceTypes.INSTANCE_FO || !inBrowser) &&
				isNotNil(action.payload)
			) {
				dispatch(fetchUserAccount());
			}
		}
);

export const changePersonalInformation = createThunk(
	{
		originType: '@account/changePersonalInformation',
		errorMessage: m.changeSettingsFail,
		successMessage: m.changeSettingsSuccess,
	},
	async ({ dispatch }, payload) => {
		await dispatch(request({ url: '/account', method: 'POST', body: payload }));

		dispatch(fetchUserAccount());
		navigate('../settings');
	}
);

const changePersonalInformationMiddleware = makeMiddleware(
	typeEq(ActionTypes.CHANGE_PERSONAL_INFORMATION),
	({ dispatch }) =>
		action => {
			const { form, onSuccess, ...body } = action.payload;

			dispatch(startSubmitForm(form));

			dispatch(
				run(
					() => request({ url: '/account', method: 'POST', body }, { origin: action, form }),
					(action, { isError }) => {
						dispatch(stopSubmitForm(action));

						if (isError) {
							dispatch(
								addToast({
									type: 'warning',
									content: { ...m.changeSettingsFail, defaultMessage: action?.payload },
								})
							);
						} else {
							dispatch(addToast({ content: m.changeSettingsSuccess, type: 'success' }));
							dispatch(reset(form));
							dispatch(fetchUserAccount());

							if (onSuccess) {
								onSuccess();
							}

							navigate('../settings');
						}
					}
				)
			);
		}
);

export const selectIsChangingPersonalInformation = selectIsThunkPending(changePersonalInformation);

interface ChangeNotificationsSettingsPayload {
	form?: string;
	messagingChannel: string;
}

export const changeNotificationsSettings = createThunk(
	{
		originType: '@account/changeNotificationsSettings',
		errorMessage: m.changeSettingsFail,
		successMessage: m.changeSettingsSuccess,
	},
	async ({ dispatch, getState }, payload: ChangeNotificationsSettingsPayload) => {
		const { form, messagingChannel } = payload;
		const accountState = getAccount(getState());
		const body = {
			...accountState,
			messagingChannel,
		};

		await dispatch(request({ url: '/account', method: 'POST', body }, { form }));

		dispatch(fetchUserAccount());
	}
);

export const selectIsChangingNotificationSettings = selectIsThunkPending(
	changeNotificationsSettings
);

export const accountMiddleware = composeMiddleware(
	changePersonalInformationMiddleware,
	fetchMiddleware,
	fetchUserAccountMiddleware
);

export const reducer = createReducer(initialState, builder => {
	builder
		.addCase(setHasShownTermsAndConditionsChangedModal, (state, action) => ({
			...state,
			hasShownTermsAndConditionsChangedModal: action.payload,
		}))
		.addCase(setAccountState, (state, action) => ({
			...state,
			settings: {
				...state.settings,
				account: {
					...state.settings.account,
					needsToChangePassword: action.payload.needsToChangePassword,
				},
			},
		}))
		.addCase(setIntialized, (state, action) => ({
			...state,
			initialized: action.payload,
		}))
		.addCase(setUserAccount, (state, action) =>
			mergeDeepLeft(
				{
					settings: {
						...action.payload.result,
					},
					initialized: true,
				},
				state
			)
		)
		.addCase(logOut.originType, () => initialState);
});
