import React, { ReactElement, ReactNode, forwardRef } from 'react';
import { InputWrapper } from '@ci/atoms';
import { FormField } from '@myci/ui-components';

import { FileInputControl, FileInputMetadataControl } from '../FileInputControl';
import './FileInput.scss';
import { FileInputInfo } from './FileInputInfo';

interface CompatibilityFileInputProps {
	/** @deprecated Use `isDisabled` instead. */
	disabled?: boolean;
	required?: boolean;
}

export interface FileInputProps extends CompatibilityFileInputProps {
	acceptedExtensions?: string[];
	defaultFileName?: string;
	description?: ReactElement;
	error: ReactNode;
	hasFileMetadata: boolean;
	isDisabled?: boolean;
	isRequired?: boolean;
	label?: ReactElement;
	name?: string;
	onBlur?: any;
	onChange?: (...event: any) => void;
	value: string;
}

export const FileInput = forwardRef((props: FileInputProps, ref) => {
	const {
		hasFileMetadata,
		isDisabled: isDisabledProp,
		disabled,
		isRequired: isRequiredProp,
		required,
		error,
		label,
		description,
		...otherProps
	} = props;

	const isRequired = isRequiredProp ?? required;
	const isDisabled = isDisabledProp ?? disabled;

	return (
		<InputWrapper error={error} isRequired={isRequired} isDisabled={isDisabled} label={label}>
			<FormField
				ref={ref}
				control={hasFileMetadata ? FileInputMetadataControl : FileInputControl}
				kind="text-input"
				autoComplete="off"
				disabled={isDisabled}
				isRequired={isRequired}
				{...otherProps}
			/>
			{description && <FileInputInfo content={description} />}
		</InputWrapper>
	);
});

FileInput.displayName = 'forwardRef(FileInput)';
