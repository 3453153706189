export const getScrollbarWidth = (): number => {
	if (typeof document === 'undefined') {
		return 0;
	}

	const outerElement = document.createElement('div');
	outerElement.style.visibility = 'hidden';
	outerElement.style.overflow = 'scroll';
	document.body.appendChild(outerElement);

	const innerElement = document.createElement('div');
	outerElement.appendChild(innerElement);

	const scrollbarWidth = outerElement.offsetWidth - innerElement.offsetWidth;

	outerElement.parentNode?.removeChild(outerElement);

	return scrollbarWidth;
};
