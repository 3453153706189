import { decapitalizeFirstWithDot } from '@ci/utils';
import { compose, fromPairs, head, partition, replace } from 'ramda';
import { isString } from 'ramda-extension';
import { errorSeverities } from './constants';
import m from './messages';

/**
 * MyCI API does not send error messages in standardized way. Should be discussed with BE devs, then data could be removed.
 * @param response
 * @returns {*}
 */
export const getErrorMessageFromResponse = response => {
	const { error, errors, error_description: errorDescription, data } = response;

	const errorMessage =
		(error && error.messageLocalized) ||
		errorDescription ||
		(errors ? head(errors)?.message : undefined) ||
		(isString(data) ? data : undefined);

	return errorMessage;
};

const prepareFieldName = compose(replace(']', ''), replace('[', '.'), decapitalizeFirstWithDot);

export const getApiValidationErrors = response => {
	const validationErrors = response.meta?.errors?.filter(
		error => error.severity === errorSeverities.validationError
	);
	if (!validationErrors) return {};

	const [fieldErrors, formErrors] = partition(error => Boolean(error?.fieldName), validationErrors);

	return {
		formErrors: formErrors.map(({ code, parameters }) => ({
			message: code
				? {
						id: `errorMessage.${code}`,
						defaultMessage: `Form validation error (${code})`,
					}
				: m.apiError,
			options: parameters,
		})),
		fieldErrors: fromPairs(
			fieldErrors.map(({ code, fieldName, message, parameters }) => [
				prepareFieldName(fieldName),
				{
					message: code
						? {
								id: `errorMessage.${code}`,
								defaultMessage: `Field validation error (${code})`,
							}
						: message,
					options: parameters,
					value: parameters?.PropertyValue,
				},
			])
		),
	};
};
