import { addToast } from '@ci/toasts';
import { generateUuid } from '@ci/utils';
import { MessageDescriptor } from 'react-intl';
import { both, isNil, o, useWith, when } from 'ramda';
import { defaultLogger } from '@ci/logger';

import { addPendingThunk, removePendingThunk } from './pendingThunks';
import { RequestAction } from '../../actions';
import {
	getIsAnyErrorResponseAction,
	getIsAnyRequestAction,
	setOrigin,
	getOrigin,
} from '../../utils';
import {
	OriginType,
	Thunk,
	ThunkApi,
	ThunkBaseApi,
	ThunkFactory,
	ThunkFactoryFunction,
} from './types';

interface ThunkOptions {
	errorMessage?: MessageDescriptor | ((error: unknown) => MessageDescriptor | null);
	originType: OriginType;
	successMessage?: MessageDescriptor;
}

declare module 'redux' {
	export interface Dispatch {
		<TThunk extends Thunk<any>>(thunk: TThunk): ReturnType<TThunk>;
	}
}

export const createThunk = <TPayload = void, TReturnValue = void>(
	originTypeOrThunkOptions: OriginType | ThunkOptions,
	handler: (thunkApi: ThunkApi, payload: TPayload) => Promise<TReturnValue>
): ThunkFactory<TPayload, TReturnValue> => {
	const thunkOptions: ThunkOptions =
		typeof originTypeOrThunkOptions === 'string'
			? { originType: originTypeOrThunkOptions }
			: originTypeOrThunkOptions;

	const { originType } = thunkOptions;

	const makeThunkFactory =
		(shouldRethrow: boolean) =>
		(...params: TPayload extends void ? [] : [payload: TPayload]) => {
			const [payload] = params;

			return async ({ dispatch, logger = defaultLogger, ...thunkApi }: ThunkBaseApi | ThunkApi) => {
				// NOTE: When `meta` is a part of `thunkApi`, we are dealing with `ThunkApi` instead of
				// `ThunkBaseApi`. This means metadata were injected by the producer, e.g. thunk polling.
				const meta = 'meta' in thunkApi ? thunkApi.meta : {};

				const nextThunkApi: ThunkApi = {
					// NOTE: Simple mechanism to avoid the need to pass `meta.origin` manually.
					// If the argument to dispatch is a request action and it has no origin, set it.
					dispatch: useWith(dispatch, [
						when<any, RequestAction>(
							both(getIsAnyRequestAction, o(isNil, getOrigin)),
							setOrigin({ type: originType, payload })
						),
					]),
					logger,
					meta,
					...thunkApi,
				};

				const thunkId = generateUuid();

				// NOTE: This is here only for better Redux DevTools tracking.
				dispatch({ type: originType, payload, meta: { isThunk: true, ...meta } });

				dispatch(addPendingThunk({ originType, thunkId, payload, meta }));

				try {
					const returnValue = await handler(nextThunkApi, payload!);

					if (thunkOptions.successMessage) {
						dispatch(addToast({ content: thunkOptions.successMessage, type: 'success' }));
					}

					return returnValue;
				} catch (error) {
					// NOTE: When the response action has generic error handling, it means a global handler
					// is responsible for handling this particular error. For example, when an unexpected
					// error occurs (500), a toast notification is shown by default -- we needn't show
					// a module-specific one (in `createThunk`).
					const hasGenericErrorHandling =
						getIsAnyErrorResponseAction(error) && Boolean(error.meta.hasGenericErrorHandling);

					const errorMessage =
						!hasGenericErrorHandling &&
						(typeof thunkOptions.errorMessage === 'function'
							? thunkOptions.errorMessage(error)
							: thunkOptions.errorMessage);

					if (errorMessage) {
						dispatch(addToast({ content: errorMessage, type: 'danger' }));
					}

					if (shouldRethrow) {
						throw error;
					} else {
						logger.error(error);
					}
				} finally {
					dispatch(removePendingThunk({ originType, thunkId }));
				}
			};
		};

	const thunkFactory = makeThunkFactory(false);

	return Object.assign(thunkFactory, {
		dangerously: makeThunkFactory(true) as ThunkFactoryFunction<TPayload, TReturnValue>,
		originType,
	});
};
