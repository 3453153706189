import { ComponentType, ElementRef, forwardRef } from 'react';
import { getDisplayName } from './getDisplayName';

export const withKey =
	<TProps extends object>(getKey: (props: TProps) => string) =>
	<TComponent extends ComponentType<TProps>>(Component: TComponent) => {
		type WithKeyProps = JSX.LibraryManagedAttributes<TComponent, TProps>;

		const WithKey = forwardRef<ElementRef<TComponent>, WithKeyProps>((props, ref) => (
			<Component {...props} key={getKey(props)} ref={ref} />
		));

		WithKey.displayName = `withKey(${getDisplayName(Component)})`;

		return WithKey;
	};
