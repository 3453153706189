import { MaskOptions } from '@creditinfo-ui/atoms';
import { makeFormatter } from 'afformative';
import { createMask, pipe } from 'imask';
import { useMemo } from 'react';

export const createMaskFormatter = (maskOptions: MaskOptions) => {
	const masked = createMask(maskOptions);

	return makeFormatter<string, string>(value => pipe(value, masked));
};

export const useMaskFormatter = (maskOptions: MaskOptions) =>
	useMemo(() => createMaskFormatter(maskOptions), [maskOptions]);
