import {
	InstanceTypes,
	currentInstanceType,
	downloadBase64File,
	getCurrentInstanceEnv,
	makeMiddleware,
} from '@myci/utils';
import { forEach, pick } from 'ramda';
import { getIsAnyResponseAction, makeApiMiddleware } from '@ci/api';
import { addToast } from '@ci/toasts';
import { getErrorMessageFromResponse } from './utils';
import { errorSeverities } from './constants';
import m from './messages';

const { baseURL, applicationKey } = getCurrentInstanceEnv();
const isBoInstance = currentInstanceType === InstanceTypes.INSTANCE_BO;

export const apiMiddleware = makeApiMiddleware({
	baseUrl: baseURL,
	getDownloadedChunkResponseData: response =>
		isBoInstance ? response.data : response.result.data.data.data,
	getParsedErrorPayload: response => {
		const errorMessage = getErrorMessageFromResponse(response);

		return errorMessage;
	},
	getParsedResponseMeta: response => {
		const errorMessage = getErrorMessageFromResponse(response);
		const responseMetadata = pick(['error', 'status', 'requestId'])(response);

		return {
			...responseMetadata,
			errors: response.errors,
			errorMessage,
		};
	},
	getHasGenericErrorHandling: response => Boolean(response.errors),
	selectHeaders: () => ({
		'X-Application-Key': applicationKey,
	}),
	downloadBase64File,
});

const toastTypesBySeverity = {
	[errorSeverities.unexpectedError]: 'danger',
	[errorSeverities.applicationError]: 'danger',
	[errorSeverities.warning]: 'warning',
};

const messagesBySeverity = {
	[errorSeverities.unexpectedError]: m.apiError,
	[errorSeverities.applicationError]: m.apiError,
	[errorSeverities.warning]: m.apiWarning,
};

export interface ApiError {
	code?: string;
	fieldName?: string;
	message: string;
	parameters?: Record<string, string | number>;
	severity: string;
}

export const apiErrorToastMiddleware = makeMiddleware(
	getIsAnyResponseAction,
	({ dispatch }) =>
		action => {
			const { errors, requestId, shouldSuppressErrorToast } = action.meta;

			if (!shouldSuppressErrorToast && errors) {
				forEach((error: ApiError) => {
					const genericMessage = messagesBySeverity[error.severity];
					const toastType = toastTypesBySeverity[error.severity];

					if (toastType) {
						const message = error.code
							? {
									id: `errorMessage.${error.code}`,
									defaultMessage: genericMessage.defaultMessage,
								}
							: genericMessage;

						dispatch(
							addToast({
								type: toastType,
								content: message,
								values: {
									requestId: String(requestId),
									...error.parameters,
								},
							})
						);
					}
				}, errors);
			}
		}
);
