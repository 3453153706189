import { createThunk, request, selectIsThunkPending } from '@ci/api';
import { InstanceTypes, currentInstanceType } from '@myci/utils';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { getLocale } from '@myci/intl';

import m from './messages';

export interface IdentifierDetail {
	allowedCountries: Array<{ key: string; value: string }>;
	name: string;
	requiredProperties: string[];
}

interface PrivacyPolicy {
	data: string;
	locale: string;
}

interface TermsAndConditions {
	data: string;
	locale: string;
	version: string;
}

export type UserType = number;

type IdentifierDetails = Record<UserType, IdentifierDetail[] | null>;

type DisputeReasonsType =
	| 'bouncedcheque'
	| 'contract'
	| 'debit'
	| 'telcopostpaid'
	| 'telcoprepaid'
	| 'utilities';

interface DisputeReason {
	key: DisputeReasonsType;
	value: string[];
}

export type DisputeReasons = DisputeReason[];

export interface City {
	code: string;
	name: string;
}

export interface Language {
	languageCode: string;
	languageId?: number;
	languageName: string;
	localName: string;
}

export interface InstanceConfig {
	baseUrlUiFo: string;
	enableCaptchaValidation: boolean;
	enableMessenger: boolean;
	supportedPdfReportLanguages: Language[];
	supportedUiLanguages: Language[];
}

export interface MobileConfig {
	shouldBlockPaymentAndroid: boolean;
	shouldBlockPaymentIos: boolean;
}

export interface AdministrativeUnit {
	code: string;
	names: Record<string, string>;
	subdivisions: AdministrativeUnit[];
	type: string;
}

type CountrySubdivisions = Record<UserType, AdministrativeUnit[] | null>;

export interface DataApiState {
	apiVersion: string;
	cities: City[] | null;
	countrySubdivisions: CountrySubdivisions;
	disputeReasons: DisputeReasons | null;
	identifierDetails: IdentifierDetails;
	instanceConfig: InstanceConfig | null;
	isApiReachable: boolean;
	messagingChannels: MessagingChannel[] | null;
	minAndroidVersion: string;
	minIosVersion: string;
	mobileConfig: MobileConfig | null;
	privacyPolicy: PrivacyPolicy | null;
	termsAndConditions: TermsAndConditions | null;
}

interface GlobalState {
	dataApi: DataApiState;
}

interface VersionData {
	buildVersion: string;
	minAndroidVersion: string;
	minIosVersion: string;
}

export interface MessagingChannel {
	enabled: boolean;
	name: string;
}

const storeVersions = createAction<VersionData>('@dataApi/storeVersions');
const storeIsApiReachable = createAction<boolean>('@dataApi/storeIsApiReachable');
const storePrivacyPolicy = createAction<PrivacyPolicy>('@dataApi/storePrivacyPolicy');
const storeTermsAndConditions = createAction<TermsAndConditions>(
	'@dataApi/storeTermsAndConditions'
);
const storeDisputeReasons = createAction<DisputeReasons>('@dataApi/storeDisputeReasons');
const storeIdentifierDetails = createAction<IdentifierDetails>('@dataApi/storeIdentifierDetails');
const storeMessagingChannels = createAction<MessagingChannel[]>('@dataApi/storeMessagingChannels');
const storeCities = createAction<City[]>('@dataApi/storeCities');
const storeCountrySubdivisions = createAction<CountrySubdivisions>(
	'@dataApi/storeCountrySubdivisions'
);
const storeInstanceConfig = createAction<InstanceConfig>('@dataApi/storeInstanceConfig');
const storeMobileConfig = createAction<MobileConfig>('@dataApi/storeMobileConfig');

export const selectApiVersion = (state: GlobalState) => state.dataApi.apiVersion;
export const selectIsApiReachable = (state: GlobalState) => state.dataApi.isApiReachable;
export const selectMinAndroidVersion = (state: GlobalState) => state.dataApi.minAndroidVersion;
export const selectMinIosVersion = (state: GlobalState) => state.dataApi.minIosVersion;
export const selectPrivacyPolicy = (state: GlobalState) => state.dataApi.privacyPolicy;
export const selectTermsAndConditions = (state: GlobalState) => state.dataApi.termsAndConditions;
export const selectDisputeReasons = (state: GlobalState) => state.dataApi.disputeReasons;
export const selectMessagingChannels = (state: GlobalState) => state.dataApi.messagingChannels;
export const selectAllIdentifierDetails = (state: GlobalState) => state.dataApi.identifierDetails;
export const makeSelectIdentifierDetailsByUserType = (userType: UserType) => (state: GlobalState) =>
	selectAllIdentifierDetails(state)[userType];
export const selectCities = (state: GlobalState) => state.dataApi.cities;
export const selectAllCountrySubdivisions = (state: GlobalState) =>
	state.dataApi.countrySubdivisions;
export const makeSelectCountrySubdivisionsByUserType =
	(userType: UserType) => (state: GlobalState) =>
		selectAllCountrySubdivisions(state)[userType];
export const selectInstanceConfig = (state: GlobalState) => state.dataApi.instanceConfig;
export const selectFoBaseUrl = (state: GlobalState) => state.dataApi.instanceConfig?.baseUrlUiFo;
export const selectMobileConfig = (state: GlobalState) => state.dataApi.mobileConfig;

const initialState: DataApiState = {
	apiVersion: '',
	cities: null,
	countrySubdivisions: {},
	disputeReasons: null,
	instanceConfig: null,
	isApiReachable: false,
	minAndroidVersion: '',
	minIosVersion: '',
	mobileConfig: null,
	messagingChannels: null,
	identifierDetails: {},
	privacyPolicy: null,
	termsAndConditions: null,
};

const isBoInstance = currentInstanceType === InstanceTypes.INSTANCE_BO;

export const fetchVersions = createThunk(
	{ originType: '@dataApi/fetchVersions', errorMessage: m.apiVersionError },
	async ({ dispatch }) => {
		const successAction = await dispatch(
			request({
				url: '/dataApi/version',
				method: 'GET',
			})
		);
		dispatch(
			storeVersions(isBoInstance ? successAction.payload : successAction.payload.result.data)
		);
	}
);

export const fetchInstanceConfiguration = createThunk(
	{ originType: '@dataApi/fetchInstanceConfiguration', errorMessage: m.instanceConfigurationError },
	async ({ dispatch }) => {
		const successAction = await dispatch(
			request({
				url: '/dataApi/instanceConfiguration',
				method: 'GET',
			})
		);

		dispatch(
			storeInstanceConfig(isBoInstance ? successAction.payload : successAction.payload.result.data)
		);
	}
);

export const fetchIsApiReachable = createThunk(
	'@dataApi/fetchIsApiReachable',
	async ({ dispatch }) => {
		try {
			await dispatch(
				request({
					url: '/dataApi/version',
					method: 'GET',
				})
			);
			dispatch(storeIsApiReachable(true));
		} catch {
			dispatch(storeIsApiReachable(false));
		}
	}
);
export const selectIsFetchingApiReachable = selectIsThunkPending(fetchIsApiReachable);

export const fetchPrivacyPolicy = createThunk(
	{ originType: '@dataApi/fetchPrivacyPolicy', errorMessage: m.privacyPolicyError },
	async ({ dispatch, getState }) => {
		const languageCode = getLocale(getState());
		const successAction = await dispatch(
			request(
				{
					url: `/dataApi/PrivacyPolicy/${languageCode}`,
					method: 'GET',
				},
				{}
			)
		);
		const {
			data: { value },
		} = successAction.payload.result;

		dispatch(
			storePrivacyPolicy({
				data: value,
				locale: languageCode,
			})
		);
	}
);
export const selectIsFetchingPrivacyPolicy = selectIsThunkPending(fetchPrivacyPolicy);

export const fetchTermsAndConditions = createThunk(
	{ originType: '@dataApi/fetchTermsAndConditions', errorMessage: m.termsAndConditionsError },
	async ({ dispatch, getState }, userType: string) => {
		const languageCode = getLocale(getState());
		const successAction = await dispatch(
			request(
				{
					url: `/dataApi/TermsAndConditions/${userType}/${languageCode}`,
					method: 'GET',
				},
				{}
			)
		);
		const {
			data: { key, value },
		} = successAction.payload.result;

		dispatch(
			storeTermsAndConditions({
				data: value,
				version: key,
				locale: languageCode,
			})
		);
	}
);
export const selectIsFetchingTermsAndConditions = selectIsThunkPending(fetchTermsAndConditions);

export const fetchDisputeReasons = createThunk(
	{ originType: '@dataApi/fetchDisputeReasons', errorMessage: m.disputeReasonError },
	async ({ dispatch }) => {
		const successAction = await dispatch(
			request(
				{
					url: `/dataApi/DisputeSettings`,
					method: 'GET',
				},
				{}
			)
		);
		const { data } = successAction.payload.result;

		dispatch(storeDisputeReasons(data));
	}
);
export const selectIsFetchingDisputeReasons = selectIsThunkPending(fetchDisputeReasons);

export const fetchIdentifierDetails = createThunk(
	{ originType: '@dataApi/fetchIdentifierDetails', errorMessage: m.idNumberConfigError },
	async ({ dispatch }, userType: UserType) => {
		const successAction = await dispatch(
			request({
				url: `/dataApi/identifierDetails/${userType}`,
				method: 'GET',
			})
		);
		dispatch(
			storeIdentifierDetails({
				[userType]: isBoInstance ? successAction.payload : successAction.payload.result.data,
			})
		);
	}
);

export const fetchCountrySubdivisions = createThunk(
	{ originType: '@dataApi/fetchCountrySubdivisions', errorMessage: m.countrySubdivisionsError },
	async ({ dispatch }, userType: UserType) => {
		const successAction = await dispatch(
			request({
				url: `/dataApi/countrySubdivisions/${userType}`,
				method: 'GET',
			})
		);
		dispatch(
			storeCountrySubdivisions({
				[userType]: isBoInstance ? successAction.payload : successAction.payload.result.data,
			})
		);
	}
);

export const selectIsFetchingCountrySubdivisions = selectIsThunkPending(fetchCountrySubdivisions);

export const fetchMessagingChannels = createThunk(
	{
		originType: '@dataApi/fetchMessagingChannels',
	},
	async ({ dispatch }) => {
		const successAction = await dispatch(request({ url: '/dataApi/messagingChannels' }));
		const { data } = successAction.payload.result;
		dispatch(storeMessagingChannels(data));
	}
);

export const selectIsFetchingMessagingChannels = selectIsThunkPending(fetchMessagingChannels);

export const fetchCities = createThunk(
	{
		originType: '@dataApi/fetchCities',
		errorMessage: m.citiesError,
	},
	async ({ dispatch }) => {
		const successAction = await dispatch(
			request({
				url: '/dataApi/cities',
				method: 'GET',
			})
		);
		dispatch(storeCities(isBoInstance ? successAction.payload : successAction.payload.result.data));
	}
);
export const selectIsFetchingCities = selectIsThunkPending(fetchCities);

export const fetchMobileConfig = createThunk(
	{
		originType: '@dataApi/fetchMobileConfig',
		errorMessage: m.mobileConfigError,
	},
	async ({ dispatch }) => {
		const successAction = await dispatch(
			request({
				url: '/dataApi/mobileConfiguration',
				method: 'GET',
			})
		);
		dispatch(storeMobileConfig(successAction.payload.result.data));
		return successAction.payload.result.data;
	}
);
export const selectIsFetchingMobileConfig = selectIsThunkPending(fetchMobileConfig);

export const reducer = createReducer(initialState, builder => {
	builder
		.addCase(storeVersions, (state, action) => ({
			...state,
			apiVersion: action.payload.buildVersion,
			minAndroidVersion: action.payload.minAndroidVersion,
			minIosVersion: action.payload.minIosVersion,
		}))
		.addCase(storeIsApiReachable, (state, action) => ({
			...state,
			isApiReachable: action.payload,
		}))
		.addCase(storePrivacyPolicy, (state, action) => ({
			...state,
			privacyPolicy: action.payload,
		}))
		.addCase(storeTermsAndConditions, (state, action) => ({
			...state,
			termsAndConditions: action.payload,
		}))
		.addCase(storeDisputeReasons, (state, action) => ({
			...state,
			disputeReasons: action.payload,
		}))
		.addCase(storeMessagingChannels, (state, action) => ({
			...state,
			messagingChannels: action.payload,
		}))
		.addCase(storeCities, (state, action) => ({
			...state,
			cities: action.payload,
		}))
		.addCase(storeCountrySubdivisions, (state, action) => ({
			...state,
			countrySubdivisions: {
				...state.countrySubdivisions,
				...action.payload,
			},
		}))
		.addCase(storeInstanceConfig, (state, action) => ({
			...state,
			instanceConfig: { ...action.payload },
		}))
		.addCase(storeMobileConfig, (state, action) => ({
			...state,
			mobileConfig: { ...action.payload },
		}))
		.addCase(storeIdentifierDetails, (state, action) => ({
			...state,
			identifierDetails: {
				...state.identifierDetails,
				...action.payload,
			},
		}));
});
