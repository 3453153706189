import { makeActionTypes, makeSimpleActionCreator } from 'redux-syringe';
import { makeBinaryActionCreator } from '@ci/utils';
import { Action, AnyAction } from 'redux';

export type QueryParams = Record<string, string | number | boolean>;

export const ACTION_PREFIX = '@api';

export const ActionTypes = makeActionTypes(ACTION_PREFIX, [
	'REQUEST',
	'SUCCESS',
	'ERROR',
	'RETRYING',
	'ADD_PENDING_REQUEST',
	'REMOVE_PENDING_REQUEST',
]);

export const errorEvent = makeBinaryActionCreator(ActionTypes.ERROR);
export const retryingEvent = makeBinaryActionCreator(ActionTypes.RETRYING);

// TODO: Make `successEvent` use `SuccessEventAction` type.
// TODO: Unify naming between `SuccessResponseAction` and `SuccessEventAction`.
export const successEvent = makeBinaryActionCreator(ActionTypes.SUCCESS);

export const addPendingRequest = makeSimpleActionCreator(ActionTypes.ADD_PENDING_REQUEST);
export const removePendingRequest = makeSimpleActionCreator(ActionTypes.REMOVE_PENDING_REQUEST);

export interface SuccessEventAction<TPayload = any, TOrigin extends Action = AnyAction> {
	meta: {
		[meta: string]: any;
		origin: TOrigin;
	};
	payload: TPayload;
	type: typeof ActionTypes.SUCCESS;
}

export interface ErrorEventAction<TPayload = any, TOrigin extends Action = AnyAction> {
	meta: {
		[meta: string]: any;
		origin: TOrigin;
	};
	payload: TPayload;
	type: typeof ActionTypes.ERROR;
}

export type ResponseEventAction<
	TSuccessPayload = any,
	TErrorPayload = any,
	TOrigin extends Action = AnyAction,
> = SuccessEventAction<TSuccessPayload, TOrigin> | ErrorEventAction<TErrorPayload, TOrigin>;

export interface FilteringEntry {
	name: string;
	operator: string;
	value: any;
}

export interface RequestPayload<TRequestBody = any> {
	baseUrl?: string;
	body?: TRequestBody;
	csvColumns?: string[];
	filtering?: FilteringEntry[];
	grid?: string;
	headers?: Record<string, string>;
	isCsv?: boolean;
	method?: string;
	pagination?: {
		pageNumber?: number;
		pageSize?: number;
	};
	queryParams?: QueryParams;
	retryInterval?: number;
	retryTimes?: number;
	sorting?: {
		sortBy?: string;
		sortDirection?: string;
	};
	url: string;
}

export interface RequestMeta<TOrigin extends Action = AnyAction> {
	[metaProp: string]: any;
	origin?: TOrigin;
}

export interface RequestAction<
	// NOTE: `TResponseBody` is used for `dispatch` overloading (promise-like requests).
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	TResponseBody = any,
	TRequestBody = any,
	TOrigin extends Action = AnyAction,
> {
	meta?: RequestMeta<TOrigin>;
	payload: RequestPayload<TRequestBody>;
	type: string;
}

export const request = <
	TResponseBody = any,
	TRequestBody = any,
	TOrigin extends Action = AnyAction,
>(
	payload: RequestPayload<TRequestBody>,
	meta?: RequestMeta<TOrigin>
): RequestAction<TResponseBody, TRequestBody, TOrigin> => ({
	type: ActionTypes.REQUEST,
	payload,
	meta,
});
