import { prepareStyleFactory } from '@creditinfo-ui/styles';
import { Icon, IconType } from '@creditinfo-ui/atoms';

const STEP_ICON_SIZE = '40px';

interface StepIconStyleProps {
	isActive: boolean;
	isDone: boolean;
}

const stepIconStyle = prepareStyleFactory<StepIconStyleProps>((utils, { isActive, isDone }) => ({
	alignItems: 'center',
	backgroundColor: utils.colors.gray400,
	borderRadius: STEP_ICON_SIZE,
	color: utils.colors.white,
	height: STEP_ICON_SIZE,
	justifyContent: 'center',
	marginInlineEnd: utils.spacings.md,
	width: STEP_ICON_SIZE,
	extend: [
		{
			condition: isDone,
			style: {
				backgroundColor: utils.colors.black,
			},
		},
		{
			condition: isActive,
			style: {
				backgroundColor: utils.colors.primary,
			},
		},
	],
}));

export interface StepperIconProps {
	activeIndex: number;
	index: number;
	isActive: boolean;
	type: IconType;
}

export const StepperIcon = ({ activeIndex, index, isActive, type }: StepperIconProps) => {
	const isDone = index < activeIndex;

	return (
		<Icon
			type={type}
			size="lg"
			color="white"
			customStyle={stepIconStyle({ isActive, isDone })}
			className="StepperIcon"
		/>
	);
};
