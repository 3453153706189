import { ReactNode } from 'react';
import { Icon, IconProps, IconType } from '@ci/atoms';
import { mergeStyles, prepareStyle, useStyles } from '@ci/styles';
import { CollapsibleTitle, CollapsibleTitleProps } from './CollapsibleTitle';

const collapsibleStatusWrapperStyle = prepareStyle(utils => ({
	display: 'flex',
	flexDirection: 'column',
	gap: utils.spacings.xs,
	justifyContent: 'space-between',
	width: '100%',
	selectors: {
		[utils.breakpoints.lg]: {
			alignItems: 'center',
			flexDirection: 'row',
		},
	},
}));

const collapsibleIndicatorWrapperStyle = prepareStyle(utils => ({
	selectors: {
		[utils.breakpoints.md]: {
			alignItems: 'center',
			display: 'flex',
			flexWrap: 'wrap',
		},
	},
}));

const collapsibleStatusIconCustomStyle = prepareStyle(utils => ({
	marginInlineEnd: 0,
	marginInlineStart: utils.spacings.sm,
	selectors: {
		[utils.breakpoints.lg]: {
			marginInlineEnd: utils.spacings.sm,
			marginInlineStart: utils.spacings.md,
		},
	},
}));

export interface CollapsibleIndicatorStatusProps {
	icon: IconType;
	iconProps?: Partial<IconProps>;
	label: ReactNode;
	title?: ReactNode;
	titleProps?: Omit<CollapsibleTitleProps, 'title'>;
}

export const CollapsibleIndicatorStatus = ({
	icon,
	iconProps,
	label,
	title,
	titleProps,
}: CollapsibleIndicatorStatusProps) => {
	const { applyStyle } = useStyles();

	return (
		<div className={applyStyle(collapsibleStatusWrapperStyle)}>
			{title && <CollapsibleTitle {...titleProps} title={title} />}
			<div className={applyStyle(collapsibleIndicatorWrapperStyle)}>
				{label}
				<Icon
					type={icon}
					size="sm"
					{...iconProps}
					customStyle={mergeStyles([collapsibleStatusIconCustomStyle, iconProps?.customStyle])}
				/>
			</div>
		</div>
	);
};
