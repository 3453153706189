import { Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { cx } from 'ramda-extension';
import { HTMLAttributes, createElement, forwardRef } from 'react';
import type * as CSS from 'csstype';
import { OpenDirection, Tag } from '../types';

interface MenuStyleProps {
	direction: OpenDirection;
	hasPositionAbsolute: boolean;
	hasShadow: boolean;
	isEmbedded: boolean;
	maxHeight: CSS.Property.MaxHeight;
}

const menuStyle = prepareStyle<MenuStyleProps>(
	(utils, { direction, hasPositionAbsolute, hasShadow, isEmbedded, maxHeight }) => ({
		backgroundColor: 'white',
		borderRadius: utils.borders.radii.basic,
		borderTop: 'none',
		boxShadow: hasShadow ? utils.boxShadows.menu : 'none',
		color: utils.colors.gray700,
		listStyleType: 'none',
		margin: 0,
		maxHeight,
		outline: 0,
		overflowX: 'hidden',
		overflowY: 'auto',
		padding: 0,
		position: 'relative',
		width: '100%',
		zIndex: utils.zIndices.menu,
		selectors: {
			':empty': {
				// NOTE: Safeguard against rendering an empty menu in IE
				display: 'none',
			},
		},
		extend: [
			{
				condition: isEmbedded,
				// NOTE: This margin/padding is used to have the menu positioned as if it was
				// coming from under a button, when used e.g. in a dropdown.
				style:
					direction === 'down'
						? {
								borderRadius: `0 0 ${utils.borders.radii.basic} ${utils.borders.radii.basic}`,
								margin: `-${utils.borders.radii.basic} 0 0`,
								padding: `${utils.borders.radii.basic} 0 0`,
							}
						: {
								borderRadius: `${utils.borders.radii.basic} ${utils.borders.radii.basic} 0 0`,
								margin: `0 0 -${utils.borders.radii.basic}`,
								padding: `0 0 ${utils.borders.radii.basic}`,
							},
			},
			{
				condition: hasPositionAbsolute,
				style: {
					insetInlineStart: 0,
					position: 'absolute',
					extend: {
						condition: direction === 'up',
						style: {
							bottom: '100%',
						},
					},
				},
			},
		],
	})
);

export interface MenuProps extends HTMLAttributes<HTMLUListElement> {
	customStyle?: Style<MenuStyleProps>;
	direction?: OpenDirection;
	hasPositionAbsolute?: boolean;
	hasShadow?: boolean;
	isEmbedded?: boolean;
	maxHeight?: CSS.Property.MaxHeight;
	tag?: Tag;
	testId?: string;
}

export const Menu = forwardRef<HTMLUListElement, MenuProps>(
	(
		{
			className,
			customStyle,
			direction = 'down',
			hasPositionAbsolute = false,
			hasShadow = false,
			isEmbedded = false,
			maxHeight = '300px',
			tag = 'ul',
			testId,
			...otherProps
		},
		ref
	) => {
		const { applyStyle } = useStyles();

		return createElement(tag, {
			className: cx(
				applyStyle([menuStyle, customStyle], {
					direction,
					hasPositionAbsolute,
					hasShadow,
					isEmbedded,
					maxHeight,
				}),
				className
			),
			'data-testid': testId,
			ref,
			...otherProps,
		});
	}
);

Menu.displayName = 'Menu';
