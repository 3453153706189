import {
	ErrorEventAction,
	createThunk,
	getIsSuccessResponseAction,
	isFetching,
	originTypeEq,
	request,
	selectIsThunkPending,
} from '@ci/api';
import { ApiError } from '@myci/api';
import { sleep } from '@ci/utils';
import { getEntity, storeEntity } from '@ci/entities';
import { schema } from 'normalizr';
import { T, always, any, anyPass, assocPath, cond, equals, isEmpty, o, prop } from 'ramda';
import { run } from '@ci/control-flow';
import { addToast } from '@ci/toasts';
import { unregisterPushNotifications } from '@myci/push-notifications';
import { reset } from 'redux-form';
import { gatsbyNavigate } from '@myci/navigation';
import { makeActionTypes, makeSimpleActionCreator } from 'redux-syringe';
import { createAction, createReducer } from '@reduxjs/toolkit';
import {
	InstanceTypes,
	composeMiddleware,
	currentInstanceType,
	getBrowserQueryParam,
	getEnvironmentVariable,
	isInBrowser as getIsInBrowser,
	makeMiddleware,
	setBrowserLocation,
	typeEq,
} from '@myci/utils';
import { Storage, clearState, getInitialState, storageKeys } from '@myci/storage';
import {
	configureBiometricAuthentication,
	requestBiometricAuthenticationSetup,
} from '@myci/native-biometrics/setup';

import m from './messages';

export interface AuthenticationState {
	accessToken: string | null;
	didLogOut: boolean;
	refreshToken: string | null;
}

interface GlobalState {
	authentication: AuthenticationState;
}

const resetPasswordTokenSchema = new schema.Entity(
	'resetPasswordToken',
	{},
	{ idAttribute: prop('token') }
);

export const ActionTypes = makeActionTypes('@authentication', [
	'FETCH_REFRESH_TOKEN',
	'FETCH_ACCESS_TOKEN',
	'FETCH_RESET_PASSWORD_TOKEN',
	'RESET_PASSWORD',
	'CHANGE_PASSWORD',
]);

export const selectResetPasswordTokenEntity = getEntity(resetPasswordTokenSchema.key);
export const fetchRefreshToken = makeSimpleActionCreator(ActionTypes.FETCH_REFRESH_TOKEN);
export const fetchAccessToken = makeSimpleActionCreator(ActionTypes.FETCH_ACCESS_TOKEN);
export const fetchResetPasswordToken = makeSimpleActionCreator(
	ActionTypes.FETCH_RESET_PASSWORD_TOKEN
);

export const setAccessToken = createAction<string | null>('@authentication/setAccessToken');
export const setRefreshToken = createAction<string | null>('@authentication/setRefreshToken');
export const setDidLogOut = createAction<boolean>('@authentication/setDidLogOut');

export const initialState = {
	...getInitialState(storageKeys.ACCESS_TOKEN_KEY, storageKeys.REFRESH_TOKEN_KEY),
	didLogOut: false,
};

const getSlice = (state: GlobalState) => state?.authentication;

type FetchingSelector = (state: GlobalState) => boolean;

const selectIsFetchingAccessToken: FetchingSelector = isFetching(ActionTypes.FETCH_ACCESS_TOKEN);
const selectIsFetchingRefreshToken: FetchingSelector = isFetching(ActionTypes.FETCH_REFRESH_TOKEN);

export const selectIsResettingPassword = isFetching(ActionTypes.RESET_PASSWORD);
export const selectIsFetchingResetPasswordToken = isFetching(
	ActionTypes.FETCH_RESET_PASSWORD_TOKEN
);

export const attachAccessToken = (token, action) =>
	assocPath(['payload', 'headers', 'Authorization'], `Bearer ${token}`, action);

const getIsAccessTokenRequest = originTypeEq(ActionTypes.FETCH_ACCESS_TOKEN);
const getIsRefreshTokenRequest = originTypeEq(ActionTypes.FETCH_REFRESH_TOKEN);

export const getAuthorizationCode = () => getBrowserQueryParam('code');

export const getIsAuthenticationError = (responseAction: ErrorEventAction) =>
	any((error: ApiError) => error.code === 'Unauthorized', responseAction?.meta?.errors ?? []);

export const selectAccessToken = o(prop('accessToken'), getSlice);
export const selectRefreshToken = o(prop('refreshToken'), getSlice);
export const selectDidLogOut = (state: GlobalState) => state.authentication.didLogOut;

const SIGN_IN_PATH = '/sign-in';

const authInstanceNamespace = (cond as any)([
	[equals(InstanceTypes.INSTANCE_BO), always('account')],
	[T, always('')],
])(currentInstanceType);

export interface SignInPayload {
	password: string;
	username: string;
}

export const signIn = createThunk(
	{ originType: '@authentication/signIn', errorMessage: m.signInFail },
	async ({ dispatch }, { username, password }: SignInPayload) => {
		await dispatch(
			request({
				url: `${authInstanceNamespace}/login`,
				body: { username, password },
				method: 'POST',
			})
		);
		if (!getIsInBrowser()) {
			dispatch(requestBiometricAuthenticationSetup({ username, password }));
		}
	}
);

interface StoreAccessTokenPayload {
	accessToken: string;
}

export const storeAccessToken = createThunk(
	{ originType: '@authentication/storeAccessToken' },
	async ({ dispatch }, { accessToken }: StoreAccessTokenPayload) => {
		const isInBrowser = getIsInBrowser();
		dispatch(setAccessToken(accessToken));

		if (isInBrowser) {
			Storage.setItem(storageKeys.ACCESS_TOKEN_KEY, accessToken);
		}
	}
);

interface StoreRefreshTokenPayload {
	refreshToken: string;
}

export const storeRefreshToken = createThunk(
	{ originType: '@authentication/storeRefreshToken' },
	async ({ dispatch }, { refreshToken }: StoreRefreshTokenPayload) => {
		const isInBrowser = getIsInBrowser();
		dispatch(setRefreshToken(refreshToken));

		if (isInBrowser) {
			Storage.setItem(storageKeys.REFRESH_TOKEN_KEY, refreshToken);
		}
	}
);

export const selectIsSigningIn: FetchingSelector = isFetching(signIn.originType);
const getIsSignInRequest = originTypeEq(signIn.originType);
export const getIsAuthenticationRequest = anyPass([
	getIsSignInRequest,
	getIsAccessTokenRequest,
	getIsRefreshTokenRequest,
]);
export const selectIsAuthenticating = anyPass([
	selectIsFetchingAccessToken,
	selectIsFetchingRefreshToken,
	selectIsSigningIn,
]);

export type VerifyCredentialsAndConfigureBiometricsPayload = SignInPayload;

export const verifyCredentialsAndConfigureBiometrics = createThunk(
	{
		originType: '@authentication/verifyCredentialsAndConfigureBiometrics',
		errorMessage: m.signInFail,
	},
	async ({ dispatch }, { username, password }: VerifyCredentialsAndConfigureBiometricsPayload) => {
		const {
			payload: { AccessToken: accessToken, RefreshToken: refreshToken },
		} = await dispatch(
			request({
				url: `${authInstanceNamespace}/login`,
				body: { username, password },
				method: 'POST',
			})
		);

		dispatch(setAccessToken(accessToken));
		dispatch(setRefreshToken(refreshToken));

		await dispatch(configureBiometricAuthentication({ username, password }));
	}
);

export const fetchRefreshTokenMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_REFRESH_TOKEN),
	({ dispatch }) =>
		action => {
			dispatch(
				request(
					{
						url: 'account/authorization',
						method: 'POST',
						body: {
							authorizationCode: action.payload,
						},
					},
					{
						origin: action,
					}
				)
			);
		}
);

const fetchAccessTokenMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_ACCESS_TOKEN),
	({ dispatch, getState }) =>
		action => {
			dispatch(
				request(
					{
						url: `${authInstanceNamespace}/refresh`,
						method: 'POST',
						body: {
							accessToken: selectAccessToken(getState()),
							refreshToken: action.payload,
						},
					},
					{
						origin: action,
					}
				)
			);
		}
);

interface ResetPasswordPayload {
	form: string;
	onSuccess?: () => void;
	username: string;
}

export const resetPassword = createThunk(
	{
		originType: '@authentication/resetPassword',
		errorMessage: m.resetPasswordFail,
	},
	async ({ dispatch }, { form, username, onSuccess }: ResetPasswordPayload) => {
		await dispatch(request({ url: '/password/reset', method: 'POST', body: { username } }));

		dispatch(addToast({ content: m.resetPasswordSuccess, type: 'success' }));
		dispatch(reset(form));

		if (onSuccess) {
			onSuccess();
		}
	}
);

export const selectIsFetchingResetPassword = selectIsThunkPending(resetPassword);

const fetchResetPasswordTokenMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_RESET_PASSWORD_TOKEN),
	({ dispatch }) =>
		action => {
			const { token } = action.payload;
			dispatch(
				run(
					() => request({ url: `/password/reset/${token}`, method: 'POST' }, { origin: action }),
					(action, { isError }) => {
						const { payload } = action;

						if (isError) {
							const errorsResponse = action?.meta?.errors;

							const errorsArray =
								!isEmpty(errorsResponse) && prop('message', errorsResponse[0])
									? errorsResponse
									: [];

							dispatch(
								addToast({
									type: 'warning',
									content: m.resetTokenInvalid,
								})
							);
							dispatch(
								storeEntity(resetPasswordTokenSchema, {
									token,
									success: false,
									errors: errorsArray,
								})
							);
						} else {
							dispatch(
								storeEntity(resetPasswordTokenSchema, {
									...payload,
									token,
								})
							);
						}
					}
				)
			);
		}
);

interface ChangePasswordPayload {
	confirmPassword: string;
	password: string;
	token: string;
}

export const changePassword = createThunk(
	{
		originType: '@authentication/changePassword',
		errorMessage: m.changePasswordFail,
	},
	async ({ dispatch }, { password, confirmPassword, token }: ChangePasswordPayload) => {
		const isInBrowser = getIsInBrowser();
		await dispatch(
			request({ url: '/password/set', method: 'POST', body: { password, confirmPassword, token } })
		);

		dispatch(addToast({ content: m.changePasswordSuccess, type: 'success' }));
		if (isInBrowser) {
			// NOTE: delay redirect so that the user can see the success message
			await sleep(3000);
			gatsbyNavigate('/sign-in');
		} else {
			gatsbyNavigate('auth');
		}
	}
);

export const selectIsChangingPassword = selectIsThunkPending(changePassword);

const defaultLocale = getEnvironmentVariable('GATSBY_DEFAULT_LOCALE')!;
const getShouldRedirectToLoginPage = () => !window.location.pathname.includes(SIGN_IN_PATH);

export const logOut = createThunk('@authentication/logOut', async ({ dispatch, getState }) => {
	const isInBrowser = getIsInBrowser();
	const accessToken = selectAccessToken(getState());

	if (!isInBrowser) {
		dispatch(unregisterPushNotifications());
	}
	Storage.removeItem(storageKeys.ACCESS_TOKEN_KEY);
	Storage.removeItem(storageKeys.REFRESH_TOKEN_KEY);
	dispatch(clearState());

	const shouldRedirectToLoginPage = isInBrowser ? getShouldRedirectToLoginPage() : false;

	if (!isInBrowser) {
		gatsbyNavigate('auth');
	}

	if (isInBrowser) {
		if (accessToken) {
			try {
				await dispatch(
					request({
						url: `${authInstanceNamespace}/logout`,
						method: 'POST',
					})
				);
			} catch (error) {
				console.warn(error);
			}
		} else {
			console.warn('No access token available for logout request.');
		}
		if (shouldRedirectToLoginPage) {
			const locale = Storage.getItem(storageKeys.MYCI_LOCALE_KEY)?.toLowerCase();

			setBrowserLocation(
				!locale || locale.includes(defaultLocale.toLowerCase())
					? SIGN_IN_PATH
					: `/${locale}${SIGN_IN_PATH}`
			);
		}
	}
	dispatch(setAccessToken(null));
	dispatch(setRefreshToken(null));
	dispatch(setDidLogOut(true));
});
export const selectIsLoggingOut = selectIsThunkPending(logOut);
export const getIsLogoutRequest = originTypeEq(logOut.originType);

const successMiddleware = makeMiddleware(
	getIsSuccessResponseAction([
		ActionTypes.FETCH_ACCESS_TOKEN,
		ActionTypes.FETCH_REFRESH_TOKEN,
		signIn.originType,
	]),
	({ dispatch }) =>
		({ payload }) => {
			const isBoInstance = currentInstanceType === InstanceTypes.INSTANCE_BO;

			const accessToken = isBoInstance ? payload.accessToken : payload.AccessToken;
			const refreshToken = isBoInstance ? payload.refreshToken : payload.RefreshToken;

			const isInBrowser = getIsInBrowser();

			if (refreshToken) {
				dispatch(storeRefreshToken({ refreshToken }));
			}

			if (accessToken) {
				dispatch(storeAccessToken({ accessToken }));

				if (isInBrowser && window.location.href.includes('sign-in')) {
					gatsbyNavigate('/app');
				} else if (!isInBrowser) {
					gatsbyNavigate('authLoader');
				}
			}
		}
);

export const middleware = (cond as any)([
	[
		equals(InstanceTypes.INSTANCE_BO),
		always(
			composeMiddleware(
				fetchAccessTokenMiddleware,
				fetchResetPasswordTokenMiddleware,
				successMiddleware
			)
		),
	],
	[
		T,
		always(
			composeMiddleware(fetchAccessTokenMiddleware, fetchRefreshTokenMiddleware, successMiddleware)
		),
	],
])(currentInstanceType);

export const reducer = createReducer(initialState, builder => {
	builder
		.addCase(setRefreshToken, (state, action) => ({
			...state,
			refreshToken: action.payload,
		}))
		.addCase(setAccessToken, (state, action) => ({
			...state,
			accessToken: action.payload,
		}))
		.addCase(setDidLogOut, (state, action) => ({
			...state,
			didLogOut: action.payload,
		}));
});
