import { Action, createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { last, update } from 'ramda';
import { isArray } from 'ramda-extension';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Thunk } from 'redux-syringe';

export type ManagerKey = string;

export interface InsertPayload {
	data: any[];
	key: ManagerKey;
}

export interface ClearPayload {
	key: ManagerKey;
}

const insert = createAction<InsertPayload>('@ci/entities/insert');
const clearAndInsert = createAction<InsertPayload>('@ci/entities/clearAndInsert');

const clear = createAction<ClearPayload>('@ci/entities/clear');
const freeze = createAction<ClearPayload>('@ci/entities/freeze');
const unfreeze = createAction<ClearPayload>('@ci/entities/unfreeze');

export interface ManagerState {
	data: null | any[];
	isFrozen: boolean;
}

export interface State {
	managers: Record<ManagerKey, ManagerState>;
}

const initialState: State = {
	managers: {},
};

export interface RootState {
	entities?: State;
}

const makeSelect =
	<TEntry>(key: ManagerKey) =>
	(state: RootState): TEntry[] | null =>
		state.entities?.managers?.[key]?.data ?? null;

const makeSelectFirst =
	<TEntry>(key: ManagerKey) =>
	(state: RootState): TEntry | null =>
		state.entities?.managers?.[key]?.data?.[0] ?? null;

export interface DataPredicate<TEntry> {
	(entry: TEntry): boolean;
}

const makeSelectBy =
	<TEntry>(key: ManagerKey) =>
	(predicate: DataPredicate<TEntry>) =>
		createSelector(makeSelect<TEntry>(key), data => (data ? data.filter(predicate) : null));

const makeSelectOneBy =
	<TEntry>(key: ManagerKey) =>
	(predicate: DataPredicate<TEntry>) =>
		createSelector(makeSelectBy<TEntry>(key)(predicate), data =>
			data ? last(data) ?? null : null
		);

export interface DataManager<TEntry> {
	clear: () => Action;
	clearAndInsert: (data: TEntry[]) => Action;
	freeze: () => Action;
	insert: (data: TEntry[]) => Action;
	key: ManagerKey;
	select: (state: RootState) => TEntry[] | null;
	selectBy: (predicate: DataPredicate<TEntry>) => (state: RootState) => TEntry[] | null;
	selectFirst: (state: RootState) => TEntry | null;
	selectOneBy: (predicate: DataPredicate<TEntry>) => (state: RootState) => TEntry | null;
	unfreeze: () => Action;
	upsert: (predicate: DataPredicate<TEntry>, entry: TEntry) => Thunk;
}

const arrayInvariant = (data: any[], key: ManagerKey) => {
	if (!isArray(data)) {
		throw new Error(
			`Data managers only support insertion of arrays. Instead, "${key}" data manager encountered type ${typeof data}.`
		);
	}
};

export const createDataManager = <TEntry>(key: ManagerKey): DataManager<TEntry> => ({
	clear: () => clear({ key }),
	clearAndInsert: data => {
		arrayInvariant(data, key);

		return clearAndInsert({ data, key });
	},

	freeze: () => freeze({ key }),
	insert: data => {
		arrayInvariant(data, key);

		return insert({ data, key });
	},
	key,
	select: makeSelect<TEntry>(key),
	selectBy: makeSelectBy<TEntry>(key),
	selectFirst: makeSelectFirst<TEntry>(key),
	selectOneBy: makeSelectOneBy<TEntry>(key),
	unfreeze: () => unfreeze({ key }),
	upsert:
		(predicate, entry) =>
		({ dispatch, getState }) => {
			const previousData = makeSelect<TEntry>(key)(getState()) ?? [];
			const previousRecordIndex = previousData.findIndex(predicate);

			const updatedData =
				previousRecordIndex !== -1
					? update(previousRecordIndex, entry, previousData)
					: [...previousData, entry];

			dispatch(clearAndInsert({ data: updatedData, key }));
		},
});

// HACK: Necessary because `dataManagerReducer` is composed after `normalizrReducer`, meaning
// that `initialState` has no effect. This is purely for backwards compatibility.
const initializeState = (state: State, key: ManagerKey) => {
	if (!state.managers) {
		state.managers = {};
	}

	if (!state.managers[key]) {
		state.managers[key] = {
			data: null,
			isFrozen: false,
		};
	}
};

export const dataManagerReducer = createReducer(initialState, builder =>
	builder
		.addCase(insert, (state, { payload }) => {
			initializeState(state, payload.key);

			if (state.managers[payload.key].isFrozen) {
				return;
			}

			const previousData = state.managers[payload.key].data ?? [];
			state.managers[payload.key].data = [...previousData, ...payload.data];
		})
		.addCase(clearAndInsert, (state, { payload }) => {
			initializeState(state, payload.key);

			if (state.managers[payload.key].isFrozen) {
				return;
			}

			state.managers[payload.key].data = payload.data ?? [];
		})
		.addCase(clear, (state, { payload }) => {
			initializeState(state, payload.key);

			if (state.managers[payload.key].isFrozen) {
				return;
			}

			delete state.managers[payload.key];
		})
		.addCase(freeze, (state, { payload }) => {
			initializeState(state, payload.key);

			state.managers[payload.key].isFrozen = true;
		})
		.addCase(unfreeze, (state, { payload }) => {
			initializeState(state, payload.key);

			state.managers[payload.key].isFrozen = false;
		})
);

/**
 * This hook clears all data associated with the supplied data manager when unmounted.
 *
 * It also prevents inserting any entries until this hook is mounted again as a safeguard against
 * race conditions where you exit a module inbetween fetching and storing some data.
 */
export const usePristineDataManager = (dataManager: DataManager<any>) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(dataManager.unfreeze());

		return () => {
			dispatch(dataManager.clear());
			dispatch(dataManager.freeze());
		};
	}, [dispatch, dataManager]);
};

declare module 'redux' {
	export interface Dispatch {
		<TThunk extends Thunk<any>>(thunk: TThunk): ReturnType<TThunk>;
	}
}
