import { forEachObjIndexed } from 'ramda';

export const setBrowserLocationUsingPost = async (location, data) =>
	new Promise((resolve, reject) => {
		try {
			const form = document.createElement('form');
			document.body.appendChild(form);
			form.method = 'post';
			form.action = location;

			forEachObjIndexed((value, name) => {
				const input = document.createElement('input');
				input.type = 'hidden';
				input.name = name;
				input.value = data[name];
				form.appendChild(input);
			}, data);

			// Note: We need to prevent resolvin promise before redirect takes place
			window.addEventListener('beforeunload', () => {
				setTimeout(() => resolve(), 5000);
			});

			form.submit();
		} catch (error) {
			reject(error);
		}
	});
