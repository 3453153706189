import {
	o,
	map,
	toPairs,
	compose,
	join,
	reject,
	prop,
	curry,
	propEq,
	anyPass,
	equals,
	pickBy,
	not,
} from 'ramda';
import { isNilOrEmpty, defaultToEmptyArray, isArray } from 'ramda-extension';

export const Operators = {
	EQUALS: 'eq',
	NOT_EQUAL: 'neq',
	GREATER_THAN: 'gt',
	GREATER_THAN_OR_EQUAL: 'ge',
	LESS_THAN: 'lt',
	LESS_THAN_OR_EQUAL: 'le',
	CONTAINS: 'contains',
	STARTS_WITH: 'startswith',
	ENDS_WITH: 'endswith',
	IN: 'in',
	NOT_IN: 'nin',
};

export const FILTERING_ANY_VALUE = '__FILTERING_ANY_VALUE__';

const shouldIgnoreValue = anyPass([
	isNilOrEmpty,
	equals(FILTERING_ANY_VALUE),
	propEq('value', FILTERING_ANY_VALUE),
	value => typeof value === 'string' && !value.trim(),
]);

const getFilteringQueryParamValue = (name, operator, value) =>
	encodeURIComponent(`${name} ${operator} ${value}`);

const getFilteringQueryParam = (name, operator, value) =>
	`$filterBy=${getFilteringQueryParamValue(name, operator, value)}`;

const makeFilter = (name, operator, value) =>
	isArray(value)
		? o(
				join('&'),
				map(singleValue => getFilteringQueryParam(name, operator, singleValue))
			)(value)
		: getFilteringQueryParam(name, operator, value);

export const getFilteringQueryString = filtering => {
	if (isNilOrEmpty(filtering)) {
		return '';
	}

	return compose(
		join('&'),
		map(({ name, value, operator = Operators.CONTAINS }) => makeFilter(name, operator, value)),
		reject(o(shouldIgnoreValue, prop('value'))),
		defaultToEmptyArray
	)(filtering);
};

export const makeFilteringEntries = curry((operator, fields) =>
	o(
		map(([name, value]) => ({ name, value, operator })),
		toPairs
	)(fields)
);

export const getFilteringObject = pickBy(o(not, shouldIgnoreValue));
